import React, { useState, useEffect } from "react";
import { UserAuth } from "../../context/AuthContext";
import { createFormToComplete, generateRandomId, readAllData, readData, updateData, createMail } from "../../functions/crud";
import { X } from "lucide-react";
import { getDate } from "../utils";

function SendFormModal({ onClose, formId }) {
    const { user } = UserAuth();
    const initialFormDetails = {
        id: generateRandomId(),
        playerId: "",
        email: "",
        formID: formId,
        formTitle: "",
        questions: [],
        organisationId: user?.organisationId,
        dateSent: getDate(),
        status: "Pending",
        injuryCaseFileId: "",
    };

    const [formDetails, setFormDetails] = useState(initialFormDetails);
    const [currentPlayers, setCurrentPlayers] = useState([]);
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const [organisationId] = useState(user?.organisationId);
    const [injuryData, setInjuryData] = useState([]);
    const [selectedInjury, setSelectedInjury] = useState(null);
    const [showInjuryDropdown, setShowInjuryDropdown] = useState(false);
    const [noEmailMessage, setNoEmailMessage] = useState(false);

    useEffect(() => {
        const fetchCurrentPlayers = async () => {
            try {
                const players = await readAllData('players', organisationId);
                setCurrentPlayers(players);
            } catch (error) {
                console.error('Error fetching players data:', error);
            }
        };

        fetchCurrentPlayers();
    }, [organisationId]);

    useEffect(() => {
        if (selectedPlayer?.id) {
            const fetchInjuryData = async () => {
                try {
                    const injuries = await readAllData('injuries', organisationId);
                    const playerInjuries = injuries.filter(injury => {
                        return selectedPlayer.injuries?.includes(injury.id) && injury.status !== "Archived";
                    });
                    setInjuryData(playerInjuries);
                    setShowInjuryDropdown(playerInjuries.length > 0);
                } catch (error) {
                    console.error('Error fetching injuries data:', error);
                }
            };

            fetchInjuryData();
        } else {
            setInjuryData([]);
            setShowInjuryDropdown(false);
            setSelectedInjury(null);
        }
    }, [selectedPlayer, organisationId]);

    useEffect(() => {
        const fetchFormTemplate = async () => {
            try {
                const formTemplate = await readData("forms", formId, organisationId);
                setFormDetails(prevFormDetails => ({
                    ...prevFormDetails,
                    formTitle: formTemplate.title,
                    questions: formTemplate.questions
                }));
            } catch (error) {
                console.error('Error fetching form template:', error);
            }
        };

        fetchFormTemplate();
    }, [formId, organisationId]);

    const fetchSelectedPlayerDetails = async (playerId) => {
        try {
            const playerDetails = await readData("players", playerId, organisationId);
            setSelectedPlayer(playerDetails);

            const injuries = await readAllData('injuries', organisationId);
            const playerInjuries = injuries.filter(injury => {
                return playerDetails.injuries?.includes(injury.id) && injury.status !== "Archived";
            });
            setInjuryData(playerInjuries);
            setShowInjuryDropdown(playerInjuries.length > 0);

            setFormDetails((prevFormDetails) => ({
                ...prevFormDetails,
                playerId: playerDetails.id,
                email: playerDetails.email,
            }));

            setNoEmailMessage(!playerDetails.email);
        } catch (error) {
            console.log("Error Fetching Player details: ", error);
        }
    };

    const handleSendForm = async (e) => {
        e.preventDefault();
        try {
            const formSentId = await createFormToComplete(formDetails);
            const formUrl = `https://matchfitt.co.uk/formtocomplete/${formSentId}/${organisationId}/${formDetails.email}`;
            const formLink = `<a href="${formUrl}">Click here to complete the form!</a>`;


            if (formDetails.injuryCaseFileId) {
                // Update the selected injury case file
                const updatedInjuryData = {
                    formsToComplete: [...(selectedInjury.forms || []), formSentId],
                };
                await updateData("injuries", formDetails.injuryCaseFileId, updatedInjuryData, organisationId);
            } else {
                // Update the player's forms list
                const updatedPlayerData = {
                    formsToComplete: [...(selectedPlayer.forms || []), formSentId],
                };
                await updateData("players", selectedPlayer.id, updatedPlayerData, organisationId);
            }

            // Send email to player with relevant information
            const emailDetails = {
                to: [formDetails.email],
                message: {
                    subject: `New MatchFitt Form`,
                    html: `
                        <p>Hello ${selectedPlayer.firstName} ${selectedPlayer.lastName},</p>
                        <p>A new form "${formDetails.formTitle}" has been set for you to complete.</p>
                        <p>Please ${formLink}</p>
                    `,
                },
            };

            await createMail(emailDetails);

            onClose();
        } catch (error) {
            console.error("Error sending form or updating player/injury: ", error);
        }
    };

    const handleOnChangePlayerSelect = (e) => {
        const playerId = e.target.value;
        if (playerId) {
            fetchSelectedPlayerDetails(playerId);
        } else {
            setSelectedPlayer(null);
            setFormDetails(initialFormDetails);
            setNoEmailMessage(false);
        }
    };

    const handleOnChangeInjurySelect = (e) => {
        const injuryId = e.target.value;
        setSelectedInjury(injuryId);
        setFormDetails((prevFormDetails) => ({
            ...prevFormDetails,
            injuryCaseFileId: injuryId,
        }));
    };

    return (
        <div className={`fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50`}>
            <div className="bg-white p-8 rounded-md shadow-md max-w-3xl w-full overflow-hidden">
                <div className='flex flex-row items-center mb-4'>
                    <h2 className="text-2xl font-bold flex-1">Send Form to Player</h2>
                    <X className='text-gray-400 hover:text-gray-600 cursor-pointer' onClick={onClose} />
                </div>
                <div className="max-h-96 overflow-y-auto">
                    <form className="grid grid-cols-2 gap-4 max-w-2xl mx-auto" onSubmit={handleSendForm}>
                        <select
                            id="players"
                            value={selectedPlayer ? selectedPlayer.id : ""}
                            onChange={handleOnChangePlayerSelect}
                        >
                            <option value="">Select Player...</option>
                            {currentPlayers.map((player) => (
                                <option key={player.id} value={player.id}>
                                    {player.firstName} {player.lastName}
                                </option>
                            ))}
                        </select>

                        {noEmailMessage && (
                            <div className="col-span-2 text-red-500">
                                This player does not yet have an email on their profile. Please add an email to their player profile in order to send forms to them.
                            </div>
                        )}

                        {showInjuryDropdown && (
                            <select
                                id="injuries"
                                value={selectedInjury || ""}
                                onChange={handleOnChangeInjurySelect}
                            >
                                <option value="">Select Injury...</option>
                                {injuryData.map((injury) => (
                                    <option key={injury.id} value={injury.id}>
                                        {injury.injury}
                                    </option>
                                ))}
                            </select>
                        )}

                        <button className="bg-darkTealPreset hover:bg-tealPreset text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                            Send
                        </button>
                    </form>
                    <div className="col-span-2 mt-4 text-sm text-gray-600">
                        Please note, if an injury case file is not chosen then the form will appear in the player profile. However, if a specific injury is selected, then the form will appear in the selected injury case file.
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SendFormModal;

