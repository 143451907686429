import React, { useState } from "react";
import { UserAuth } from "../context/AuthContext";
import { createData } from "../functions/crud";
import CurrentPlayersTable from "./dashboardComponents/CurrentPlayersTable";
import CurrentPlayersTableAlt from "./dashboardComponents/CurrentPlayersTableAlt";
import CreateNewPlayerModal from "./modals/CreateNewPlayerModal";

export default function Players() {

    const { user } = UserAuth();
    const organisationId = user?.organisationId;

    const [isNewPlayerModalVisible, setNewPlayerModalVisible] = useState(false);


    const toggleNewPlayerModal = () => {
        setNewPlayerModalVisible(!isNewPlayerModalVisible);
    };

    return (
        <div className="w-full">
            <div className="mx-10 my-10">
                {isNewPlayerModalVisible &&
                    <div>
                        <CreateNewPlayerModal onClose={toggleNewPlayerModal}/>
                    </div>
                }
                <div className="flex -flex-row w-full place-content-between">
                    <h1 className="text-2xl font-bold pb-4">Players</h1>
                    <button
                        className="bg-darkTealPreset hover:bg-tealPreset text-white font-bold py-2 px-4 rounded"
                        onClick={toggleNewPlayerModal}>
                        + New Player
                    </button>
                </div>

                <div>
                    <div className="mt-4">
                        <CurrentPlayersTableAlt />
                    </div>
                </div>

            </div>
        </div>
    )
}