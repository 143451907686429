import React from 'react';
import startSubscription from '../../stripe/startSubscription';
import { UserAuth } from '../../context/AuthContext';
import { ArrowRight } from 'lucide-react'
import { logout } from '../../functions/auth';

function NotYetSubscribedModal() {
    const { user } = UserAuth();

    const handleSubscribeButtonClicked = async (e) => {
        let priceId = "";

        try {

            switch (e.target.name) {
                case 'solo':
                    priceId = 'price_1POdQuJE427N7TRjdXL4hLG6';
                    break;
                case 'team':
                    priceId = 'price_1POdRMJE427N7TRjaTA5F3xQ';
                    break;
                case 'squad':
                    priceId = 'price_1POdRPJE427N7TRjfowGKpuj';
                    break;
                case 'roster':
                    priceId = 'price_1POdRSJE427N7TRjvENEUKPU';
                    break;
                case 'enterprise':
                    priceId = 'price_1POdRUJE427N7TRjnPy6MuAZ';
                    break;
                default:
                    break;
            }

            console.log("PRICE ID: ", priceId)
            await startSubscription(user, priceId);
        } catch (error) {
            console.error('Error starting subscription from file NotYetSubscribedModal:', error);
        }
    };

    const handleSignOutButtonClick = () => {
        logout();
    }

    return (
        <div className={`fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75`}>
            <div className="bg-gray-100 p-8 rounded-md shadow-md max-w-5xl w-full overflow-hidden">
                <div className='flex flex-row items-center mb-4'>
                    <h2 className="text-2xl font-bold flex-1">Subscriptions</h2>
                </div>
                <div className="h-[500px]"> {/* Adjusted height */}
                    <h1 className='mb-5'>An active subscription has not been found in the account for your organisation. Please click the subscribe button below to choose one and begin using our platform!</h1>


                    <div id='middleSection' className='flex flex-row gap-6 mx-auto max-w-5xl justify-center mb-10 mt-20'>
                        <div className='flex flex-col border border-none shadow-md pb-5 bg-white rounded-md'>
                            <h1 className='text-xl w-full font-bold mb-4 text-center mx-auto py-5 px-10 bg-yellowPreset text-white rounded-t-md'>Solo</h1>
                            <p className='max-w-full mx-auto mb-4 text-center'>£29/mo</p>
                            <p className='max-w-full mx-auto mb-4 text-center border-y-2 border-gray-300 py-1'>1 Account</p>
                            <button onClick={(e) => handleSubscribeButtonClicked(e)} name='solo' className='mx-auto bg-yellowPreset text-white px-4 py-2 rounded'>Select</button>
                        </div>
                        <div className='flex flex-col border border-none shadow-md pb-5 bg-white rounded-md'>
                            <h1 className='text-xl w-full font-bold mb-4 text-center mx-auto py-5 px-10 bg-tealPreset text-white rounded-t-md'>Team</h1>
                            <p className='max-w-full mx-auto mb-4 text-center'>£79/mo</p>
                            <p className='max-w-full mx-auto mb-4 text-center border-y-2 border-gray-300 py-1'>2-6 Accounts</p>
                            <button onClick={(e) => handleSubscribeButtonClicked(e)} name='team' className='mx-auto bg-tealPreset text-white px-4 py-2 rounded'>Select</button>
                        </div>
                        <div className='flex flex-col border border-none shadow-md pb-5 bg-white rounded-md'>
                            <h1 className='text-xl w-full font-bold mb-4 text-center mx-auto py-5 px-10 bg-darkTealPreset text-white rounded-t-md'>Squad</h1>
                            <p className='max-w-full mx-auto mb-4 text-center'>£149/mo</p>
                            <p className='max-w-full mx-auto mb-4 text-center border-y-2 border-gray-300 py-1'>7-12 Accounts</p>
                            <button onClick={(e) => handleSubscribeButtonClicked(e)} name='squad' className='mx-auto bg-darkTealPreset text-white px-4 py-2 rounded'>Select</button>
                        </div>
                        <div className='flex flex-col border border-none shadow-md pb-5 bg-white rounded-md'>
                            <h1 className='text-xl w-full font-bold mb-4 text-center mx-auto py-5 px-10 bg-navyPreset text-white rounded-t-md'>Roster</h1>
                            <p className='max-w-full mx-auto mb-4 text-center'>£199/mo</p>
                            <p className='max-w-full mx-auto mb-4 text-center border-y-2 border-gray-300 py-1'>13-25 Accounts</p>
                            <button onClick={(e) => handleSubscribeButtonClicked(e)} name='roster' className='mx-auto bg-navyPreset text-white px-4 py-2 rounded'>Select</button>
                        </div>
                        <div className='flex flex-col border border-none shadow-md pb-5 bg-white rounded-md'>
                            <h1 className='text-xl w-full font-bold mb-4 text-center mx-auto py-5 px-10 bg-gray-800 text-white rounded-t-md'>Enterprise</h1>
                            <p className='max-w-full mx-auto mb-4 text-center'>£299/mo</p>
                            <p className='max-w-full mx-auto mb-4 text-center border-y-2 border-gray-300 py-1'>26-100 Accounts</p>
                            <button onClick={(e) => handleSubscribeButtonClicked(e)} name='enterprise' className='mx-auto bg-gray-800 text-white px-4 py-2 rounded'>Select</button>
                        </div>
                    </div>
                </div>
                <button
                    className=" text-blue-400 hover:text-blue-500 py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    onClick={handleSignOutButtonClick}
                >
                    Sign Out
                </button>
            </div>
        </div>
    );
}

export default NotYetSubscribedModal;
