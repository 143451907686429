import {initializeApp} from "firebase/app"
import {getFirestore} from "firebase/firestore"
import {getAuth} from "firebase/auth"
import {getStorage} from "firebase/storage"

const firebaseConfig = {
    apiKey: "AIzaSyDAC4I2OMY5knkf8UhYVIl1NulRTkEXoPQ",
    authDomain: "recovery-radar-5345b.firebaseapp.com",
    projectId: "recovery-radar-5345b",
    storageBucket: "recovery-radar-5345b.appspot.com",
    messagingSenderId: "599719510013",
    appId: "1:599719510013:web:642856478afd86afe5e394"
  };

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);