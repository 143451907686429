import React, { useState, useEffect } from "react";
import Sidebar, { SidebarItem } from "../components/shared/Sidebar";
import DashboardHeader from "../components/shared/DashboardHeader";
import { Route, Routes } from "react-router-dom";
import { LayoutDashboard, List, Users, Activity, Building, ClipboardList} from "lucide-react";
import Dashboard from "../components/Dashboard";
import Players from "../components/Players";
import Injuries from "../components/Injuries";
import Staff from "../components/Staff";
import PlayerProfile from "../components/PlayerProfile";
import CaseFile from "../components/CaseFile";
import MyProfile from "../components/MyProfile";
import { UserAuth } from "../context/AuthContext.jsx";
import NotYetSubscribedModal from "../components/modals/NotYetSubscribedModal.jsx";
import { checkSubscriptionStatus, checkisUserOwner } from "../functions/crud.js";
import Organisation from "../components/Organisation.jsx";
import Forms from "../components/Forms.jsx";
import FormDetails from "../components/FormDetails.jsx";

export default function UserRoutes() {

    const { user } = UserAuth();
    const organisationId = user?.organisationId;
    const userAccessLevel = user?.accessLevel;
    const isUserGeneralUser = userAccessLevel == "General User";
    const [hasActiveSubscription, setHasActiveSubscription] = useState(false);
    const [isCurrentUserOwner, setIsCurrentUserOwner] = useState(false);

    useEffect(() => {
        const checkingSubscriptionStatus = async () => {
            try {
                const isSubscribed = await checkSubscriptionStatus(organisationId);
                setHasActiveSubscription(isSubscribed);

            } catch (error) {
                console.error("Error checking subscription status:", error);
            }
        };

        const checkIfCurrentUserIsOwner = async () => {
            try {
                const isOwner = await checkisUserOwner(organisationId, user.uid)
                setIsCurrentUserOwner(isOwner);
                console.log(isOwner)
            } catch (error) {
                console.error("Error checking if current user is owner:", error);
            }
        }

        checkingSubscriptionStatus();
        checkIfCurrentUserIsOwner();
    }, [organisationId]);

    return (
        <div className="flex flex-row">
            <div className="z-50">
                {hasActiveSubscription && <NotYetSubscribedModal />}
            </div>
            <Sidebar>
                <SidebarItem icon={<LayoutDashboard size={30} />} text="Dashboard"/>
                <SidebarItem icon={<List size={30}/>} text="Players"/>
                {!isUserGeneralUser && <SidebarItem icon={<Activity size={30}/>} text="Injuries"/>}
                <SidebarItem icon={<ClipboardList size={30}/>} text="Forms"/>
                <SidebarItem icon={<Users size={30}/>} text="Staff"/>
                {isCurrentUserOwner && <SidebarItem icon={<Building size={30}/>} text="Organisation"/>}
            </Sidebar>
            <div className="w-full bg-gray-50">
                <DashboardHeader/>
                <Routes>
                    <Route path="/dashboard" element={<Dashboard />}/>
                    <Route path="/players" element={<Players />}/>
                    <Route path="/injuries" element={<Injuries />}/>
                    <Route path="/forms" element={<Forms />}/>
                    <Route path="/staff" element={<Staff />}/>
                    <Route path="/organisation" element={<Organisation />}/>
                    <Route path="/playerProfile/:id" element={<PlayerProfile />}/>
                    <Route path="/casefile/:id" element={<CaseFile />}/>
                    <Route path="/form/:id" element={<FormDetails />}/>
                    <Route path="/profile" element={<MyProfile />}/>
                </Routes>
            </div>
        </div>
    )
}