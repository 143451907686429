import React, { useState } from "react";
import { UserAuth } from "../../context/AuthContext";
import { createData } from "../../functions/crud";
import { X, Trash2 } from "lucide-react";
import { getTime, getDate } from "../utils";

function CreateFormTemplateModal({ onClose }) {
    const initialFormDetails = {
        title: "",
        questions: [{ text: "", type: "text", options: [""] }],
        creatorId: "",
        timeCreated: getTime(),
        dateCreated: getDate()
    };

    const { user } = UserAuth();
    const [form, setForm] = useState(initialFormDetails);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleQuestionChange = (index, e) => {
        const { name, value } = e.target;
        const newQuestions = form.questions.map((question, qIndex) => (
            qIndex === index ? { ...question, [name]: value } : question
        ));
        setForm({ ...form, questions: newQuestions });
    };

    const handleOptionChange = (qIndex, oIndex, value) => {
        const newQuestions = form.questions.map((question, index) => {
            if (index === qIndex) {
                const newOptions = question.options.map((option, idx) => (
                    idx === oIndex ? value : option
                ));
                return { ...question, options: newOptions };
            }
            return question;
        });
        setForm({ ...form, questions: newQuestions });
    };

    const addOption = (qIndex) => {
        const newQuestions = form.questions.map((question, index) => {
            if (index === qIndex) {
                return { ...question, options: [...question.options, ""] };
            }
            return question;
        });
        setForm({ ...form, questions: newQuestions });
    };

    const removeOption = (qIndex, oIndex) => {
        const newQuestions = form.questions.map((question, index) => {
            if (index === qIndex) {
                const newOptions = question.options.filter((_, idx) => idx !== oIndex);
                return { ...question, options: newOptions };
            }
            return question;
        });
        setForm({ ...form, questions: newQuestions });
    };

    const addQuestion = () => {
        setForm({
            ...form,
            questions: [...form.questions, { text: "", type: "text", options: [""] }]
        });
    };

    const removeQuestion = (index) => {
        const newQuestions = form.questions.filter((_, qIndex) => qIndex !== index);
        setForm({ ...form, questions: newQuestions });
    };

    const handleCreateForm = async (e) => {
        e.preventDefault();
        try {
            const formWithCreator = { ...form, creatorId: user.uid };
            await createData("forms", formWithCreator, user?.organisationId);
            onClose();
        } catch (error) {
            console.error("Error creating form:", error);
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
            <div className="bg-white p-8 rounded-md shadow-md max-w-3xl w-full overflow-hidden">
                <div className="flex flex-row items-center mb-4">
                    <h2 className="text-2xl font-bold flex-1">Create New Form Template</h2>
                    <X className="text-gray-400 hover:text-gray-600 cursor-pointer" onClick={onClose} />
                </div>
                <div className="max-h-96 overflow-y-auto">
                    <form className="grid grid-cols-1 gap-4 max-w-2xl mx-auto" onSubmit={handleCreateForm}>
                        <input
                            type="text"
                            placeholder="Form Title"
                            name="title"
                            value={form.title}
                            onChange={handleInputChange}
                            className="p-2 border border-gray-300 rounded"
                        />
                        {form.questions.map((question, index) => (
                            <div key={index} className="flex flex-col space-y-2">
                                <div className="flex items-center space-x-2">
                                    <input
                                        type="text"
                                        placeholder="Question Text"
                                        name="text"
                                        value={question.text}
                                        onChange={(e) => handleQuestionChange(index, e)}
                                        className="p-2 border border-gray-300 rounded flex-1"
                                    />
                                    <select
                                        name="type"
                                        value={question.type}
                                        onChange={(e) => handleQuestionChange(index, e)}
                                        className="p-2 border border-gray-300 rounded"
                                    >
                                        <option value="text">Text</option>
                                        <option value="paragraph">Paragraph Text</option>
                                        <option value="multiple-choice">Multiple Choice</option>
                                        <option value="checkbox">Checkboxes</option>
                                    </select>
                                    <button
                                        type="button"
                                        onClick={() => removeQuestion(index)}
                                        className="p-2 bg-red-500 text-white rounded"
                                    >
                                        <Trash2 size={25}/>
                                    </button>
                                </div>
                                {(question.type === "multiple-choice" || question.type === "checkbox") && (
                                    <div className="flex flex-col space-y-2 pl-10">
                                        {question.options.map((option, oIndex) => (
                                            <div key={oIndex} className="flex items-center space-x-2">
                                                <input
                                                    type="text"
                                                    value={option}
                                                    onChange={(e) => handleOptionChange(index, oIndex, e.target.value)}
                                                    placeholder="Option"
                                                    className="p-2 border border-gray-300 rounded flex-1"
                                                />
                                                <button
                                                    type="button"
                                                    onClick={() => removeOption(index, oIndex)}
                                                    className="p-2 bg-red-500 text-white rounded"
                                                >
                                                    Remove
                                                </button>
                                            </div>
                                        ))}
                                        <button
                                            type="button"
                                            onClick={() => addOption(index)}
                                            className="p-2 bg-blue-300 hover:bg-blue-400 text-white rounded"
                                        >
                                            Add Option
                                        </button>
                                    </div>
                                )}
                            </div>
                        ))}
                        <button
                            type="button"
                            onClick={addQuestion}
                            className="p-2 bg-blue-300 hover:bg-blue-400 text-white rounded"
                        >
                            Add Question
                        </button>
                        <button
                            type="submit"
                            className="bg-darkTealPreset hover:bg-tealPreset text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        >
                            Create
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default CreateFormTemplateModal;
