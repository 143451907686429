import React, {useState, useEffect} from 'react'
import { X, HelpCircle } from 'lucide-react';
import { updateUser, readUser } from '../../functions/crud';
import DeleteStaffModal from './DeleteStaffModal';
import SuccessfullyDeletedModal from './SuccessfullyDeletedModal';

function StaffSettingsModal({onClose, staffId, updateSuccessful}) {

    const [newAccessLevel, setNewAccessLevel] = useState({accessLevel: ""})
    const [isToolTipVisible, setToolTipVisible] = useState(false);
    const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
    const [isDeletionSuccessfulModalVisible, setDeletionSuccessfulModalVisible] = useState(false);
    const [currentStaffMember, setCurrentStaffMemebr] = useState([]);

    useEffect(() => {
        const fetchStaffMember = async () => {
            try {
                const staffData = await readUser(staffId);
                setCurrentStaffMemebr(staffData);
            } catch (error) {
                console.error("Error reading document:", error);
            }
        };

        fetchStaffMember();
    }, [staffId]);

    const handleSaveNewAccessLevel = (e) => {
        e.preventDefault();
        updateUser(newAccessLevel, staffId);
        onClose();
        updateSuccessful(newAccessLevel.accessLevel)
    }

    const toggleDeleteModalVisible = () => {
        setDeleteModalVisible(!isDeleteModalVisible);
    }

    const toggleDeletionSuccessfulModalVisible = () => {
        setDeletionSuccessfulModalVisible(!isDeletionSuccessfulModalVisible);
    }

    const deletionSuccessfullCloseButtonClicked = () => {
        setDeletionSuccessfulModalVisible(!isDeletionSuccessfulModalVisible);
        onClose();
    }

  return (
    <div className={`fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75`}>
    <div className="bg-white p-8 rounded-md shadow-md max-w-lg w-full overflow-hidden">
        <div className='flex flex-row items-center mb-4'>
            <h2 className="text-2xl font-bold flex-1">Settings for {currentStaffMember?.firstName} {currentStaffMember.LastName}</h2>
            <X className='text-gray-400 hover:text-gray-600 cursor-pointer' onClick={onClose} />
        </div>
        <div className="max-h-96 overflow-y-auto">
            <form className="gap-4 max-w-2xl mx-auto" onSubmit={handleSaveNewAccessLevel}>
                <div className='flex flex-row'>
                    <div className='flex flex-col mb-4'>
                        <label htmlFor="accessLevel">Select New Access Level</label>
                        <select
                            className={`font-semibold py-2 px-2 mr-auto text-gray-600 w-[175px]`}
                            name='accessLevel'
                            required
                            onChange={(e) => {
                                setNewAccessLevel({accessLevel: e.target.value});
                            }}
                        >
                            <option value="" disabled selected>Access Level...</option>
                            <option value="Admin">Admin</option>
                            <option value="Healthcare User">Healthcare User</option>
                            <option value="General User">General User</option>
                        </select>
                    </div>
                    <HelpCircle 
                        className='text-gray-400 hover:text-gray-700 ml-2 mt-10 cursor-pointer'
                        onClick={() => setToolTipVisible(!isToolTipVisible)}
                    />
                </div>

                {isToolTipVisible && <div className='text-gray-500'>
                    Admin: This role has access to everything, including managing organisation, access & permissions settings and viewing all healthcare data.<br/><br/>
                    Healthcare User: This role has access to healthcare data and some administrative functions related to managing healthcare data, but not full access to manage organisation, access & permissions settings.<br/><br/>
                    General User: This role has limited access and can only view general information about players, without access to healthcare data or administrative functions.
                </div>}

                <button className="bg-darkTealPreset hover:bg-tealPreset text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                    Save
                </button>
            </form>
        </div>
    </div>
</div>
  )
}

export default StaffSettingsModal