import React from 'react'
import { X } from 'lucide-react'
import { updateUser } from '../../functions/crud';
import { UserAuth } from "../../context/AuthContext";
import { useState } from 'react'
import { storage } from '../../firebase/fire';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { generateRandomId } from '../../functions/crud';

function EditUserImageModal({onClose}) {

    const { user } = UserAuth();
    const organisationId = user?.organisationId;
    const userId = user?.uid;
    const [imageUpload, setImageUpload] = useState(null);

    const handleOnClickSave = async () => {
        if (imageUpload === null) {
            console.log("No image uploaded")
        } else {
            const imageRef = ref(storage, `${organisationId}/users/${userId}/${imageUpload.name + generateRandomId()}`)
            try {
                await uploadBytes(imageRef, imageUpload);
                const downloadURL = await getDownloadURL(imageRef);
                await updateUser({ imageRef: downloadURL }, userId);
                onClose();
            } catch (error) {
                console.error("Error uploading image:", error);
            }
        }
    }
    
    return (
        <div className={`fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75`}>
            <div className="bg-white p-8 rounded-md shadow-md max-w-3xl w-full overflow-hidden">
                <div className='flex flex-row items-center mb-4'>
                    <h2 className="text-2xl font-bold flex-1">Edit Image</h2>
                    <X className='text-gray-400 hover:text-gray-600 cursor-pointer' onClick={onClose} />
                </div>

                <div className='flex flex-row'>
                    <div className="max-h-96 overflow-y-auto">
                        {user?.imageRef ? (
                            <div
                                className="h-48 w-48 rounded-sm bg-sky-500 bg-cover bg-no-repeat bg-center"
                                style={{ backgroundImage: `url(${user?.imageRef})` }}
                            >
                            </div>
                        ) : (
                            <div
                                className="h-48 w-48 rounded-sm bg-gray-300 text-gray-600 bg-cover bg-no-repeat bg-center text-center"
                                style={{ backgroundImage: `url(https://placehold.co/600x400/2a8795/fff?text=${(user?.firstName || '').charAt(0)}${(user?.LastName || '').charAt(0)})` }}
                            >
                            </div>
                        )}
                    </div>

                    <div className='ml-20 flex flex-col'>
                        <input 
                            type='file' 
                            className='mb-4'
                            onChange={(e) => {
                                setImageUpload(e.target.files[0])
                        }}/>
                        <button 
                            className='mr-auto bg-darkTealPreset hover:bg-tealPreset text-white font-bold px-4 py-2 rounded'
                            onClick={handleOnClickSave}
                            >
                                Save
                            </button>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default EditUserImageModal