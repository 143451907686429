import { createContext, useContext, useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import { auth, db } from "../firebase/fire";

const userContext = createContext();

export const UserAuth = () => {
    return useContext(userContext);
};

export default function AuthContextProvider({children}) {
    const [isLoggedOut, setIsLoggedOut] = useState(true);
    const [user, setUser] = useState(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
          if (currentUser) {
            setIsLoggedOut(false);
            onSnapshot(doc(db, "users", currentUser.uid), (doc) => {
              setUser(doc.data());
            });
    
            console.log("It ran again");
          } else {
            setIsLoggedOut(true);
          }
        });
        return () => {
          unsubscribe();
        };
      }, []);

    return (
        <userContext.Provider
            value={{
                isLoggedOut,
                user
            }}
        >
            {children}
        </userContext.Provider>
    )
}