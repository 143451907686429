import React, { useState, useEffect } from 'react'
import { UserAuth } from "../../context/AuthContext";
import UpdateCard from '../UpdateCard'
import { listenToCollection, } from "../../functions/crud";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase/fire";

function Updates() {
  const { user } = UserAuth();
  const organisationId = user?.organisationId;
  const [globalUpdateData, setGlobalUpdateData] = useState([]);

  useEffect(() => {
    const checkCollectionExists = async () => {
      try {
        const collectionRef = collection(
          db,
          "organisations",
          organisationId,
          "globalUpdates"
        );
        const snapshot = await getDocs(collectionRef);

        if (!snapshot.empty) {
          const unsubscribe = listenToCollection(
            "globalUpdates",
            organisationId,
            (newData) => {
              // Sort the updates by date and time most recent
              newData.sort((a, b) => {
                const dateA = new Date(
                  `${a.dateCreated} ${a.timeCreated}`
                ).getTime();
                const dateB = new Date(
                  `${b.dateCreated} ${b.timeCreated}`
                ).getTime();
                return dateB - dateA;
              });
              setGlobalUpdateData(newData);
            }
          );

          return () => {
            unsubscribe();
          };
        } else {
          console.log("The 'globalUpdates' collection does not exist or is empty.");
        }
      } catch (error) {
        console.error("Error checking collection:", error);
      }
    };

    checkCollectionExists();
  }, [organisationId]);

  return (
    <div className="w-[17rem] h-full bg-white shadow-md p-4 rounded-sm border border-gray-200 flex flex-col ml-3 my-4">
      <strong className="text-gray-700 font-medium">Updates</strong>
      <div className="w-full flex-1 text-xs">
        {globalUpdateData.length === 0 ? (
          <p>No updates</p>
        ) : (
          globalUpdateData.map((update, index) => (
            <UpdateCard
              key={index}
              title={update.title}
              subTitle={update.subTitle}
              imageRef={update.imageRef}
              creatorFirstName={update.creatorFirstName}
              creatorLastName={update.creatorLastName}
              creatorRole={update.creatorRole}
              timeCreated={update.timeCreated}
              dateCreated={update.dateCreated}
            />
          ))
        )}
      </div>
    </div>
  );
}

export default Updates