import React, { useEffect, useState } from 'react';
import { ArrowUpLeftFromSquare } from "lucide-react";
import { listenToCollection, readData } from "../../functions/crud";
import { db } from "../../firebase/fire";
import { collection, getDocs } from "firebase/firestore";
import { UserAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

export default function CurrentInjuriesTableAlt() {
    const { user } = UserAuth();
    const [currentInjuries, setCurrentInjuries] = useState([]);
    const [playerData, setPlayerData] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const organisationId = user?.organisationId;
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const collectionRef = collection(db, "organisations", organisationId, "injuries");
                const snapshot = await getDocs(collectionRef);
                if (!snapshot.empty) {
                    const unsubscribe = listenToCollection("injuries", organisationId, (newData) => {
                        setCurrentInjuries(newData);
                    });
                    return unsubscribe;
                } else {
                    console.log("The 'injuries' collection does not exist or is empty.");
                }
            } catch (error) {
                console.error("Error checking collection:", error);
            }
        };

        fetchData();
    }, [organisationId]);

    useEffect(() => {
        const fetchPlayerData = async () => {
            const promises = currentInjuries.map(injury => readData("players", injury.playerId, organisationId));
            try {
                const playerDataArray = await Promise.all(promises);
                const playerDataObject = playerDataArray.reduce((acc, player) => {
                    acc[player.id] = player;
                    return acc;
                }, {});
                setPlayerData(playerDataObject);
            } catch (error) {
                console.error("Error fetching player data:", error);
            }
        };

        if (currentInjuries.length > 0) {
            fetchPlayerData();
        }
    }, [currentInjuries, organisationId]);

    const handleCaseFileButtonClick = (id) => {
        navigate(`/casefile/${id}`);
    };

    const getStatusBackgroundColor = (status) => {
        switch (status) {
            case 'In Triage':
                return 'bg-red-500';
            case 'In Rehab':
                return 'bg-yellow-400';
            case 'Returned to Training':
                return 'bg-purple-600';
            case 'Returned to Play':
                return 'bg-green-600';
            case 'Playing with Injury':
                return 'bg-orange-500'; 
            case 'Archived':
                return 'bg-gray-600';
            default:
                return 'bg-black';
        }
    };

    const filteredInjuries = currentInjuries.filter(injury =>
        (playerData[injury.playerId]?.firstName || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
        (playerData[injury.playerId]?.lastName || '').toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <div className="flex flex-column sm:flex-row flex-wrap space-y-4 sm:space-y-0 items-center justify-between pb-4">
                <label htmlFor="table-search" className="sr-only">Search</label>
                <div className="relative">
                    <div className="absolute inset-y-0 left-0 rtl:inset-r-0 rtl:right-0 flex items-center ps-3 pointer-events-none">
                        <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg>
                    </div>
                    <input
                        type="text"
                        id="table-search"
                        className="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Search for injuries"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div>
            </div>
            <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-200">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            Player name
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Injury
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Expected RTP
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Status
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {filteredInjuries.length === 0 ? (
                        <tr className="bg-white border-b hover:bg-gray-50">
                            <td colSpan="6" className="text-center">No injuries found. (If you have added an injury and do not see it here, please refresh your browser!)</td>
                        </tr>
                    ) : (
                        filteredInjuries
                        .filter(injury => injury.status !== "Archived")
                        .map((injury) => (
                            <tr key={injury.id} className='bg-white border-b hover:bg-gray-50"'>
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap flex flex-row">
                                    {playerData[injury.playerId]?.imageRef ? (
                                        <div
                                            className="h-10 w-10 rounded-full bg-sky-500 bg-cover bg-no-repeat bg-center m-2"
                                            style={{ backgroundImage: `url(${playerData[injury.playerId]?.imageRef})` }}
                                        >
                                        </div>
                                    ) : (
                                        <div
                                            className="h-10 w-10 rounded-full bg-gray-300 text-gray-600 bg-cover bg-no-repeat bg-center text-center m-2"
                                            style={{ backgroundImage: `url(https://placehold.co/600x400/f4b346/fff?text=${(playerData[injury.playerId]?.firstName || '').charAt(0)}${(playerData[injury.playerId]?.lastName || '').charAt(0)})` }}
                                        >
                                        </div>
                                    )}
                                    <div className='flex flex-row mt-4'>
                                        <h1 className='text-md font-semibold'>
                                            {playerData[injury.playerId]?.firstName + " " + playerData[injury.playerId]?.lastName || '-'}
                                        </h1>
                                    </div>
                                </th>
                                <td className='px-6 py-4'>{injury.injury || '-'}</td>
                                <td className='px-6 py-4'>{injury.expectedRtp || '-'}</td>
                                <td className='px-6 py-4'>
                                    <div className={`${getStatusBackgroundColor(injury.status)} text-white rounded-sm px-4 py-2 max-w-max inline-block`}>
                                        {injury.status}
                                    </div>
                                </td>
                                <td className='px-6 py-4'>
                                    <ArrowUpLeftFromSquare size={22} className='cursor-pointer' onClick={() => handleCaseFileButtonClick(injury.id)} />
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
        </div>
    )
}
