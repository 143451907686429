import React, { useState, useEffect } from "react";
import { UserAuth } from "../context/AuthContext";
import { createData, readAllData, readData, updateData } from "../functions/crud";
import CurrentInjuriesTable from "./dashboardComponents/CurrentInjuriesTable";
import CreateNewInjuryModal from "./modals/CreateNewInjuryModal";
import CurrentInjuriesTableAlt from "./dashboardComponents/CurrentInjuriesTableAlt";

export default function Players() {

    const { user } = UserAuth();
    const organisationId = user?.organisationId;

    const [isNewInjuryModalVisible, setNewInjuryModalVisible] = useState(false);


    const toggleNewInjuryModal = () => {
        setNewInjuryModalVisible(!isNewInjuryModalVisible);
    };

    return (
        <div className="w-full">
            <div className="mx-10 my-10">
                {isNewInjuryModalVisible &&
                    <div>
                        <CreateNewInjuryModal onClose={toggleNewInjuryModal} />
                    </div>
                }
                <div className="flex -flex-row w-full place-content-between">
                    <h1 className="text-2xl font-bold pb-4">Injuries</h1>
                    <button
                        className="bg-darkTealPreset hover:bg-tealPreset text-white font-bold py-2 px-4 rounded"
                        onClick={toggleNewInjuryModal}>
                        + New Injury
                    </button>
                </div>

                <div>
                    <div className="mt-4">
                        {/* <CurrentInjuriesTable /> */}
                        <CurrentInjuriesTableAlt />
                    </div>
                </div>

            </div>
        </div>
    )
}