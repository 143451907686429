import React, { useState, useEffect } from "react";
import StaffCard from "./StaffCard";
import { UserAuth } from "../context/AuthContext";
import { db } from "../firebase/fire";
import { collection, query, where, getDocs } from 'firebase/firestore';
import InviteUserModal from "./modals/InviteUserModal";
import { Trash2 } from 'lucide-react';
import DeleteInviteModal from "./modals/DeleteInviteModal";
import { checkSubscriptionAccountInviteLimit } from "../functions/crud";

export default function Staff() {
    const { user } = UserAuth();
    const organisationId = user?.organisationId;

    const [staffData, setStaffData] = useState([]);
    const [invitationsData, setInvitationsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isInviteUserModalVisible, setInviteUserModalVisible] = useState(false);
    const [isDeleteInviteModalVisible, setDeleteInviteModalVisible] = useState(false);
    const [inviteToDeleteInfo, setInviteToDeleteInfo] = useState({});
    const [isInviteEnabled, setIsInviteEnabled] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (organisationId) {
                    // Fetch staff data
                    const staffQuerySnapshot = await getDocs(query(collection(db, 'users'), where('organisationId', '==', organisationId)));
                    const staffData = staffQuerySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

                    // Fetch invitations data
                    const invitationsQuerySnapshot = await getDocs(query(collection(db, 'invitations'), where('organisationId', '==', organisationId)));
                    const invitationsData = invitationsQuerySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

                    setStaffData(staffData);
                    setInvitationsData(invitationsData);

                    // Check invite limit after fetching data
                    checkIfInviteLimitHasBeenReached(staffData, invitationsData);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        if (organisationId) {
            fetchData();
        }
    }, [organisationId]);

    const toggleInviteUserModal = () => {
        setInviteUserModalVisible(!isInviteUserModalVisible);
    };

    const toggleDeleteInviteModal = () => {
        setDeleteInviteModalVisible(!isDeleteInviteModalVisible);
    };

    const handleInviteSent = async () => {
        // Fetch invitations data again after sending invitation
        const invitationsQuerySnapshot = await getDocs(query(collection(db, 'invitations'), where('organisationId', '==', organisationId)));
        const invitationsData = invitationsQuerySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setInvitationsData(invitationsData);

        // Check invite limit again after sending invitation
        checkIfInviteLimitHasBeenReached(staffData, invitationsData);
    };

    const handleDeleteInvite = (deletedInviteId) => {
        setInvitationsData(invitationsData.filter(invite => invite.id !== deletedInviteId));
    };

    const checkIfInviteLimitHasBeenReached = async (staffData, invitationsData) => {
        try {
            if (organisationId) {
                const limit = await checkSubscriptionAccountInviteLimit(organisationId);
                const staffLength = staffData.length;
                const invitesLength = invitationsData.length;
                setIsInviteEnabled((staffLength + invitesLength) < limit);
            }
        } catch (error) {
            console.error("Error checking invite limit:", error);
            setIsInviteEnabled(false);
        }
    };

    return (
        <div className="w-full">
            <div className="mx-10 my-10">
                {isInviteUserModalVisible && <InviteUserModal onClose={toggleInviteUserModal} onInviteSent={handleInviteSent}/>}
                {isDeleteInviteModalVisible && <DeleteInviteModal onClose={toggleDeleteInviteModal} invite={inviteToDeleteInfo} onDeleteInvite={handleDeleteInvite}/>}

                <div className="flex -flex-row w-full place-content-between">
                    <h1 className="text-2xl font-bold pb-4">Staff</h1>
                    <button 
                        className={`bg-darkTealPreset hover:bg-tealPreset text-white font-bold py-2 px-4 rounded ${!isInviteEnabled && 'opacity-50 cursor-not-allowed'}`} 
                        onClick={toggleInviteUserModal}
                        disabled={!isInviteEnabled}
                        title={!isInviteEnabled ? 'User account invite limit reached' : null}
                    >
                        + Invite
                    </button>
                </div>
                
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                    {staffData.map((staffMember) => (
                        <StaffCard key={staffMember.id} userCard={staffMember} />
                    ))}
                </div>

                <h1 className="text-2xl font-bold pb-4 mt-4">Invitations</h1>
                
                {invitationsData.length === 0 ? (
                    <h2>There are no pending invites</h2>
                ) : (
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                        {invitationsData.map((invite) => (
                            <div className="flex flex-col bg-white rounded-sm border border-gray-200 shadow-md p-4" key={invite.id}>
                                <h2 className="text-lg font-bold">Email</h2>
                                <h2 className="text-gray-600">{invite.email}</h2>
                                <h2 className="text-lg font-bold">Expiry</h2>
                                <h2 className="text-gray-600">{invite.expiry.toDate().toLocaleString()}</h2>
                                <Trash2 
                                    className="cursor-pointer ml-auto text-gray-400 hover:text-black"
                                    onClick={() => {
                                        toggleDeleteInviteModal();
                                        setInviteToDeleteInfo(invite);
                                    }}  
                                />
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}
