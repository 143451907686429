import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAuth } from 'firebase/auth';

// Assuming you have exported 'app' from your 'firebase/fire' file
import { app } from '../firebase/fire'; 

const functions = getFunctions(app, "europe-west2");

const manageSubscription = async (user) => {
  try {
    const functionRef = httpsCallable(
      functions,
      'ext-firestore-stripe-payments-createPortalLink'
    );
    const { data } = await functionRef({
      customerId: user?.uid,
      returnUrl: window.location.origin,
      locale: 'auto',
    });

    if (data && data.url) {
      console.log('Reroute to Stripe portal: ', data.url);
      window.location.assign(data.url);
    } else {
      throw new Error('No url returned');
    }
  } catch (error) {
    console.error(error);
  }
};

export default manageSubscription;