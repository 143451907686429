import { Settings } from 'lucide-react';
import React, { useState } from 'react';
import StaffSettingsModal from './modals/StaffSettingsModal';
import { UserAuth } from '../context/AuthContext';

const StaffCard = ({ userCard }) => {
    
    const { user } = UserAuth();
    const [isAccessLevelModalVisible, setAccessLevelModalVisible] = useState(false);
    const [userAccessLevel, setUserAccessLevel] = useState(userCard.accessLevel); 
    const toggleAccessLevelModal = () => {
        setAccessLevelModalVisible(!isAccessLevelModalVisible);
    };

    const accessLevelUpdateSuccessful = (newAccessLevel) => {
        setUserAccessLevel(newAccessLevel); 
    };

    return (
        <div className="flex flex-col items-center bg-white rounded-sm border border-gray-200 shadow-md p-4">
            {isAccessLevelModalVisible && <StaffSettingsModal onClose={toggleAccessLevelModal} staffId={userCard?.uid} updateSuccessful={accessLevelUpdateSuccessful} />}
            {userCard?.imageRef ? (
                <div
                    className="h-24 w-24 rounded-full bg-sky-500 bg-cover bg-no-repeat bg-center"
                    style={{ backgroundImage: `url(${userCard?.imageRef})` }}
                ></div>
            ) : (
                <div
                    className="h-24 w-24 rounded-full bg-gray-300 text-gray-600 bg-cover bg-no-repeat bg-center text-center"
                    style={{ backgroundImage: `url(https://placehold.co/600x400/2a8795/fff?text=${(userCard?.firstName || '').charAt(0)}${(userCard?.LastName || '').charAt(0)})` }}
                ></div>
            )}
            <div className="mt-4">
                <h2 className="text-lg font-bold text-center">{userCard?.firstName} {userCard?.LastName}</h2>
                <p className="text-gray-600 text-center">{userCard?.email}</p>
                <p className="text-gray-600 text-center">{userCard?.role}</p>
                <p className="text-gray-600 text-center">({userAccessLevel})</p>
            </div>
            {(user?.accessLevel == "Admin" && user?.uid != userCard?.uid) && <Settings
                size={18}
                className='text-gray-400 hover:text-gray-700 cursor-pointer ml-auto'
                onClick={toggleAccessLevelModal}
            />}
        </div>
    );
};

export default StaffCard;
