import React, { useState, useEffect } from "react";
import { UserAuth } from "../../context/AuthContext";
import { createData, readAllData, readData, updateData } from "../../functions/crud";
import { X } from "lucide-react";
import { getTime, getDate } from "../utils";

function CreateNewInjuryModal({ onClose }) {
    const initialInjuryDetails = {
        firstName: "",
        lastName: "",
        injury: "",
        dateOfInjury: "",
        expectedRtp: "",
        playerId: "",
        status: "In Triage"
    }

    const { user } = UserAuth();
    const [newInjury, setNewInjury] = useState(initialInjuryDetails);
    const [currentPlayers, setCurrentPlayers] = useState([]);
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const organisationId = user?.organisationId;

    const [globalUpdateData, setGlobalUpdateData] = useState({
        title: "New Injury File Created",
        subTitle: ``,
        imageRef: `${user?.imageRef ? user?.imageRef : "https://placehold.co/600x400/2a8795/fff?text="}${(user?.firstName || '').charAt(0)}${(user?.LastName || '').charAt(0)}`,
        creatorFirstName: `${user?.firstName}`,
        creatorLastName: `${user?.LastName}`,
        creatorRole: `${user?.role}`,
        timeCreated: getTime(),
        dateCreated: getDate()
    });

    useEffect(() => {
        const fetchCurrentPlayers = async () => {
            try {
                const players = await readAllData('players', organisationId);
                setCurrentPlayers(players);
            } catch (error) {
                console.error('Error fetching players data:', error);
            }
        };

        fetchCurrentPlayers();
    }, [organisationId]);

    const fetchSelectedPlayerDetails = async (playerId) => {
        try {
            const playerDetails = await readData("players", playerId, organisationId);
            setSelectedPlayer(playerDetails);
            setNewInjury((prevNewInjury) => ({
                ...prevNewInjury,
                firstName: playerDetails.firstName,
                lastName: playerDetails.lastName,
                playerId: playerDetails.id,
            }));
            setGlobalUpdateData((prevGlobalUpdateData) => ({
                ...prevGlobalUpdateData,
                subTitle: `for ${playerDetails.firstName} ${playerDetails.lastName}`
            }));
        } catch (error) {
            console.log("Error Fetching Player details: ", error)
        }
    }

    const handleCreateInjury = async (e) => {
        e.preventDefault();
        try {
            const injuryId = await createData("injuries", newInjury, organisationId);
            const updatedPlayerData = { injuries: [...(selectedPlayer.injuries || []), injuryId] };
            await updateData("players", selectedPlayer.id, updatedPlayerData, organisationId);
            createData("globalUpdates", globalUpdateData, organisationId);
            onClose();
        } catch (error) {
            console.error("Error creating injury or updating player: ", error);
        }
    }

    const handleOnChangeInput = (e) => {
        setNewInjury({ ...newInjury, [e.target.name]: e.target.value });
    }

    const handleOnChangePlayerSelect = (e) => {
        const playerId = e.target.value;
        if (playerId) {
            fetchSelectedPlayerDetails(playerId);
        } else {
            setSelectedPlayer(null);
            setNewInjury(initialInjuryDetails);
        }
    }

    return (
        <div className={`fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50`}>
            <div className="bg-white p-8 rounded-md shadow-md max-w-3xl w-full overflow-hidden">
                <div className='flex flex-row items-center mb-4'>
                    <h2 className="text-2xl font-bold flex-1">Create New Injury Case File</h2>
                    <X className='text-gray-400 hover:text-gray-600 cursor-pointer' onClick={onClose} />
                </div>
                <div className="max-h-96 overflow-y-auto">
                    <form className="grid grid-cols-2 gap-4 max-w-2xl mx-auto" onSubmit={handleCreateInjury}>
                        <select
                            id="players"
                            value={selectedPlayer ? selectedPlayer.id : ""}
                            onChange={handleOnChangePlayerSelect}
                        >
                            <option value="">Select Player...</option>
                            {currentPlayers.map((player) => (
                                <option key={player.id} value={player.id}>
                                    {player.firstName} {player.lastName}
                                </option>
                            ))}
                        </select>

                        <input
                            type="text"
                            placeholder="Injury"
                            name="injury"
                            value={newInjury.injury}
                            onChange={handleOnChangeInput}
                        />

                        <div className="flex flex-col">
                            <label htmlFor="dateOfInjury" className="mr-auto text-gray-700">Date of Injury</label>
                            <input
                                type="date"
                                placeholder="Date of Injury"
                                name="dateOfInjury"
                                value={newInjury.dateOfInjury}
                                onChange={handleOnChangeInput}
                            />
                        </div>

                        <div className="flex flex-col">
                            <label htmlFor="expectedRtp" className="mr-auto text-gray-700">Expected return to play(RTP)</label>
                            <input
                                type="date"
                                placeholder="expected RTP"
                                name="expectedRtp"
                                value={newInjury.expectedRtp}
                                onChange={handleOnChangeInput}
                            />
                        </div>

                        <button className="bg-darkTealPreset hover:bg-tealPreset text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                            Create
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default CreateNewInjuryModal;