import React, { useState } from "react";
import TopGrid from "./dashboardComponents/TopGrid";
import Updates from "./dashboardComponents/Updates";
import CurrentPlayersTableAlt from "./dashboardComponents/CurrentPlayersTableAlt";
import CurrentInjuriesTableAlt from "./dashboardComponents/CurrentInjuriesTableAlt";
import { UserAuth } from "../context/AuthContext";

export default function Dashboard() {

    const { user } = UserAuth();
    const organisationId = user?.organisationId;
    const userAccessLevel = user?.accessLevel;
    const isUserGeneralUser = userAccessLevel == "General User";

    return (
        <div className="w-full">
            <div className="mx-10 my-10">
                <h1 className="text-2xl font-bold pb-4">Dashboard</h1>
                <TopGrid />
                <div className="flex flex-row">
                    <div className="w-3/4 mt-4">
                        {isUserGeneralUser && <CurrentPlayersTableAlt />}
                        {!isUserGeneralUser && <CurrentInjuriesTableAlt />}
                    </div>
                    <Updates />
                </div>
            </div>
        </div>
    )
}