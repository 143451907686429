import React, { useState, useEffect } from "react";
import CreateFormTemplateModal from "./modals/CreateFormTemplateModal";
import { UserAuth } from "../context/AuthContext";
import { readAllData } from "../functions/crud";
import FormsTable from "./dashboardComponents/FormsTable";

const Forms = () => {
    const { user } = UserAuth();
    const [isNewTemplateModalVisible, setNewTemplateModalVisible] = useState(false);
    const [formTemplates, setFormTemplates] = useState([]);

    const organisationId = user?.organisationId;

    useEffect(() => {
        const fetchTemplates = async () => {
            try {
                const templates = await readAllData("forms", organisationId);
                setFormTemplates(templates);
            } catch (error) {
                console.error("Error fetching questionnaire templates: ", error);
            }
        };

        fetchTemplates();
    }, [organisationId]);

    const toggleNewQuestionnaireTemplateModal = () => {
        setNewTemplateModalVisible(!isNewTemplateModalVisible);
    };

    return (
        <div className="w-full">
            <div className="mx-10 my-10">
                {isNewTemplateModalVisible && (
                    <CreateFormTemplateModal onClose={toggleNewQuestionnaireTemplateModal} />
                )}

                <div className="flex flex-row w-full place-content-between mb-8">
                    <h1 className="text-3xl font-bold">Forms</h1>
                    <button
                        className="bg-darkTealPreset hover:bg-tealPreset text-white font-bold py-2 px-4 rounded"
                        onClick={toggleNewQuestionnaireTemplateModal}
                    >
                        + New form Template
                    </button>
                </div>

                <div>
                    <div className="mt-4">
                        <FormsTable />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Forms;
