import React, { useState } from "react";
import { ArrowLeft } from "lucide-react"; // Importing the left arrow icon from lucide-react
import LoginComponent from "../components/nonUserPageComponents/LoginComponent";
import SignUpComponent from "../components/nonUserPageComponents/SignUpComponent";
import image from "../assets/login.jpg";

export default function Home() {
    const [isLogin, setIsLogin] = useState(true); // State to toggle between Login and SignUp

    // Function to toggle the form type
    const toggleForm = () => {
        setIsLogin(!isLogin);
    };

    // Function to navigate back to an external website
    const goBackToMainWebsite = () => {
        window.location.href = "https://matchfitt.co.uk"; // Redirects to the external website
    };

    return (
        <div className="flex flex-col lg:flex-row max-h-screen">
            {/* Left side: Login or Sign Up Form */}
            <div className="w-full lg:w-1/2 flex items-center justify-center bg-white p-4 lg:p-8 relative">
                {/* Left Arrow for navigation */}
                <button
                    onClick={goBackToMainWebsite}
                    className="absolute top-4 left-4 lg:top-8 lg:left-8 flex items-center text-gray-600 hover:text-gray-800"
                >
                    <ArrowLeft className="mr-2" size={24} /> {/* Left arrow icon */}
                    <span className="hidden lg:inline">Back to Home</span> {/* Optional text on larger screens */}
                </button>

                <div className="max-w-md w-full">
                    {isLogin ? <LoginComponent /> : <SignUpComponent />}

                    {/* Button to toggle between Login and Sign Up */}
                    <button
                        onClick={toggleForm}
                        className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 mb-4 lg:mb-12 rounded w-full"
                    >
                        {isLogin ? "Don't have an account? Sign Up" : "Already have an account? Log In"}
                    </button>
                </div>
            </div>

            {/* Right Side: Background Image */}
            <div className="w-full lg:w-1/2 hidden lg:block">
                <img
                    src={image}
                    alt="Background"
                    className="object-cover h-full w-full"
                />
            </div>
        </div>
    );
}
