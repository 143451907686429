import { X, Download } from 'lucide-react'
import React, { useState, useEffect} from 'react'
import { readData } from '../../functions/crud';
import { UserAuth } from "../../context/AuthContext";
import DocViewer from "react-doc-viewer"

function ReadFileModal({onClose, file}) {
    const { user } = UserAuth();
    const organisationId = user?.organisationId;

    return (
        <div className={`fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75`}>
            <div className="bg-white p-8 rounded-md shadow-md max-w-3xl w-full overflow-hidden">
                <div className='flex flex-row items-center mb-4'>
                    <h2 className="text-2xl font-bold flex-1">File</h2>
                    <X className='text-gray-400 hover:text-gray-600 cursor-pointer' onClick={onClose} />
                </div>
                {file && 
                    <div>
                        <a href={file.url} download={file.name}>
                            <Download className="text-blue-600 hover:text-blue-800 cursor-pointer mr-2" />
                            Download File: {file.name}
                        </a>
                        <img
                            src={file.url}
                            alt="File"
                            className="w-full h-full object-contain"
                        />
                    </div>
                }
            </div>
        </div>
    )
}

export default ReadFileModal;
