import React, { useState, useEffect } from 'react';
import { UserAuth } from '../../context/AuthContext';
import { X, HelpCircle } from 'lucide-react';
import { Timestamp } from 'firebase/firestore';
import { generateRandomId, createMail, createUserInvitation } from '../../functions/crud';
import Tooltip from '../Tooltip';

function InviteUserModal({ onClose, onInviteSent }) {
    const { user } = UserAuth();
    const organisationId = user?.organisationId;
    const [isToolTipVisible, setToolTipVisible] = useState(false);
    const [inviteDetails, setInviteDetails] = useState({
        id: generateRandomId(),
        email: '',
        accessLevel: '',
        organisationId: organisationId,
        createdAt: Timestamp.fromMillis(Date.now()),
        expiry: Timestamp.fromMillis(Date.now() + 24 * 60 * 60 * 1000),
    });

    const [mailDetails, setMailDetails] = useState({
        to: [],
        message: {
            subject: 'MatchFitt Invitation',
            html: `You have been invited to join your organisation on the Matchfitt App. <br><br> The link will last for 24 hours, after which it will expire. <br><br> Please click the link to sign up! <br><br>`,
        },
    });

    const handleInvite = async (e) => {
        e.preventDefault();
        const updatedInviteDetails = {
            ...inviteDetails,
            email: mailDetails.to[0],
        };
        setInviteDetails(updatedInviteDetails);
        
        const inviteUrl = `https://matchfitt.co.uk/invitationsignup/${updatedInviteDetails.id}/${organisationId}/${updatedInviteDetails.email}`;
        await createUserInvitation(updatedInviteDetails);
    
        const inviteLink = `<a href="${inviteUrl}">Click here to complete sign up!</a>`;
        const updatedMailDetails = {
            ...mailDetails,
            message: {
                ...mailDetails.message,
                html: `You have been invited to join your organisation on the Matchfitt App. <br><br> 
                The link will last for 24 hours, after which it will expire. <br><br> 
                Please ${inviteLink} <br><br>`,
            },
        };
    
        await createMail(updatedMailDetails);
        setMailDetails(updatedMailDetails);
        onInviteSent();
        onClose();
        alert('User Invited');
    };
    
    
    

    return (
        <div className={`fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50`}>
            <div className="bg-white p-8 rounded-md shadow-md max-w-xl w-full overflow-hidden">
                <div className="flex flex-row items-center mb-4">
                    <h2 className="text-2xl font-bold flex-1">Invite User</h2>
                    <X className="text-gray-400 hover:text-gray-600 cursor-pointer" onClick={onClose} />
                </div>
                <div className="max-h-96 overflow-y-auto">
                    <form className="flex flex-col gap-2" onSubmit={handleInvite}>
                        <input
                            type="text"
                            placeholder="Email"
                            required
                            onChange={(e) => {
                                setInviteDetails({ ...inviteDetails, email: e.target.value });
                                setMailDetails({ ...mailDetails, to: [e.target.value] });
                            }}
                        />
                        
                        <div className='flex flex-row'>
                            <div className='flex flex-col'>
                                <label htmlFor="accessLevel">Access Level</label>
                                <select
                                    className={`font-semibold py-2 px-2 mr-auto text-gray-600 w-[175px]`}
                                    value={inviteDetails.accessLevel}
                                    name='accessLevel'
                                    required
                                    onChange={(e) => {
                                        setInviteDetails({ ...inviteDetails, accessLevel: e.target.value });
                                    }}
                                >
                                    <option value="" disabled selected>Access Level...</option>
                                    <option value="Admin">Admin</option>
                                    <option value="Healthcare User">Healthcare User</option>
                                    <option value="General User">General User</option>
                                </select>
                            </div>

                            <HelpCircle 
                                className='text-gray-400 hover:text-gray-700 ml-2 mt-10 cursor-pointer'
                                onClick={() => setToolTipVisible(!isToolTipVisible)}
                            />

                        </div>

                        {isToolTipVisible && <div className='text-gray-500'>
                            Admin: This role has access to everything, including managing organisation settings and viewing all healthcare data.<br/><br/>
                            Healthcare User: This role has access to healthcare data and some administrative functions related to managing healthcare data, but not full access to manage organisation settings.<br/><br/>
                            General User: This role has limited access and can only view general information about players, without access to healthcare data or administrative functions.
                        </div>}

                        <button className="bg-darkTealPreset hover:bg-tealPreset text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                            Invite
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default InviteUserModal;
