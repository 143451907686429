import React, { useState } from "react";
import { signUp } from "../functions/auth";
import { useNavigate } from "react-router-dom";
import Lottie from 'react-lottie';
import signUpAnimation from "../assets/signUpAnimation.json";

export default function Signup() {
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState("");
    const [LastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [organisationName, setOrganisationName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const userData = {
        firstName,
        LastName,
        email,
        organisationName,
        accessLevel: "Admin"
    };

    const organisationData = {
        organisationName,
        subscriptionStatus: "inactive"
    };

    const handleSignUp = (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            alert("Passwords do not match");
            return;
        } else if (!email) {
            alert("Email is required");
            return;
        } else if (!password) {
            alert("Password is required");
            return;
        } else {
            signUp(email, password, userData, organisationData);
            navigate("/dashboard");
        }
    };

    return (
        <div className="flex flex-col md:flex-row gap-4 mx-10 my-10 bg-white">
            <div id="left-segment" className="w-full md:w-1/2 my-10 md:my-32">
                <h1 className="text-3xl font-bold text-center mb-12">Sign up</h1>
                <form className="grid grid-cols-1 md:grid-cols-1 gap-4 max-w-5xl mx-auto" onSubmit={handleSignUp}>
                    <input 
                        type="text" 
                        placeholder="First Name" 
                        onChange={(e) => setFirstName(e.target.value)}
                        className="p-2 border rounded-md w-80 mx-auto"
                    />
                    <input 
                        type="text" 
                        placeholder="Last Name" 
                        onChange={(e) => setLastName(e.target.value)}
                        className="p-2 border w-80 mx-auto rounded-md"
                    />
                    <input 
                        type="text" 
                        placeholder="Email" 
                        onChange={(e) => setEmail(e.target.value)}
                        className="p-2 border w-80 mx-auto rounded-md"
                    />
                    <input 
                        type="text" 
                        placeholder="Organisation Name" 
                        onChange={(e) => setOrganisationName(e.target.value)}
                        className="p-2 border w-80 mx-auto rounded-md"
                    />
                    <input 
                        type="password" 
                        placeholder="Password" 
                        onChange={(e) => setPassword(e.target.value)}
                        className="p-2 border w-80 mx-auto rounded-md"
                    />
                    <input 
                        type="password" 
                        placeholder="Confirm Password" 
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className="p-2 border w-80 mx-auto rounded-md"
                    />
                    <button className="ring-2 ring-tealPreset hover:bg-tealPreset hover:text-white w-80 mx-auto font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                        Sign Up
                    </button>
                </form>
            </div>
            <div id="right-segment" className="w-full md:w-1/2 flex justify-center items-center">
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: signUpAnimation,
                        rendererSettings: {
                          preserveAspectRatio: 'xMidYMid slice'
                      }
                    }}
                    height={500} // Adjust height as needed for mobile
                    width={500} // Adjust width as needed for mobile
                    speed={0.5}
                />
            </div>
        </div>
    );
}
