import React, { useState } from "react";
import { signIn } from "../functions/auth";
import { useNavigate } from "react-router-dom";

export default function Login() {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const handleLogin = (e) => {
        e.preventDefault();
        signIn(email, password);
        navigate("/dashboard");
    }

    return (
        <div>
            <h1 className="text-3xl font-bold text-center mb-12">Login</h1>

            <form className="grid grid-cols-2 gap-4 max-w-5xl mx-auto" onSubmit={handleLogin}>

                <input type="text" placeholder="Email" 
                    onChange={(e) => {
                        setEmail(e.target.value);
                    }}
                />

                <input type="password" placeholder="Password" 
                    onChange={(e) => {
                        setPassword(e.target.value);
                    }}
                />

                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                    Login
                </button>

            </form>
        </div>
    )
}