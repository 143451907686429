import React, {useState, useEffect} from 'react'
import { deleteUser, readUser, checkisUserOwner } from '../../functions/crud';
import { X } from 'lucide-react';
import { UserAuth } from '../../context/AuthContext';
import { deleteCurrentUserAccount, logout } from '../../functions/auth';

function DeleteStaffModal({onClose, deletionSuccessfull}) {

    const { user } = UserAuth();
    const organisationId = user?.organisationId;
    const userId = user?.uid;
    const [isCurrentUserOwner, setIsCurrentUserOwner] = useState(false);

    useEffect(() => {
        const checkIfCurrentUserIsOwner = async () => {
            try {
                const isOwner = await checkisUserOwner(organisationId, user.uid)
                setIsCurrentUserOwner(isOwner);
                console.log(isOwner)
            } catch (error) {
                console.error("Error checking if current user is owner:", error);
            }
        }

        checkIfCurrentUserIsOwner();
    }, [organisationId]);

    const handleDeleteButtonClicked = async () => {
        try {
            await deleteUser(userId);
            await deleteCurrentUserAccount();
            onClose();
            deletionSuccessfull();
            logout();
        } catch (error) {
            console.error("Error deleting user account:", error);
        }
    }
    

  return (
    <div className={`fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75`}>
    <div className="bg-white p-8 rounded-md shadow-md max-w-lg w-full overflow-hidden">
        <div className='flex flex-row items-center mb-4'>
            <h2 className="text-2xl font-bold flex-1">Account Deletion for {user?.firstName} {user?.LastName}</h2>
            <X className='text-gray-400 hover:text-gray-600 cursor-pointer' onClick={onClose} />
        </div>
        
        {isCurrentUserOwner? (<div className="max-h-96 overflow-y-auto">
            <h1 className='mb-5'>{user?.firstName} {user?.LastName} you are the owner of the organisation, and therefore cannot delete your account without transferring ownership to a different staff member, or deleting the organisation in its entirety.</h1>
            <h1 className='mb-5'>Please see the FAQ's tab in the organisation sidebar section for more information on this.</h1>

        </div>) : (
        <div className="max-h-96 overflow-y-auto">
            <h1 className='mb-5'>{user?.firstName} {user?.LastName}'s account will be deleted in it's entirety. This includes any and all data that is currently held relating to your account.</h1>
            <h1 className='text-lg font-bold'>Are you sure you want to delete your account {user?.firstName} {user?.LastName}?</h1>
        
            <div>
                <button
                    className='mr-auto bg-red-400 hover:bg-red-600 text-white font-bold px-4 py-2 rounded'
                    onClick={handleDeleteButtonClicked}
                >
                    Delete
                </button>
                <button
                    className='mr-auto ml-2 bg-gray-400 hover:bg-gray-700 text-white font-bold px-4 py-2 rounded'
                    onClick={() => onClose()}
                >
                    Cancel
                </button>
            </div>
        </div>)}
    </div>
</div>
  )
}

export default DeleteStaffModal