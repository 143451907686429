import React from 'react'

function SuccessfullyDeletedModal({ onClose, deletedElementName }) {
    return (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-4 rounded-lg text-center">
                <h2 className="text-xl font-bold mb-4">
                    {deletedElementName} has been successfully Deleted!
                </h2>
                <h2 className="text-l mb-4">
                    Please refresh the page if this does not appear immediately.
                </h2>
                <button onClick={onClose} className="bg-gray-400 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                    Close
                </button>
            </div>
        </div>
    )
}

export default SuccessfullyDeletedModal