import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { readData, updateData } from '../functions/crud';
import { UserAuth } from "../context/AuthContext";
import { ChevronLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";

const FormDetails = () => {
    const { user } = UserAuth();
    const params = useParams();
    const [formDetails, setFormDetails] = useState(null);
    const [newQuestion, setNewQuestion] = useState('');
    const [newQuestionType, setNewQuestionType] = useState('text'); // Default question type
    const [newQuestionOptions, setNewQuestionOptions] = useState(['']);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchFormDetails = async () => {
            try {
                const form = await readData('forms', params.id, user?.organisationId);
                setFormDetails(form);
            } catch (error) {
                console.error('Error reading document:', error);
            }
        };

        fetchFormDetails();
    }, [params.id, user?.organisationId]);

    const handleAddQuestion = () => {
        if (newQuestion.trim()) {
            const newQuestionData = {
                type: newQuestionType,
                text: newQuestion.trim(),
                options: newQuestionType === 'multiple-choice' || newQuestionType === 'checkbox' ? newQuestionOptions : []
            };
            const updatedQuestions = [...formDetails.questions, newQuestionData];
            setFormDetails({ ...formDetails, questions: updatedQuestions });
            setNewQuestion('');
            setNewQuestionType('text'); // Reset question type to default after adding question
            setNewQuestionOptions(['']); // Reset options
        }
    };

    const handleRemoveQuestion = (index) => {
        const updatedQuestions = formDetails.questions.filter((_, i) => i !== index);
        setFormDetails({ ...formDetails, questions: updatedQuestions });
    };

    const handleSaveForm = () => {
        updateData('forms', params.id, formDetails, user?.organisationId)
            .then(() => {
                alert('Form updated successfully');
            })
            .catch(error => {
                console.error('There was an error updating the form!', error);
            });
    };

    const handleOptionChange = (qIndex, oIndex, value) => {
        const updatedQuestions = formDetails.questions.map((question, index) => {
            if (index === qIndex) {
                const updatedOptions = question.options.map((option, idx) => (
                    idx === oIndex ? value : option
                ));
                return { ...question, options: updatedOptions };
            }
            return question;
        });
        setFormDetails({ ...formDetails, questions: updatedQuestions });
    };

    const addOption = (qIndex) => {
        const updatedQuestions = formDetails.questions.map((question, index) => {
            if (index === qIndex) {
                return { ...question, options: [...question.options, ""] };
            }
            return question;
        });
        setFormDetails({ ...formDetails, questions: updatedQuestions });
    };

    const removeOption = (qIndex, oIndex) => {
        const updatedQuestions = formDetails.questions.map((question, index) => {
            if (index === qIndex) {
                const updatedOptions = question.options.filter((_, idx) => idx !== oIndex);
                return { ...question, options: updatedOptions };
            }
            return question;
        });
        setFormDetails({ ...formDetails, questions: updatedQuestions });
    };

    const handleNewOptionChange = (index, value) => {
        const updatedOptions = newQuestionOptions.map((option, idx) => (
            idx === index ? value : option
        ));
        setNewQuestionOptions(updatedOptions);
    };

    const addNewOption = () => {
        setNewQuestionOptions([...newQuestionOptions, '']);
    };

    const removeNewOption = (index) => {
        const updatedOptions = newQuestionOptions.filter((_, idx) => idx !== index);
        setNewQuestionOptions(updatedOptions);
    };

    if (!formDetails) return <div className="mx-10 my-10">Loading...</div>;

    return (
        <div className="flex flex-col items-center mx-10 my-10">
            <div className="flex items-center mb-4">
                <ChevronLeft
                    className="text-gray-300 hover:text-gray-700 cursor-pointer"
                    size={30}
                    onClick={() => navigate("/forms")}
                />
                <h1 className="text-2xl font-bold ml-2">{formDetails.title}</h1>
            </div>
            <ul className="w-full max-w-3xl border rounded-lg shadow-lg p-4 bg-white">
                {formDetails.questions.map((question, index) => (
                    <li key={index} className="flex flex-col py-2 border-b">
                        <div className="flex items-center justify-between">
                            <span>{question.text} ({question.type})</span>
                            <button
                                onClick={() => handleRemoveQuestion(index)}
                                className="text-red-500 hover:text-red-700"
                            >
                                Remove
                            </button>
                        </div>
                        {(question.type === "multiple-choice" || question.type === "checkbox") && (
                            <div className="flex flex-col mt-2 pl-4">
                                {question.options.map((option, oIndex) => (
                                    <div key={oIndex} className="flex items-center space-x-2 mb-2">
                                        <input
                                            type="text"
                                            value={option}
                                            onChange={(e) => handleOptionChange(index, oIndex, e.target.value)}
                                            className="p-2 border border-gray-300 rounded flex-1"
                                            placeholder="Option"
                                        />
                                        <button
                                            onClick={() => removeOption(index, oIndex)}
                                            className="p-2 bg-red-500 text-white rounded"
                                        >
                                            Remove
                                        </button>
                                    </div>
                                ))}
                                <button
                                    onClick={() => addOption(index)}
                                    className="p-2 bg-blue-300 hover:bg-blue-400 text-white rounded self-start"
                                >
                                    Add Option
                                </button>
                            </div>
                        )}
                    </li>
                ))}
            </ul>
            <div className="mt-4 w-full max-w-3xl flex flex-col space-y-2">
                <div className="flex items-center space-x-2">
                    <input
                        type="text"
                        value={newQuestion}
                        onChange={(e) => setNewQuestion(e.target.value)}
                        placeholder="New question"
                        className="p-2 border border-gray-300 rounded-l w-full"
                    />
                    <select
                        value={newQuestionType}
                        onChange={(e) => setNewQuestionType(e.target.value)}
                        className="p-2 border border-gray-300 rounded-r"
                    >
                        <option value="text">Single Line Text</option>
                        <option value="paragraph">Paragraph Text</option>
                        <option value="multiple-choice">Multiple Choice</option>
                        <option value="checkbox">Checkboxes</option>
                    </select>
                </div>
                {(newQuestionType === "multiple-choice" || newQuestionType === "checkbox") && (
                    <div className="flex flex-col space-y-2">
                        {newQuestionOptions.map((option, index) => (
                            <div key={index} className="flex items-center space-x-2">
                                <input
                                    type="text"
                                    value={option}
                                    onChange={(e) => handleNewOptionChange(index, e.target.value)}
                                    className="p-2 border border-gray-300 rounded flex-1"
                                    placeholder="Option"
                                />
                                <button
                                    onClick={() => removeNewOption(index)}
                                    className="p-2 bg-red-500 text-white rounded"
                                >
                                    Remove
                                </button>
                            </div>
                        ))}
                        <button
                            onClick={addNewOption}
                            className="p-2 bg-blue-300 hover:bg-blue-400 text-white rounded self-start"
                        >
                            Add Option
                        </button>
                    </div>
                )}
                <button
                    onClick={handleAddQuestion}
                    className="bg-blue-300 hover:bg-blue-400 text-white p-2 rounded"
                >
                    Add Question
                </button>
            </div>
            <button
                onClick={handleSaveForm}
                className="bg-darkTealPreset hover:bg-tealPreset text-white font-bold py-2 px-4 rounded mt-4 focus:outline-none focus:shadow-outline"
            >
                Save Form
            </button>
        </div>
    );
};

export default FormDetails;
