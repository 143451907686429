import { createUserWithEmailAndPassword, signInWithEmailAndPassword, deleteUser } from "firebase/auth";
import { auth, db } from "../firebase/fire";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { generateRandomId } from "../functions/crud";

export const signIn = async (email, password) => {
    try {
        const res = await signInWithEmailAndPassword(auth, email, password);
        return res.user;
    } catch (error) {
        return error;
    }
};

export const logout = async () => {
    auth.signOut()
    .then(function() {
        console.log("Login Successful!")
    })
    .catch(function(error) {
        console.log("Login Unsuccessful!, error: ", error)
    });
};

export const signUp = async (email, password, userData, organisationData) => {
    try {
        const user = await createUserWithEmailAndPassword(auth, email, password);
        
        const organisationId = generateRandomId();
        await setDoc(doc(db, "organisations", organisationId), {
            ...organisationData,
            uid: organisationId,
        });

        await setDoc(doc(db, "users", user.user.uid), {
            ...userData,
            organisationId: organisationId,
            uid: user.user.uid,
        });

        return user.user;
    } catch (error) {
        return error;
    }
};

export const inviteSignUp = async (email, password, userData) => {
    try {
        const user = await createUserWithEmailAndPassword(auth, email, password);

        await setDoc(doc(db, "users", user.user.uid), {
            ...userData,
            uid: user.user.uid,
        });

        return user.user;
    } catch (error) {
        return error;
    }
};

export const deleteCurrentUserAccount = async () => {
    try {
        await deleteUser(auth.currentUser);

        return "User account successfully deleted.";
    } catch (error) {
        console.error("Error deleting user account:", error);
        return error;
    }
};

export const signUp2 = async (email, password, userData, organisationData) => {
    try {
        const user = await createUserWithEmailAndPassword(auth, email, password);
        const organisationId = generateRandomId();
        await setDoc(doc(db, "organisations", organisationId), {
            ...organisationData,
            uid: organisationId,
        });

        const usersCollectionRef = collection(db, "organisations", organisationId, "users")
        await addDoc(usersCollectionRef, {
            ...userData,
            organisationId: organisationId, 
            uid: user.user.uid});

        return user.user;
    } catch (error) {
        return error;
    }
};