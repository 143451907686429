import React from 'react'
import { CalendarDays } from 'lucide-react'

function UpdateCard({title, subTitle, imageRef, creatorFirstName, creatorLastName, creatorRole, timeCreated, dateCreated}) {
  return (
    <div className='flex flex-row pr-2 mt-4'>
        <div id='left-segment' className='flex flex-col'>
            <div className='rounded-full bg-gray-200 p-1.5'>
                <CalendarDays size={12}/>
            </div>
            <div className='border-l-2 border-gray-200 h-full ml-3 mt-2'>

            </div>

        </div>

        <div id='right-segment' className='flex flex-col ml-2'>
            <h1 className='font-bold text-lg leading-none'>{title}</h1>
            <h1 className='font-semibold text-sm mt-1'>{subTitle}</h1>
            
            <div className='flex flex-row mt-1'>
                <div 
                    className="w-8 h-8 rounded-full bg-sky-500 bg-cover bg-no-repeat bg-center"
                    style={{ backgroundImage: `url(${imageRef})` }}
                >
                </div>
                <div className='flex flex-col ml-1'>
                    <h1 className='text-gray-400'>{creatorFirstName + " " + creatorLastName}</h1>
                    <h1 className='text-gray-400'>{creatorRole}</h1>
                </div>
            </div>

            <h1 className='text-gray-600 mt-1'>{timeCreated + " " + dateCreated}</h1>
        </div>
    </div>
  )
}

export default UpdateCard