import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import Signup from "../pages/Signup";
import Login from "../pages/Login"
import NavBar from "../components/nonUserPageComponents/Navbar";
import InviteSignup from "../pages/InviteSignup";
import Footer from "../components/nonUserPageComponents/Footer";
import FormToComplete from "../pages/FormToComplete";

export default function NonUserRoutes() {
    return (
        <div>
            {/* <NavBar /> */}
                <Routes>
                    <Route path="/" element={<Home />}/>
                    <Route path="/signup" element={<Signup />}/>
                    <Route path="/login" element={<Login />}/>
                    <Route path="/*" element={<Home />}/>
                    <Route path="/invitationsignup/:id/:organisationId/:email" element={<InviteSignup />} />
                    <Route path="/formtocomplete/:id/:organisationId/:email" element={<FormToComplete />} />
                </Routes>
            <div className="bg-gray-100">
                {/* <Footer /> */}
            </div>
        </div>
    )
}