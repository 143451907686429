import React, { useEffect, useState } from 'react';
import { MoreHorizontal } from 'lucide-react';
import { listenToCollection } from "../../functions/crud";
import { db } from "../../firebase/fire";
import { collection, getDocs } from "firebase/firestore";
import { UserAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import SendFormModal from '../modals/SendFormModal'; // Import the modal component

export default function FormsTable() {
    const { user } = UserAuth();
    const [forms, setForms] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [showDropdown, setShowDropdown] = useState(null); // State to manage dropdown visibility
    const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility
    const [selectedFormId, setSelectedFormId] = useState(null); // State to store selected form id
    const organisationId = user?.organisationId;
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const collectionRef = collection(db, "organisations", organisationId, "forms");
                const snapshot = await getDocs(collectionRef);
                if (!snapshot.empty) {
                    const unsubscribe = listenToCollection("forms", organisationId, (newData) => {
                        setForms(newData);
                    });
                    return unsubscribe;
                } else {
                    console.log("The 'forms' collection does not exist or is empty.");
                }
            } catch (error) {
                console.error("Error checking collection:", error);
            }
        };

        fetchData();
    }, [organisationId]);

    const handleEditClick = (id) => {
        navigate(`/form/${id}`);
    };

    const handleSendClick = (id) => {
        setSelectedFormId(id); // Store selected form id
        setIsModalOpen(true); // Open modal
    };

    const filteredForms = forms.filter(form =>
        form.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            {/* Search input */}
            <div className="flex flex-column sm:flex-row flex-wrap space-y-4 sm:space-y-0 items-center justify-between pb-4">
                <label htmlFor="table-search" className="sr-only">Search</label>
                <div className="relative">
                    <div className="absolute inset-y-0 left-0 rtl:inset-r-0 rtl:right-0 flex items-center ps-3 pointer-events-none">
                        <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg>
                    </div>
                    <input
                        type="text"
                        id="table-search"
                        className="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Search for forms"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div>
            </div>

            {/* Forms table */}
            <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-200">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            Title
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Number of Questions
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Date & Time Created
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {filteredForms.length === 0 ? (
                        <tr className="bg-white border-b hover:bg-gray-50">
                            <td colSpan="4" className="text-center">No forms found. (If you have added a form and do not see it here, please refresh your browser!)</td>
                        </tr>
                    ) : (
                        filteredForms.map((form) => (
                            <tr key={form.id} className='bg-white border-b hover:bg-gray-50"'>
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    {form.title || '-'}
                                </th>
                                <td className='px-6 py-4'>{form.questions.length || 0}</td>
                                <td className='px-6 py-4'>
                                    {form.dateCreated || '-'} {form.timeCreated || '-'}
                                </td>
                                <td className='px-6 py-4 relative'>
                                    {/* Dropdown menu */}
                                    <MoreHorizontal size={22} className='cursor-pointer' onClick={() => setShowDropdown(showDropdown === form.id ? null : form.id)} />
                                    {showDropdown === form.id && (
                                        <div className="absolute right-0 w-48 bg-white border border-gray-200 rounded-lg shadow-lg z-50">
                                            <button
                                                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                onClick={() => handleEditClick(form.id)}
                                            >
                                                Edit Form
                                            </button>
                                            <button
                                                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                onClick={() => handleSendClick(form.id)}
                                            >
                                                Send Form
                                            </button>
                                        </div>
                                    )}
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>

            {/* SendFormModal */}
            {isModalOpen && (
                <SendFormModal
                    onClose={() => setIsModalOpen(false)}
                    formId={selectedFormId}
                />
            )}
        </div>
    );
}
