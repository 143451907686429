import React, { useState } from 'react';
import { X } from 'lucide-react';
import { readData, updateData } from '../../functions/crud';
import { UserAuth } from "../../context/AuthContext";
import { storage } from '../../firebase/fire';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

function AddFileModal({ onClose, injuryId, onFileUploadSuccess, files }) {

    const { user } = UserAuth();
    const organisationId = user?.organisationId;
    const [fileUpload, setFileUpload] = useState(null);

    const handleOnClickSave = async () => {
        if (fileUpload === null) {
            console.log("No file uploaded");
            return;
        }

        const fileName = fileUpload.name;
        const fileRef = ref(storage, `${organisationId}/injuries/${injuryId}/${fileName}`);
        
        try {
            // Check if the file with the same name already exists
            const fileExists = files.some(file => file.name === fileName);
            let adjustedFileName = fileName;
            
            if (fileExists) {
                // Get the file extension
                const fileExtension = fileName.split('.').pop();
                
                // Extract the filename without the extension
                const baseFileName = fileName.replace(`.${fileExtension}`, '');
                
                // Find the highest index among files with the same base name
                const highestIndex = files.reduce((maxIndex, file) => {
                    const regex = new RegExp(`^${baseFileName} \\(([0-9]+)\\)\\.${fileExtension}$`);
                    const match = file.name.match(regex);
                    if (match && match[1]) {
                        const index = parseInt(match[1], 10);
                        return Math.max(maxIndex, index);
                    }
                    return maxIndex;
                }, 0);
                
                // Adjust the filename by appending an incrementing number in brackets
                adjustedFileName = `${baseFileName} (${highestIndex + 1}).${fileExtension}`;
            }

            // Upload the file with the adjusted filename
            await uploadBytes(ref(storage, `${organisationId}/injuries/${injuryId}/${adjustedFileName}`), fileUpload);
            const downloadURL = await getDownloadURL(fileRef);
            const newFile = { url: downloadURL, name: adjustedFileName };
            const updatedFiles = [...files, newFile];

            await updateData("injuries", injuryId, { files: updatedFiles }, organisationId);
            onFileUploadSuccess(downloadURL, adjustedFileName);
            onClose();
        } catch (error) {
            console.error("Error uploading image:", error);
        }
    };
    
    return (
        <div className={`fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75`}>
            <div className="bg-white p-8 rounded-md shadow-md max-w-3xl w-full overflow-hidden">
                <div className='flex flex-row items-center mb-4'>
                    <h2 className="text-2xl font-bold flex-1">Add File</h2>
                    <X className='text-gray-400 hover:text-gray-600 cursor-pointer' onClick={onClose} />
                </div>
                <div className='flex flex-row'>
                    <div className="max-h-96 overflow-y-auto flex flex-col">
                        <input 
                            type='file' 
                            className='mb-4'
                            onChange={(e) => {
                                setFileUpload(e.target.files[0])
                            }}
                        />
                        <button 
                            className='mr-auto bg-darkTealPreset hover:bg-tealPreset text-white font-bold px-4 py-2 rounded'
                            onClick={handleOnClickSave}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddFileModal;
