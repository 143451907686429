import { db } from "../firebase/fire";
import {
    collection,
    doc,
    setDoc,
    onSnapshot
} from "firebase/firestore";
import { generateRandomId, updateOrganisation } from "../functions/crud";

const startSubscription = async (user, priceId) => {
    if (!user) {
        console.error("User is not authenticated");
        return;
    }

    const userId = user?.uid;
    const organisationId = user?.organisationId;
    const id = generateRandomId();

    try {
        const docRef = doc(db, "users", userId, "checkout_sessions", id);
        await setDoc(docRef, {
            id,
            price: priceId, // Replace with your price ID
            success_url: window.location.origin,
            cancel_url: window.location.origin,
        });

        // Update the owner field for the organisation using updateData function
        await updateOrganisation({ owner: userId }, organisationId);

        // Wait for the CheckoutSession to get attached by the extension
        onSnapshot(docRef, (snap) => {
            const { error, url } = snap.data();
            if (error) {
                // Show an error to your customer and
                // inspect your Cloud Function logs in the Firebase console.
                console.log(error)
                alert(`An error occurred: ${error.message}`);
            }
            if (url) {
                // We have a Stripe Checkout URL, let's redirect.
                window.location.assign(url);
            }
        });
    } catch (error) {
        console.error('Error starting subscription:', error);
    }
};

export default startSubscription;