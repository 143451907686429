import React, { useState } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { X } from "lucide-react";

function ForgottenPasswordModal({ onClose }) {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState(null);
    const [messageType, setMessageType] = useState(null); // 'success' or 'error'
    const auth = getAuth();

    const handleSubmitButtonClicked = (e) => {
        e.preventDefault();
        sendPasswordResetEmail(auth, email)
            .then(() => {
                setMessage("Password reset email sent!");
                setMessageType("success");
            })
            .catch((error) => {
                setMessage(`Error: ${error.message}`);
                setMessageType("error");
            });
    };

    return (
        <div className={`fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50`}>
            <div className="bg-white p-8 rounded-md shadow-md max-w-xl w-full overflow-hidden">
                <div className='flex flex-row items-center mb-4'>
                    <h2 className="text-2xl font-bold flex-1">Forgotten Password</h2>
                    <X className='text-gray-400 hover:text-gray-600 cursor-pointer' onClick={onClose} />
                </div>
                <div className="max-h-96 overflow-y-auto">
                    <form className="flex flex-col gap-2" onSubmit={handleSubmitButtonClicked}>
                        <input
                            type="text"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />

                        <button className="bg-darkTealPreset hover:bg-tealPreset text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                            Submit
                        </button>
                    </form>
                    {message && (
                        <p className={`mt-4 text-center ${messageType === 'success' ? 'text-green-500' : 'text-red-500'}`}>
                            {message}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ForgottenPasswordModal;

