import React from 'react'
import { X } from 'lucide-react';
import { UserAuth } from "../../context/AuthContext";
import { deleteData, deleteDocumentsByField, deleteFilesInFolder } from '../../functions/crud';
import { useNavigate } from "react-router-dom"

function DeleteInjuryModal({onClose, injury, onDeleteInjury}) {

    const { user } = UserAuth();
    const organisationId = user?.organisationId;
    const navigate = useNavigate();

    const handleDeleteButtonClicked = async () => {
        try {
            await deleteData("injuries", injury?.id, organisationId);

            await deleteDocumentsByField("soapNotes", "injuryId", injury?.id, organisationId);

            await deleteFilesInFolder(organisationId, "injuries", injury?.id);

            onDeleteInjury();
            onClose();
            navigate(`/injuries`);
        } catch (error) {
            console.error("Error deleting player:", error);
        }
    };

    return (
        <div className={`fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75`}>
            <div className="bg-white p-8 rounded-md shadow-md max-w-lg w-full overflow-hidden">
                <div className='flex flex-row items-center mb-4'>
                    <h2 className="text-2xl font-bold flex-1">Delete Injury Case File</h2>
                    <X className='text-gray-400 hover:text-gray-600 cursor-pointer' onClick={onClose} />
                </div>
                <div className="max-h-96 overflow-y-auto">
                    <h1 className='text-lg font-bold'>Are you sure you want to delete this injury case file?</h1>
                    <h1 className='text-md font-semibold my-5'>All information and uploaded files relating to this injury case file will be deleted permanently if so.</h1>

                    <div>
                        <button
                            className='mr-auto bg-red-400 hover:bg-red-600 text-white font-bold px-4 py-2 rounded'
                            onClick={handleDeleteButtonClicked}
                        >
                            Delete
                        </button>
                        <button
                            className='mr-auto ml-2 bg-gray-400 hover:bg-gray-700 text-white font-bold px-4 py-2 rounded'
                            onClick={() => onClose()}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeleteInjuryModal