import React, { Fragment, useEffect, useState } from 'react';
import { UserAuth } from "../../context/AuthContext";
import { logout } from '../../functions/auth';
import { listenToCollection } from "../../functions/crud";
import { db } from "../../firebase/fire";
import { collection, getDocs } from "firebase/firestore";
import { Menu, Transition } from '@headlessui/react';
import { Search } from "lucide-react";
import { useNavigate } from 'react-router-dom';
import classNames from "classnames";

export default function DashboardHeader() {
    const { user } = UserAuth();
    const [currentPlayers, setCurrentPlayers] = useState([]);
    const organisationId = user?.organisationId;
    const [searchQuery, setSearchQuery] = useState('');
    const [showSearchResults, setShowSearchResults] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const collectionRef = collection(db, "organisations", organisationId, "players");
                const snapshot = await getDocs(collectionRef);

                if (!snapshot.empty) {
                    const unsubscribe = listenToCollection("players", organisationId, (newData) => {
                        setCurrentPlayers(newData);
                    });

                    return () => {
                        unsubscribe();
                    };
                } else {
                    console.log("The 'players' collection does not exist or is empty.");
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [organisationId]);

    const filteredPlayers = currentPlayers.filter(player =>
        (typeof player.firstName === 'string' && player.firstName.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (typeof player.lastName === 'string' && player.lastName.toLowerCase().includes(searchQuery.toLowerCase()))
    );

	const handleProfileButtonClick = (id) => {
        navigate(`/playerprofile/${id}`);
		setSearchQuery('');
    }

    const handleLogout = () => {
        logout();
    }

    return (
        <div className="bg-white h-16 px-4 w-full flex items-center border-b border-gray-200 justify-between">
            <div className="relative">
                <Search fontSize={20} className="text-gray-400 absolute top-1/2 left-3 -translate-y-1/2" />
                <input
                    type="text"
                    placeholder="Search for players..."
                    className="text-sm focus:outline-none active:outline-none border border-gray-300 w-[24rem] h-10 pl-11 pr-4 rounded-sm"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onFocus={() => setShowSearchResults(true)} // Show search results when input is focused
                    onBlur={() => setShowSearchResults(false)} // Hide search results when input loses focus
                />
                {searchQuery && ( // Only show search results when search query is not empty
                    <div className="absolute left-0 right-0 mt-1 p-4 bg-white border border-gray-300 rounded-md shadow-md z-10">
                        {/* Render search results here */}
                        {filteredPlayers.map(player => (
							<div key={player.id} className="flex flex-row py-1 hover:bg-gray-100 cursor-pointer" onClick={() => handleProfileButtonClick(player.id)}>
                                {player?.imageRef ? (
                                    <div
                                        className="h-10 w-10 rounded-full bg-sky-500 bg-cover bg-no-repeat bg-center m-2"
                                        style={{ backgroundImage: `url(${player?.imageRef})` }}
                                    >
                                    </div>
                                ) : (
                                    <div
                                        className="h-10 w-10 rounded-full bg-gray-300 text-gray-600 bg-cover bg-no-repeat bg-center text-center m-2"
                                        style={{ backgroundImage: `url(https://placehold.co/600x400/f4b346/fff?text=${(player?.firstName || '').charAt(0)}${(player?.lastName || '').charAt(0)})` }}
                                    >
                                    </div>
                                )}
                                <div className='flex flex-row mt-4'>
                                    <h1 className='text-md font-semibold'>
                                        {player.firstName + " " + player.lastName || '-'}
                                    </h1>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className="flex items-center gap-2 mr-2">
                <Menu as="div" className="relative">
                    <div>
                        <Menu.Button className="ml-2 bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-neutral-400">
                            <span className="sr-only">Open user menu</span>
                            {user?.imageRef ? (
                                <div
                                    className="h-10 w-10 rounded-full bg-sky-500 bg-cover bg-no-repeat bg-center"
                                    style={{ backgroundImage: `url(${user?.imageRef})` }}
                                >
                                </div>
                            ) : (
                                <div
                                    className="h-10 w-10 rounded-full bg-gray-300 text-gray-600 bg-cover bg-no-repeat bg-center text-center"
                                    style={{ backgroundImage: `url(https://placehold.co/600x400/2a8795/fff?text=${(user?.firstName || '').charAt(0)}${(user?.LastName || '').charAt(0)})` }}
                                >
                                </div>
                            )}
                        </Menu.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="origin-top-right z-10 absolute right-0 mt-2 w-48 rounded-sm shadow-md p-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <h1 className="px-4 py-2 border-b border-gray-200">Welcome, {user?.firstName}!</h1>
                            <Menu.Item>
                                {({ active }) => (
                                    <div
                                        onClick={() => navigate('/profile')}
                                        className={classNames(
                                            active && 'bg-indigo-50 text-gray-600',
                                            'active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200'
                                        )}
                                    >
                                        My Profile
                                    </div>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <div
                                        className={classNames(
                                            active && 'bg-indigo-50 text-gray-600',
                                            'active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200'
                                        )}
                                        onClick={handleLogout}
                                    >
                                        Sign out
                                    </div>
                                )}
                            </Menu.Item>
                        </Menu.Items>
                    </Transition>
                </Menu>
            </div>
        </div>
    );
}
