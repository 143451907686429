import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { readData, updateData, readAllData, readAllForms } from "../functions/crud";
import { UserAuth } from "../context/AuthContext";
import { ArrowUpLeftFromSquare, ChevronDown, ChevronUp, MoveLeft, Pencil, Eye, Trash2, } from "lucide-react";
import { useNavigate } from "react-router-dom"
import EditImageModal from "./modals/EditImageModal";
import DeletePlayerModal from "./modals/DeletePlayerModal";
import SuccessfullyDeletedModal from "./modals/SuccessfullyDeletedModal";
import { Tab } from '@headlessui/react'
import ReadFormModal from "./modals/ReadFormModal";
import DeleteFormModal from "./modals/DeleteFormModal";

export default function PlayerProfile() {

    const { user } = UserAuth();
    const organisationId = user?.organisationId;
    const userId = user?.uid;
    const userAccessLevel = user?.accessLevel;
    const isUserGeneralUser = userAccessLevel == "General User";
    let params = useParams();
    const navigate = useNavigate();

    const [newName, setNewName] = useState({ firstName: "", lastName: "" });
    const [newDob, setNewDob] = useState({ dob: "" });
    const [newAddress, setNewAddress] = useState({
        address: {
            street: "",
            postCode: ""
        }
    });
    const [newContactInfo, setNewContactInfo] = useState({ email: "", phone: "" });
    const [newPosition, setNewPosition] = useState({ position: "" });
    const [newTeam, setNewTeam] = useState({ team: "" });

    const [currentPlayerProfile, setCurrentPlayerProfile] = useState([]);
    const [forms, setForms] = useState([]);
    const [injuryData, setInjuryData] = useState([]);
    const [isEditImageModalOpen, setEditImageModalOpen] = useState(false)
    const [isDeletePlayerModalVisible, setDeletePlayerModalVisible] = useState(false)
    const [isDeletePlayerSuccessModalVisible, setDeletePlayerSuccessModalVisible] = useState(false);
    const [editNameVisible, setEditNameVisible] = useState(false)
    const [editDobVisible, setEditDobVisible] = useState(false)
    const [editAddressVisible, setEditAddressVisible] = useState(false);
    const [editContactInfoVisible, setEditContactInfoVisible] = useState(false);
    const [editPositionVisible, setEditPositionVisible] = useState(false);
    const [editTeamVisible, setEditTeamVisible] = useState(false);
    const [isArchivedInjuriesVisible, setArchivedInjuriesVisible] = useState(false)
    const [isReadFormModalVisible, setReadFormModalVisible] = useState(false);
    const [formToReadId, setFormToReadId] = useState("");
    const [formTodeleteId, setFormToDeleteId] = useState("");
    const [formTodeleteStatus, setFormToDeleteStatus] = useState("");
    const [isDeleteFormModalVisible, setDeleteFormModalVisible] = useState(false);
    const [isDeleteFormSuccessfulModalVisible, setDeleteFormSuccessfulModalVisible] = useState(false)

    const [isPlayerInjured, setIsPlayerInjured] = useState(false);
    const [isPlayingWithInjury, setIsPlayingWithInjury] = useState(false);

    useEffect(() => {
        const fetchPlayerProfile = async () => {
            try {
                const playerProfile = await readData("players", params.id, organisationId);
                setCurrentPlayerProfile(playerProfile);

                if (playerProfile.injuries) {
                    const injuries = await readAllData("injuries", organisationId);
                    const playerInjuries = injuries.filter(injury => playerProfile.injuries.includes(injury.id));
                    setInjuryData(playerInjuries);
                    setIsPlayerInjured(playerInjuries.some(injury => injury.status !== "Archived"));
                    setIsPlayingWithInjury(playerInjuries.some(injury => injury.status == "Playing with Injury"));
                } else {
                    setInjuryData([]);
                }
            } catch (error) {
                console.error("Error reading document:", error);
            }
        };

        const fetchForms = async () => {
            try {
                const formsData = await readAllForms(organisationId);
                const filteredForms = formsData.filter(form => form.playerId === params.id);
                const filteredFormsAgain = filteredForms.filter(form => form.injuryCaseFileId === "");
                setForms(filteredFormsAgain);
            } catch (error) {
                console.error("Error fetching forms:", error);
            }
        };

        fetchPlayerProfile();
        fetchForms();
    }, [params.id, organisationId]);

    const handleInjuryCaseFileButtonClick = (id) => {
        navigate(`/casefile/${id}`);
    }

    const toggleEditImageModal = () => {
        setEditImageModalOpen(!isEditImageModalOpen);
    }

    const toggleDeletePlayerModal = () => {
        setDeletePlayerModalVisible(!isDeletePlayerModalVisible);
    }

    const toggleDeletePlayerSuccessModal = () => {
        setDeletePlayerSuccessModalVisible(!isDeletePlayerSuccessModalVisible);
    }

    const toggleReadFormModal = () => {
        setReadFormModalVisible(!isReadFormModalVisible);
    }

    const toggleDeleteFormModal = () => {
        setDeleteFormModalVisible(!isDeleteFormModalVisible);
    }

    const toggleDeleteFormSuccessModal = () => {
        setDeleteFormSuccessfulModalVisible(!isDeleteFormSuccessfulModalVisible);
    }

    const saveEditName = async () => {
        updateData("players", params.id, newName, organisationId)
        setCurrentPlayerProfile({ ...currentPlayerProfile, firstName: newName.firstName, lastName: newName.lastName })
        setEditNameVisible(!editNameVisible);
    }

    const saveEditDob = async () => {
        updateData("players", params.id, newDob, organisationId)
        setCurrentPlayerProfile({ ...currentPlayerProfile, dob: newDob.dob })
        setEditDobVisible(!editDobVisible);
    }

    const saveEditAddress = async () => {
        updateData("players", params.id, newAddress, organisationId);
        setCurrentPlayerProfile({ ...currentPlayerProfile, address: { street: newAddress.address.street, postCode: newAddress.address.postCode } })
        setEditAddressVisible(!editAddressVisible);
    }

    const saveEditContactInfo = async () => {
        updateData("players", params.id, newContactInfo, organisationId);
        setCurrentPlayerProfile({ ...currentPlayerProfile, email: newContactInfo.email, phone: newContactInfo.phone })
        setEditContactInfoVisible(!editContactInfoVisible)
    }

    const saveEditPosition = async () => {
        updateData("players", params.id, newPosition, organisationId);
        setCurrentPlayerProfile({ ...currentPlayerProfile, position: newPosition.position })
        setEditPositionVisible(!editPositionVisible)
    }

    const saveEditTeam = async () => {
        updateData("players", params.id, newTeam, organisationId);
        setCurrentPlayerProfile({ ...currentPlayerProfile, team: newTeam.team })
        setEditTeamVisible(!editTeamVisible)
    }

    const handleImageUploadSuccess = (downloadURL) => {
        setCurrentPlayerProfile(prevProfile => ({
            ...prevProfile,
            imageRef: downloadURL
        }));
    }

    const calculateAge = () => {
        const today = new Date();
        const dob = new Date(currentPlayerProfile?.dob);

        const diffMs = today - dob;
        const age = Math.floor(diffMs / (1000 * 60 * 60 * 24 * 365.25));

        return age;
    }

    const getStatusBackgroundColor = (status) => {
        switch (status) {
            case 'In Triage':
                return 'bg-red-500';
            case 'In Rehab':
                return 'bg-yellow-400';
            case 'Returned to Training':
                return 'bg-purple-600';
            case 'Returned to Play':
                return 'bg-green-600';
            case 'Playing with Injury':
                return 'bg-orange-500';
            case 'Archived':
                return 'bg-gray-600';
            default:
                return 'bg-black';
        }
    };

    return (
        <div className="mx-10 my-10">

            {isEditImageModalOpen && <EditImageModal onClose={toggleEditImageModal} playerId={params.id} onImageUploadSuccess={handleImageUploadSuccess} />}
            {isDeletePlayerModalVisible && <DeletePlayerModal onClose={toggleDeletePlayerModal} player={currentPlayerProfile} onDeletePlayer={toggleDeletePlayerSuccessModal} />}
            {isDeletePlayerSuccessModalVisible && <SuccessfullyDeletedModal onClose={toggleDeletePlayerSuccessModal} deletedElementName="Player" />}
            {isReadFormModalVisible && <ReadFormModal onClose={toggleReadFormModal} formId={formToReadId}/>}
            {isDeleteFormModalVisible && <DeleteFormModal onClose={toggleDeleteFormModal} formId={formTodeleteId} formStatus={formTodeleteStatus} deletionSuccessful={toggleDeleteFormSuccessModal} />}
            {isDeleteFormSuccessfulModalVisible && <SuccessfullyDeletedModal onClose={toggleDeleteFormSuccessModal} deletedElementName="Form" />}

            <MoveLeft
                className="items-center mr-4 text-gray-300 hover:text-gray-700 cursor-pointer"
                size={50}
                onClick={() => navigate("/players")}
            />
            <div className="bg-transparent px-4 pt-3 mt-4 pb-4 rounded-sm flex flex-row">

                <div name="left-segment" className="flex flex-col w-1/4">

                    {currentPlayerProfile?.imageRef ? (
                        <div
                            className="h-48 w-48 rounded-sm bg-sky-500 bg-cover bg-no-repeat bg-center"
                            style={{ backgroundImage: `url(${currentPlayerProfile.imageRef})` }}
                        >
                        </div>
                    ) : (
                        <div
                            className="h-48 w-48 rounded-sm bg-gray-300 text-gray-600 bg-cover bg-no-repeat bg-center text-center"
                            style={{ backgroundImage: `url(https://placehold.co/600x400/f4b346/fff?text=${(currentPlayerProfile?.firstName || '').charAt(0)}${(currentPlayerProfile?.lastName || '').charAt(0)})` }}
                        >
                        </div>
                    )}
                    <p className="text-sm cursor-pointer text-blue-400" onClick={toggleEditImageModal}>Edit Image</p>

                    <h1 className="text-sm font-semibold text-gray-400 mt-2">Status</h1>
                    <div className={`${isPlayingWithInjury ? 'bg-orange-500' : isPlayerInjured ? 'bg-red-500' : 'bg-green-500'} text-white font-bold py-2 px-4 rounded-sm max-w-max`}>
                        {isPlayingWithInjury ? 'Playing with Injury' : isPlayerInjured ? 'Injured' : 'Healthy'}
                    </div>


                    <div className="flex flex-col mb-8 mt-8">
                        <div className="flex flex-row">
                            <h1 className="text-sm font-semibold text-gray-400 mt-2">Team</h1>
                            <Pencil size={15} className="text-gray-500 ml-1 mt-2 cursor-pointer" onClick={() => { setEditTeamVisible(true) }} />
                        </div>
                        {!editTeamVisible && <div className="flex flex-row">
                            {currentPlayerProfile?.team ? (<h1>{currentPlayerProfile?.team}</h1>) : (<h1 className="text-center">-</h1>)}
                        </div>}
                        {editTeamVisible && <div>
                            <input
                                type="text"
                                placeholder="Team"
                                className="text-sm w-4/5 rounded-md py-1.5 mb-2"
                                onChange={(e) => {
                                    setNewTeam({ team: e.target.value })
                                }}
                            />
                            <div>
                                <button
                                    className='mr-auto bg-darkTealPreset hover:bg-tealPreset text-white font-bold px-2 py-1 rounded'
                                    onClick={saveEditTeam}
                                >
                                    Save
                                </button>
                                <button
                                    className='mr-auto ml-2 bg-gray-400 hover:bg-gray-700 text-white font-bold px-2 py-1 rounded'
                                    onClick={() => { setEditTeamVisible(false) }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>}
                    </div>

                    <div className="flex flex-col mb-8">
                        <div className="flex flex-row">
                            <h1 className="text-sm font-semibold text-gray-400 mt-2">Position</h1>
                            <Pencil size={15} className="text-gray-500 ml-1 mt-2 cursor-pointer" onClick={() => { setEditPositionVisible(true) }} />
                        </div>
                        {!editPositionVisible && <div className="flex flex-row">
                            {currentPlayerProfile?.position ? (<h1>{currentPlayerProfile?.position}</h1>) : (<h1 className="text-center">-</h1>)}
                        </div>}
                        {editPositionVisible && <div>
                            <input
                                type="text"
                                placeholder="Position"
                                className="text-sm w-4/5 rounded-md py-1.5 mb-2"
                                onChange={(e) => {
                                    setNewPosition({ position: e.target.value })
                                }}
                            />
                            <div>
                                <button
                                    className='mr-auto bg-darkTealPreset hover:bg-tealPreset text-white font-bold px-2 py-1 rounded'
                                    onClick={saveEditPosition}
                                >
                                    Save
                                </button>
                                <button
                                    className='mr-auto ml-2 bg-gray-400 hover:bg-gray-700 text-white font-bold px-2 py-1 rounded'
                                    onClick={() => { setEditPositionVisible(false) }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>}
                    </div>
                </div>

                <div name="right-segment" className="flex flex-col w-3/4">

                    <div className="bg-white px-4 pt-3 pb-4 rounded-sm border border-gray-200 flex-1 shadow-md">
                        <div className="flex flex-row">
                            <h1 className="text-sm font-semibold text-gray-400">Full Name</h1>
                            <Pencil size={15} className="text-gray-500 ml-1 cursor-pointer" onClick={() => { setEditNameVisible(true) }} />
                        </div>
                        {!editNameVisible && <h1 className="text-xl font-bold pb-12">{currentPlayerProfile?.firstName + " " + currentPlayerProfile?.lastName}</h1>}
                        {editNameVisible && <div>
                            <div className="flex flex-row">
                                <input
                                    type="text"
                                    placeholder="First Name"
                                    className="text-sm w-1/4 rounded-md py-1.5 mb-2 mr-4"
                                    value={newName.firstName}
                                    onChange={(e) => {
                                        setNewName({ ...newName, firstName: e.target.value })
                                    }}
                                />
                                <input
                                    type="text"
                                    placeholder="Last Name"
                                    className="text-sm w-1/4 rounded-md py-1.5 mb-2"
                                    value={newName.lastName}
                                    onChange={(e) => {
                                        setNewName({ ...newName, lastName: e.target.value })
                                    }}
                                />
                            </div>
                            <div>
                                <button
                                    className='mr-auto bg-darkTealPreset hover:bg-tealPreset text-white font-bold px-2 py-1 rounded'
                                    onClick={saveEditName}
                                >
                                    Save
                                </button>
                                <button
                                    className='mr-auto ml-2 bg-gray-400 hover:bg-gray-700 text-white font-bold px-2 py-1 rounded'
                                    onClick={() => { setEditNameVisible(false) }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>}

                        <div className="flex flex-row justify-between">
                            <div>
                                <div className="flex flex-row">
                                    <h1 className="text-sm font-semibold text-gray-400">Date of Birth</h1>
                                    <Pencil size={15} className="text-gray-500 ml-1 cursor-pointer" onClick={() => { setEditDobVisible(true) }} />
                                </div>

                                {!editDobVisible && <div>
                                    {currentPlayerProfile?.dob ? (<h1>{currentPlayerProfile?.dob}</h1>) : (<h1 className="text-center">-</h1>)}
                                </div>}

                                {editDobVisible && <div>
                                    <input
                                        type="date"
                                        placeholder="Date of Birth"
                                        className="text-sm rounded-md mb-2 mr-4"
                                        onChange={(e) => {
                                            setNewDob({ ...newDob, dob: e.target.value })
                                        }}
                                    />
                                    <div>
                                        <button
                                            className='mr-auto bg-darkTealPreset hover:bg-tealPreset text-white font-bold px-2 py-1 rounded'
                                            onClick={saveEditDob}
                                        >
                                            Save
                                        </button>
                                        <button
                                            className='mr-auto ml-2 bg-gray-400 hover:bg-gray-700 text-white font-bold px-2 py-1 rounded'
                                            onClick={() => { setEditDobVisible(false) }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>}

                                <h1>{calculateAge()} years old</h1>
                            </div>

                            <div>
                                <div className="flex flex-row">
                                    <h1 className="text-sm font-semibold text-gray-400">Home Address</h1>
                                    <Pencil size={15} className="text-gray-500 ml-1 cursor-pointer" onClick={() => setEditAddressVisible(true)} />
                                </div>

                                {!editAddressVisible && <div>
                                    {currentPlayerProfile?.address ? (<div><h1>{currentPlayerProfile?.address.street}</h1><h1>{currentPlayerProfile?.address.postCode}</h1></div>) : (<h1 className="text-center">-</h1>)}
                                </div>}

                                {editAddressVisible && (
                                    <div>
                                        <input
                                            type="text"
                                            placeholder="Street"
                                            className="text-sm rounded-md mb-2 mr-4"
                                            onChange={(e) => setNewAddress(prevAddress => ({ ...prevAddress, address: { ...prevAddress.address, street: e.target.value } }))}
                                        />
                                        <input
                                            type="text"
                                            placeholder="Postcode"
                                            className="text-sm rounded-md mb-2 mr-4"
                                            onChange={(e) => setNewAddress(prevAddress => ({ ...prevAddress, address: { ...prevAddress.address, postCode: e.target.value } }))}
                                        />
                                        <div>
                                            <button
                                                className='mr-auto bg-darkTealPreset hover:bg-tealPreset text-white font-bold px-2 py-1 rounded'
                                                onClick={saveEditAddress}
                                            >
                                                Save
                                            </button>
                                            <button
                                                className='mr-auto ml-2 bg-gray-400 hover:bg-gray-700 text-white font-bold px-2 py-1 rounded'
                                                onClick={() => setEditAddressVisible(false)}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div>
                                <div className="flex flex-row">
                                    <h1 className="text-sm font-semibold text-gray-400">Contact Information</h1>
                                    <Pencil size={15} className="text-gray-500 ml-1 cursor-pointer" onClick={() => setEditContactInfoVisible(true)} />
                                </div>

                                {!editContactInfoVisible && <div>
                                    {currentPlayerProfile?.email ? (<h1>{currentPlayerProfile?.email}</h1>) : (<h1>Email: -</h1>)}
                                    {currentPlayerProfile?.phone ? (<h1>{currentPlayerProfile?.phone}</h1>) : (<h1>Phone: -</h1>)}
                                </div>}

                                {editContactInfoVisible && (
                                    <div>
                                        <input
                                            type="text"
                                            placeholder="Email"
                                            className="text-sm w-3/4 rounded-md py-1.5 mb-2 mr-4"
                                            onChange={(e) => setNewContactInfo({ ...newContactInfo, email: e.target.value })}
                                        />
                                        <input
                                            type="text"
                                            placeholder="Phone"
                                            className="text-sm w-3/4 rounded-md py-1.5 mb-2 mr-4"
                                            onChange={(e) => setNewContactInfo({ ...newContactInfo, phone: e.target.value })}
                                        />
                                        <div>
                                            <button
                                                className='mr-auto bg-darkTealPreset hover:bg-tealPreset text-white font-bold px-2 py-1 rounded'
                                                onClick={saveEditContactInfo}
                                            >
                                                Save
                                            </button>
                                            <button
                                                className='mr-auto ml-2 bg-gray-400 hover:bg-gray-700 text-white font-bold px-2 py-1 rounded'
                                                onClick={() => setEditContactInfoVisible(false)}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <Tab.Group>
                        <Tab.List className="flex p-1 space-x-1 bg-gray-900/20 rounded-xl mt-4">
                            <Tab
                                className={({ selected }) =>
                                    selected
                                        ? 'w-full py-2.5 text-sm leading-5 font-medium text-white bg-gray-600 rounded-lg'
                                        : 'w-full py-2.5 text-sm leading-5 font-medium text-gray-400 rounded-lg hover:bg-white/[0.12] hover:text-white'
                                }
                            >
                                Injuries
                            </Tab>
                            <Tab
                                className={({ selected }) =>
                                    selected
                                        ? 'w-full py-2.5 text-sm leading-5 font-medium text-white bg-gray-600 rounded-lg'
                                        : 'w-full py-2.5 text-sm leading-5 font-medium text-gray-400 rounded-lg hover:bg-white/[0.12] hover:text-white'
                                }
                            >
                                Forms
                            </Tab>
                        </Tab.List>
                        <Tab.Panels className="mt-2">
                            <Tab.Panel className="rounded-xl p-3 ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400">
                                <div className="bg-white px-4 pt-3 mt-4 pb-4 rounded-sm border border-gray-200 flex-1 shadow-md">
                                    <h2 className="text-xl font-bold pb-4">Injuries</h2>
                                    <h2 className="text-l font-semibold">Active</h2>
                                    <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-200">
                                            <tr>
                                                <th scope="col" className="px-6 py-3">
                                                    Injury
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Status
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Expected RTP
                                                </th>
                                                {!isUserGeneralUser && <th scope="col" className="px-6 py-3">
                                                    Action
                                                </th>}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {injuryData.length === 0 ? (
                                                <tr className="bg-white border-b hover:bg-gray-50">
                                                    <td colSpan="6" className="text-center">No injuries found.</td>
                                                </tr>
                                            ) : (
                                                injuryData
                                                    .filter(injury => injury.status !== "Archived")
                                                    .map((injury) => (
                                                        <tr key={injury.id} className='bg-white border-b hover:bg-gray-50"'>
                                                            <td className='px-6 py-4'>{injury.injury || '-'}</td>
                                                            <td className='px-6 py-4'>
                                                                <div className={`${getStatusBackgroundColor(injury.status)} text-white rounded-sm px-4 py-2 max-w-max inline-block`}>
                                                                    {injury.status}
                                                                </div>
                                                            </td>
                                                            <td className='px-6 py-4'>{injury.expectedRtp || '-'}</td>
                                                            {!isUserGeneralUser && <td className='px-6 py-4'>
                                                                <ArrowUpLeftFromSquare size={22} className='cursor-pointer' onClick={() => handleInjuryCaseFileButtonClick(injury.id)} />
                                                            </td>}
                                                        </tr>
                                                    ))
                                            )}
                                        </tbody>
                                    </table>
                                    <div className="flex flex-row">
                                        <h2 className="text-l font-semibold pt-3 mr-1">Archived</h2>
                                        {!isArchivedInjuriesVisible && <ChevronDown onClick={() => setArchivedInjuriesVisible(!isArchivedInjuriesVisible)} className="mt-3 cursor-pointer text-gray-400 hover:text-gray-700" />}
                                        {isArchivedInjuriesVisible && <ChevronUp onClick={() => setArchivedInjuriesVisible(!isArchivedInjuriesVisible)} className="mt-3 cursor-pointer text-gray-400 hover:text-gray-700" />}
                                    </div>
                                    {isArchivedInjuriesVisible && <div>
                                        <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-200">
                                                <tr>
                                                    <th scope="col" className="px-6 py-3">
                                                        Injury
                                                    </th>
                                                    <th scope="col" className="px-6 py-3">
                                                        Status
                                                    </th>
                                                    <th scope="col" className="px-6 py-3">
                                                        RTP
                                                    </th>
                                                    {!isUserGeneralUser && <th scope="col" className="px-6 py-3">
                                                        Action
                                                    </th>}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {injuryData.length === 0 ? (
                                                    <tr className="bg-white border-b hover:bg-gray-50">
                                                        <td colSpan="6" className="text-center">No injuries found.</td>
                                                    </tr>
                                                ) : (
                                                    injuryData
                                                        .filter(injury => injury.status === "Archived")
                                                        .map((injury) => (
                                                            <tr key={injury.id} className='bg-white border-b hover:bg-gray-50"'>
                                                                <td className='px-6 py-4'>{injury.injury || '-'}</td>
                                                                <td className='px-6 py-4'>
                                                                    <div className={`${getStatusBackgroundColor(injury.status)} text-white rounded-sm px-4 py-2 max-w-max inline-block`}>
                                                                        {injury.status}
                                                                    </div>
                                                                </td>
                                                                <td className='px-6 py-4'>{injury.expectedRtp || '-'}</td>
                                                                {!isUserGeneralUser && <td className='px-6 py-4'>
                                                                    <ArrowUpLeftFromSquare size={22} className='cursor-pointer' onClick={() => handleInjuryCaseFileButtonClick(injury.id)} />
                                                                </td>}
                                                            </tr>
                                                        ))
                                                )}
                                            </tbody>
                                        </table>
                                    </div>}
                                </div>
                            </Tab.Panel>
                            <Tab.Panel className="rounded-xl p-3 ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400">
                                <div className="bg-transparent rounded-sm flex-1 pt-4">
                                    <div className="bg-white px-4 pt-3 mt-4 pb-4 rounded-sm border border-gray-200 flex-1 shadow-md">
                                        <div className="flex flex-row">
                                            <h1 className="text-xl font-bold pb-4">Forms</h1>
                                        </div>
                                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 mt-4">
                                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-200">
                                                <tr>
                                                    <th scope="col" className="px-6 py-3">
                                                        View
                                                    </th>
                                                    <th scope="col" className="px-6 py-3">
                                                        Title
                                                    </th>
                                                    <th scope="col" className="px-6 py-3">
                                                        Status
                                                    </th>
                                                    <th scope="col" className="px-6 py-3">
                                                        Delete
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {forms.length === 0 ? (
                                                    <tr className="bg-white border-b hover:bg-gray-50">
                                                        <td colSpan="6" className="text-center">No Forms Completed.</td>
                                                    </tr>
                                                ) : (
                                                    forms.map((form, index) => (
                                                        <tr key={index} className='bg-white border-b hover:bg-gray-50"'>
                                                            <td className='px-6 py-4'>
                                                                <Eye
                                                                    className='cursor-pointer mr-2 text-gray-400 hover:text-black' 
                                                                    onClick={() => {
                                                                        toggleReadFormModal();
                                                                        setFormToReadId(form.id);
                                                                    }}
                                                                    />
                                                            </td>
                                                            <td className='px-6 py-4'>
                                                                <h1 className="text-gray-700">{form?.formTitle}</h1>
                                                            </td>
                                                            <td className='px-6 py-4'>
                                                                <h1 className="text-gray-700">{form?.status}</h1>
                                                            </td>
                                                            <td className='px-6 py-4'>
                                                                <Trash2
                                                                    className="cursor-pointer ml-2 text-gray-400 hover:text-black"
                                                                    onClick={() => {
                                                                        toggleDeleteFormModal();
                                                                        setFormToDeleteId(form.id)
                                                                        setFormToDeleteStatus(form.status)
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Tab.Panel>
                        </Tab.Panels>
                    </Tab.Group>


                </div>
            </div>

            <div className="bg-transparent px-4 pt-3 mt-4 pb-4 rounded-sm flex flex-row justify-between">
                <div></div> {/* This empty div will push the button to the right */}
                <button
                    className='bg-red-400 hover:bg-red-600 text-white font-bold px-4 py-2 rounded'
                    onClick={toggleDeletePlayerModal}
                >
                    Delete Player
                </button>
            </div>
        </div>
    )
}