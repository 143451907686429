import React, { useState } from 'react';
import manageSubscription from '../stripe/manageSubscription';
import { UserAuth } from '../context/AuthContext';
import { Disclosure, Tab } from '@headlessui/react';
import { ChevronDown, ChevronUp } from 'lucide-react';

function Organisation() {
  const { user } = UserAuth();
  const [openPanels, setOpenPanels] = useState({});

  const handleManageSubscriptionButtonClicked = async () => {
    await manageSubscription(user);
  };

  const togglePanel = (panel) => {
    setOpenPanels((prevOpenPanels) => ({
      ...prevOpenPanels,
      [panel]: !prevOpenPanels[panel],
    }));
  };

  return (
    <div className="w-full">
      <div className="mx-10 my-10">
        <div className="flex flex-row w-full place-content-between">
          <h1 className="text-2xl font-bold pb-4">Organisation</h1>
        </div>

        <Tab.Group>
          <Tab.List className="flex p-1 space-x-1 bg-gray-900/20 rounded-xl">
            <Tab
              className={({ selected }) =>
                selected
                  ? 'w-full py-2.5 text-sm leading-5 font-medium text-white bg-gray-600 rounded-lg'
                  : 'w-full py-2.5 text-sm leading-5 font-medium text-gray-400 rounded-lg hover:bg-white/[0.12] hover:text-white'
              }
            >
              Subscription
            </Tab>
            <Tab
              className={({ selected }) =>
                selected
                  ? 'w-full py-2.5 text-sm leading-5 font-medium text-white bg-gray-600 rounded-lg'
                  : 'w-full py-2.5 text-sm leading-5 font-medium text-gray-400 rounded-lg hover:bg-white/[0.12] hover:text-white'
              }
            >
              FAQs
            </Tab>
          </Tab.List>
          <Tab.Panels className="mt-2">
            <Tab.Panel className="p-3 rounded-xl">
              <button
                className="bg-darkTealPreset hover:bg-tealPreset text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={handleManageSubscriptionButtonClicked}
              >
                Manage subscription
              </button>
            </Tab.Panel>
            <Tab.Panel className="bg-gray-700 p-3 rounded-xl">
              <div className="mx-auto w-full max-w-lg divide-y divide-white/5 rounded-xl">
                <Disclosure as="div" className="p-6">
                  {({ open }) => (
                    <>
                      <Disclosure.Button
                        className="group flex w-full items-center justify-between"
                        onClick={() => togglePanel('ownershipTransfer')}
                      >
                        <span className="text-sm/6 font-medium text-white group-hover:text-white/80">
                          How do I transfer organisation ownership to another staff member?
                        </span>
                        {openPanels['ownershipTransfer'] ? (
                          <ChevronUp className="text-white" />
                        ) : (
                          <ChevronDown className="text-white" />
                        )}
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 text-sm/5 text-gray-300">
                        <h1 className="font-semibold">
                          You are the current owner of the organisation. If you wish to transfer the ownership to another staff member, please follow the following steps:
                        </h1>
                        <h1 className="ml-5 font-normal">
                          1. Ensure the staff member you wish to be the new owner is already a part of the organisation and has admin access.
                        </h1>
                        <h1 className="ml-5 font-normal">
                          2. Click on the manage subscription tab, then click on the manage subscription button. This will take you to a third-party screen managed by our payment partnership Stripe.
                        </h1>
                        <h1 className="ml-5 font-normal">
                          3. Cancel the current plan that is active for your organisation.
                        </h1>
                        <h1 className="ml-5 font-normal">
                          4. Your plan will now be cancelled on the renewal date. After this date has passed, the new owner will be able to renew the subscription for the organisation, and will now be the official owner of the organisation.
                        </h1>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <Disclosure as="div" className="p-6">
                  {({ open }) => (
                    <>
                      <Disclosure.Button
                        className="group flex w-full items-center justify-between"
                        onClick={() => togglePanel('technicalSupport')}
                      >
                        <span className="text-sm/6 font-medium text-white group-hover:text-white/80">
                          How do I delete the entire organisation account?
                        </span>
                        {openPanels['technicalSupport'] ? (
                          <ChevronUp className="text-white" />
                        ) : (
                          <ChevronDown className="text-white" />
                        )}
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 text-sm/5 text-gray-300">
                        If you would like to delete the organisation account in its entirety, please contact us using the contact form on the homepage of our website.
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
}

export default Organisation;
