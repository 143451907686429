import React, {useState, useEffect} from 'react'
import { deleteFileFromFilesArray, deleteSpecificField, deleteUser, readUser } from '../../functions/crud';
import { UserAuth } from "../../context/AuthContext";
import { X } from 'lucide-react';

function DeleteFileModal({onClose, file, injuryId, deletionSuccessfull}) {

    const { user } = UserAuth();
    const organisationId = user?.organisationId;

    const handleDeleteButtonClicked = () => {
        const updatedFiles = deleteFileFromFilesArray("injuries", injuryId, organisationId, file.name);
        onClose();
        deletionSuccessfull();
    }

  return (
    <div className={`fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75`}>
    <div className="bg-white p-8 rounded-md shadow-md max-w-lg w-full overflow-hidden">
        <div className='flex flex-row items-center mb-4'>
            <h2 className="text-2xl font-bold flex-1">File Deletion for {file.name}</h2>
            <X className='text-gray-400 hover:text-gray-600 cursor-pointer' onClick={onClose} />
        </div>
        <div className="max-h-96 overflow-y-auto">
            <h1 className='mb-5'>If you wish to continue, the file with the name {file.name} will be deleted.</h1>
            <h1 className='text-lg font-bold'>Are you sure you want to delete {file.name}?</h1>
        
            <div>
                <button
                    className='mr-auto bg-red-400 hover:bg-red-600 text-white font-bold px-4 py-2 rounded'
                    onClick={handleDeleteButtonClicked}
                >
                    Delete
                </button>
                <button
                    className='mr-auto ml-2 bg-gray-400 hover:bg-gray-700 text-white font-bold px-4 py-2 rounded'
                    onClick={() => onClose()}
                >
                    Cancel
                </button>
            </div>
        </div>
    </div>
</div>
  )
}

export default DeleteFileModal