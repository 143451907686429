import React, { useState, useEffect } from "react";
import { UserAuth } from "../../context/AuthContext";
import { createData } from "../../functions/crud";
import { X } from "lucide-react";
import { getTime, getDate } from "../utils";

function CreateNewPlayerModal({ onClose }) {
    const initialPlayerDetails = {
        firstName: "",
        lastName: "",
        position: "",
        dob: "",
        team: "",
    };

    const { user } = UserAuth();
    const [newPlayer, setNewPlayer] = useState(initialPlayerDetails);
    const organisationId = user?.organisationId;

    const [globalUpdateData, setGlobalUpdateData] = useState({
        title: "New Player File Created",
        subTitle: ``,
        imageRef: `${user?.imageRef ? user?.imageRef : "https://placehold.co/600x400/2a8795/fff?text="}${(user?.firstName || '').charAt(0)}${(user?.LastName || '').charAt(0)}`,
        creatorFirstName: `${user?.firstName}`,
        creatorLastName: `${user?.LastName}`,
        creatorRole: `${user?.role}`,
        timeCreated: getTime(),
        dateCreated: getDate()
    });

    useEffect(() => {
        setGlobalUpdateData((prevGlobalUpdateData) => ({
            ...prevGlobalUpdateData,
            subTitle: `for ${newPlayer.firstName} ${newPlayer.lastName}`
        }));
    }, [newPlayer]);

    const handleCreatePlayer = (e) => {
        e.preventDefault();
        try {
            createData("players", newPlayer, organisationId);
            createData("globalUpdates", globalUpdateData, organisationId);
            onClose();
        } catch (error) {
            console.error("Error creating player: ", error);
        }
    };

    const handleOnChangeInput = (e) => {
        setNewPlayer({ ...newPlayer, [e.target.name]: e.target.value });
    };

    return (
        <div className={`fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50`}>
            <div className="bg-white p-8 rounded-md shadow-md max-w-3xl w-full overflow-hidden">
                <div className='flex flex-row items-center mb-4'>
                    <h2 className="text-2xl font-bold flex-1">Create New player</h2>
                    <X className='text-gray-400 hover:text-gray-600 cursor-pointer' onClick={onClose} />
                </div>
                <div className="max-h-96 overflow-y-auto">
                    <form className="grid grid-cols-2 gap-4 max-w-2xl mx-auto" onSubmit={handleCreatePlayer}>
                        <div className="flex flex-col">
                            <label htmlFor="firstName" className="mr-auto text-gray-700">First Name</label>
                            <input
                                type="text"
                                placeholder="John..."
                                name="firstName"
                                onChange={handleOnChangeInput}
                            />
                        </div>

                        <div className="flex flex-col">
                            <label htmlFor="lastName" className="mr-auto text-gray-700">Last Name</label>
                            <input
                                type="text"
                                placeholder="Smith..."
                                name="lastName"
                                onChange={handleOnChangeInput}
                            />

                        </div>

                        <div className="flex flex-col">
                            <label htmlFor="position" className="mr-auto text-gray-700">Position</label>
                            <input
                                type="text"
                                name="position"
                                placeholder="ST, GK, Scrum Half..."
                                onChange={handleOnChangeInput}
                            />
                        </div>

                        <div className="flex flex-col">
                            <label htmlFor="dob" className="mr-auto text-gray-700">Date of Birth</label>
                            <input
                                type="date"
                                placeholder="Date of Birth"
                                name="dob"
                                onChange={handleOnChangeInput}
                            />
                        </div>

                        <div className="flex flex-col">
                            <label htmlFor="team" className="mr-auto text-gray-700">Team</label>
                            <input
                                type="text"
                                placeholder="1st team, U21..."
                                name="team"
                                onChange={handleOnChangeInput}
                            />
                        </div>

                        <button className="bg-darkTealPreset hover:bg-tealPreset text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                            Create
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default CreateNewPlayerModal;
