import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { format } from 'date-fns'; // Import format function from date-fns
import { readCompletedForm, readData } from '../../functions/crud';
import { UserAuth } from '../../context/AuthContext';

function ReadFormModal({ onClose, formId }) {
    const [formData, setFormData] = useState(null);
    const [formNotFound, setFormNotFound] = useState(false); // State to track if form is not found
    const { user } = UserAuth();

    // Function to format date
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return format(date, 'MMMM dd, yyyy HH:mm:ss');
    };

    useEffect(() => {
        const fetchFormData = async () => {
            try {
                const formData = await readCompletedForm(formId);
                if (formData) {
                    setFormData(formData);
                } else {
                    setFormNotFound(true); // Set formNotFound state to true if form is not found
                }
            } catch (error) {
                console.error("Error fetching form data:", error);
            }
        };

        fetchFormData();
    }, [formId]);

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
            {formData && (
                <div className="bg-white p-8 rounded-md shadow-md max-w-3xl w-full overflow-hidden">
                    <div className="flex flex-row items-center mb-4">
                        <h2 className="text-2xl font-bold flex-1">
                            {formData.formTitle}
                        </h2>
                        <X className="text-gray-400 hover:text-gray-600 cursor-pointer" onClick={onClose} />
                    </div>
                    <div className="mb-4">
                        <p className="font-semibold">Completed at</p>
                        <p className="text-lg font-normal">{formatDate(formData.dateCompleted)}</p>
                    </div>
                    <div className="max-h-96 overflow-y-auto">
                        {formData.responses.map((item, index) => (
                            <div key={index} className="mb-4">
                                <h1 className="text-xl font-semibold">{item.question}</h1>
                                {Array.isArray(item.response) ? (
                                    <ul>
                                        {item.response.map((response, idx) => (
                                            <li key={idx}>{response}</li>
                                        ))}
                                    </ul>
                                ) : (
                                    <p>{item.response}</p>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {formNotFound && (
                <div className="bg-white p-8 rounded-md shadow-md max-w-3xl w-full">
                    <div className="flex flex-row items-center mb-4">
                        <h2 className="text-2xl font-bold flex-1">
                            Form Response:
                        </h2>
                        <X className="text-gray-400 hover:text-gray-600 cursor-pointer" onClick={onClose} />
                    </div>
                    <p className="text-lg font-normal">This form has not yet been completed by the player.</p>
                </div>
            )}
        </div>
    );
}

export default ReadFormModal;
