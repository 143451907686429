import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { readData, createData, readFormToComplete, createCompletedForm, deleteFormToComplete } from "../functions/crud";

function FormToComplete() {
    const { id, organisationId, email } = useParams();
    const navigate = useNavigate();
    const [formTemplate, setFormTemplate] = useState(null);
    const [formResponses, setFormResponses] = useState([]);
    
    useEffect(() => {
        const fetchFormTemplate = async () => {
            try {
                const formTemplateData = await readFormToComplete(id);
                setFormTemplate(formTemplateData);
                setFormResponses(formTemplateData.questions.map((question) => ({
                    question: question.text,
                    response: question.type === "checkbox" ? [] : ""
                })));
                console.log(formTemplateData)
            } catch (error) {
                console.error("Error fetching form template:", error);
            }
        };

        fetchFormTemplate();
    }, [id, organisationId]);

    const handleInputChange = (questionText, value) => {
        setFormResponses(prevResponses => 
            prevResponses.map(response => 
                response.question === questionText 
                ? { ...response, response: value } 
                : response
            )
        );
    };

    const handleCheckboxChange = (questionText, option) => {
        setFormResponses(prevResponses => 
            prevResponses.map(response => {
                if (response.question === questionText) {
                    const currentValues = response.response || [];
                    const newValues = currentValues.includes(option)
                        ? currentValues.filter(val => val !== option)
                        : [...currentValues, option];
                    return { ...response, response: newValues };
                }
                return response;
            })
        );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = {
            id,
            email,
            formTitle: formTemplate.formTitle,
            responses: formResponses,
            playerId: formTemplate.playerId,
            injuryCaseFileId: formTemplate.injuryCaseFileId,
            status: "Completed",
            organisationId,
            dateCompleted: new Date().toISOString()
        };

        console.log(formData);

        try {
            await createCompletedForm(formData);
            alert("Form Completed Successfully");
            await deleteFormToComplete(id);
            navigate("/dashboard");
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };

    if (!formTemplate) {
        return <div className="text-center text-2xl mt-10"><h1>Loading form...</h1></div>;
    }

    return (
        <div className="flex flex-col items-center mx-10 my-10">
            <h1 className="text-3xl font-bold mb-12">{formTemplate.title}</h1>
            <form className="grid grid-cols-1 gap-4 max-w-2xl w-full" onSubmit={handleSubmit}>
                {formTemplate.questions.map((question) => (
                    <div key={question.text} className="flex flex-col">
                        <label className="font-bold mb-2">{question.text}</label>
                        {question.type === "text" && (
                            <input
                                type="text"
                                value={formResponses.find(response => response.question === question.text)?.response || ""}
                                onChange={(e) => handleInputChange(question.text, e.target.value)}
                                className="p-2 border border-gray-300 rounded"
                            />
                        )}
                        {question.type === "paragraph" && (
                            <textarea
                                value={formResponses.find(response => response.question === question.text)?.response || ""}
                                onChange={(e) => handleInputChange(question.text, e.target.value)}
                                className="p-2 border border-gray-300 rounded"
                            />
                        )}
                        {question.type === "multiple-choice" && (
                            question.options.map((option) => (
                                <label key={option}>
                                    <input
                                        type="radio"
                                        name={question.text}
                                        value={option}
                                        checked={formResponses.find(response => response.question === question.text)?.response === option}
                                        onChange={(e) => handleInputChange(question.text, option)}
                                        className="mr-2"
                                    />
                                    {option}
                                </label>
                            ))
                        )}
                        {question.type === "checkbox" && (
                            question.options.map((option) => (
                                <label key={option}>
                                    <input
                                        type="checkbox"
                                        name={question.text}
                                        value={option}
                                        checked={formResponses.find(response => response.question === question.text)?.response.includes(option) || false}
                                        onChange={() => handleCheckboxChange(question.text, option)}
                                        className="mr-2"
                                    />
                                    {option}
                                </label>
                            ))
                        )}
                    </div>
                ))}
                <button className="bg-darkTealPreset hover:bg-tealPreset text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                    Submit
                </button>
            </form>
        </div>
    );
}

export default FormToComplete;
