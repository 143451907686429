import React, {useState, useEffect} from 'react'
import { UserAuth } from "../../context/AuthContext";
import { deleteData, readData, createData } from "../../functions/crud";
import { getTime, getDate } from '../utils';

function CheckBeforeDeleteModal({ onClose, toBeDeletedElementName, soapNoteId, showDeleteSuccessModal, caseFileId }) {

    const { user } = UserAuth();
    const organisationId = user?.organisationId;
    const [currentCaseFile, setCurrentCaseFile] = useState([]);
    const [globalUpdateData, setGlobalUpdateData] = useState(null)

    useEffect(() => {
        const fetchCaseFile = async () => {
            try {
                const caseFile = await readData("injuries", caseFileId, organisationId);
                setCurrentCaseFile(caseFile);
                setGlobalUpdateData({
                    title: "Soap Note Deleted",
                    subTitle: `for ${caseFile.firstName + " " + caseFile.lastName}`,
                    imageRef: `${user?.imageRef ? user?.imageRef : "https://placehold.co/600x400/2a8795/fff?text="}${(user?.firstName || '').charAt(0)}${(user?.LastName || '').charAt(0)}`,
                    creatorFirstName: `${user?.firstName}`,
                    creatorLastName: `${user?.LastName}`,
                    creatorRole: `${user?.role}`,
                    timeCreated: getTime(),
                    dateCreated: getDate()
                })
            } catch (error) {
                console.error("Error reading document:", error);
            }  
        } 
        fetchCaseFile();
    }, [caseFileId, organisationId, user]);
  
    const handleDelete = () => {
        deleteData("soapNotes", soapNoteId, organisationId)
        if (globalUpdateData) {
            createData("globalUpdates", globalUpdateData, organisationId);
        }
        onClose();
        showDeleteSuccessModal();
        console.log("Deleted Soap Noye with id: ", soapNoteId)
    }

    return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
        <div className="bg-white p-4 rounded-lg text-center">
            <h2 className="text-2xl font-bold mb-4">
                Are you sure you want to delete this {toBeDeletedElementName}?
            </h2>
            <div className='flex flex-row justify-center gap-4'>
                <button onClick={handleDelete} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                    Delete
                </button>
                <button onClick={onClose} className="bg-gray-400 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                    Cancel
                </button>
            </div>
        </div>
    </div>  
  )
}

export default CheckBeforeDeleteModal