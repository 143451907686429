import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { readData, listenToCollection, updateData, readUser, createData, readAllForms } from "../functions/crud";
import { UserAuth } from "../context/AuthContext";
import { Eye, MoveLeft, Trash2, Pencil, ChevronDown, ChevronUp } from "lucide-react";
import { useNavigate } from "react-router-dom"
import SoapNoteModal from "./modals/SoapNoteModal";
import SuccessfullyCreatedModal from "./modals/SuccessfullyCreatedModal";
import CheckBeforeDeleteModal from "./modals/CheckBeforeDeleteModal";
import SuccessfullyDeletedModal from "./modals/SuccessfullyDeletedModal";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase/fire";
import ReadSoapNoteModal from "./modals/ReadSoapNoteModal";
import { calculateAge, getDate, getTime } from "./utils";
import AddFileModal from "./modals/AddFileModal";
import ReadFileModal from "./modals/ReadFileModal";
import DeleteFileModal from "./modals/DeleteFileModal";
import DeleteInjuryModal from "./modals/DeleteInjuryModal";
import { Tab } from '@headlessui/react'
import ReadFormModal from "./modals/ReadFormModal";
import DeleteFormModal from "./modals/DeleteFormModal";

export default function CaseFile() {

    const { user } = UserAuth();
    let params = useParams();
    const navigate = useNavigate();
    const [currentCaseFile, setCurrentCaseFile] = useState([]);
    const [currentPlayer, setCurrentPlayer] = useState([]);
    const [soapNotes, setSoapNotes] = useState([]);
    const [files, setFiles] = useState([]);
    const [forms, setForms] = useState([]);
    const [soapNoteCreatorDetails, setSoapNoteCreatorDetails] = useState([]);
    const [isModalVisible, setModalVisible] = useState(false);
    const [isAddFileModalVisible, setAddFileModalVisible] = useState(false);
    const [isReadFileModalVisible, setReadFileModalVisible] = useState(false);
    const [isReadFormModalVisible, setReadFormModalVisible] = useState(false);
    const [isSuccessModalVisible, setSuccessModalVisible] = useState(false);
    const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
    const [isDeleteSuccessModalVisible, setDeleteSuccessModalVisible] = useState(false);
    const [isReadSoapNoteModalVisible, setReadSoapNoteModalVisible] = useState(false);
    const [SoapNoteToDeleteId, setSoapNoteToDeleteId] = useState("");
    const [SoapNoteToReadId, setSoapNoteToReadId] = useState("");
    const [formToReadId, setFormToReadId] = useState("");
    const [formTodeleteId, setFormToDeleteId] = useState("");
    const [formTodeleteStatus, setFormToDeleteStatus] = useState("");
    const [fileToReadInfo, setFileToReadInfo] = useState({});
    const [fileToDeleteInfo, setFileToDeleteInfo] = useState({});
    const [isDeleteFileModalVisible, setDeleteFileModalVisible] = useState(false);
    const [isDeleteFormModalVisible, setDeleteFormModalVisible] = useState(false);
    const [isDeleteFileSuccessModalVisible, setDeleteFileSuccessModalVisible] = useState(false);
    const [isDeleteInjuryModalVisible, setDeleteInjuryModalVisible] = useState(false)
    const [isDeleteInjurysuccessfulModalVisible, setDeleteInjurysuccessfulModalVisible] = useState(false)
    const [isDeleteFormSuccessfulModalVisible, setDeleteFormSuccessfulModalVisible] = useState(false)
    const organisationId = user?.organisationId;

    const [newInjuryName, setNewInjuryName] = useState({ injury: "" });
    const [newDateOfInjury, setNewDateOfInjury] = useState({ dateOfInjury: "" });
    const [newExpectedRtp, setNewExpectedRtp] = useState({ expectedRtp: "" });

    const [editInjuryNameVisible, setEditInjuryNameVisible] = useState(false);
    const [editDateOfInjuryVisible, setEditDateOfInjuryVisible] = useState(false);
    const [editExpectedRtpVisible, setEditExpectedRtpVisible] = useState(false);
    const [soapNoteDetailsVisible, setSoapNoteDetailsVisible] = useState({});

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);

    const totalPages = Math.ceil(soapNotes.length / itemsPerPage);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentSoapNotes = soapNotes.slice(indexOfFirstItem, indexOfLastItem);

    const paginationInfo = `Page ${currentPage} of ${totalPages}`;

    const [globalUpdateData, setGlobalUpdateData] = useState({
        title: "Player Injury status changed",
        subTitle: ``,
        imageRef: `${user?.imageRef ? user?.imageRef : "https://placehold.co/600x400/2a8795/fff?text="}${(user?.firstName || '').charAt(0)}${(user?.LastName || '').charAt(0)}`,
        creatorFirstName: `${user?.firstName}`,
        creatorLastName: `${user?.LastName}`,
        creatorRole: `${user?.role}`,
        timeCreated: getTime(),
        dateCreated: getDate()
    });

    useEffect(() => {
        const fetchCaseFile = async () => {
            try {
                const caseFile = await readData("injuries", params.id, organisationId);
                setCurrentCaseFile(caseFile);
                fetchCurrentPlayer(caseFile.playerId);
                if (caseFile.files) {
                    setFiles(caseFile.files);
                } else {
                    setFiles([]);
                }
            } catch (error) {
                console.error("Error reading document:", error);
            }
        }

        const fetchCurrentPlayer = async (playerId) => {
            try {
                const player = await readData("players", playerId, organisationId);
                setCurrentPlayer(player);
            } catch (error) {
                console.error("Error reading document:", error);
            }
        }

        const checkCollectionExists = async () => {
            try {
                const collectionRef = collection(db, "organisations", organisationId, "soapNotes");
                const snapshot = await getDocs(collectionRef);

                if (!snapshot.empty) {
                    const unsubscribe = listenToCollection("soapNotes", organisationId, (newData) => {
                        // Filter soap notes where casefileId matches the current case file ID
                        const filteredSoapNotes = newData.filter(note => note.injuryId === params.id);
                        // Sort soap notes by selectedDate and selectedTime
                        const sortedSoapNotes = filteredSoapNotes.sort((a, b) => {
                            // Convert selectedDate and selectedTime to comparable formats
                            const dateA = new Date(a.selectedDate + ' ' + a.selectedTime);
                            const dateB = new Date(b.selectedDate + ' ' + b.selectedTime);
                            // Compare dates
                            return dateB - dateA;
                        });
                        setSoapNotes(sortedSoapNotes);
                    });

                    return () => {
                        unsubscribe();
                    };
                } else {
                    console.log("The 'soapNotes' collection does not exist or is empty.");
                }
            } catch (error) {
                console.error("Error checking collection:", error);
            }
        };

        const fetchForms = async () => {
            try {
                const formsData = await readAllForms(organisationId);
                const filteredForms = formsData.filter(form => form.injuryCaseFileId === params.id);
                setForms(filteredForms);
            } catch (error) {
                console.error("Error fetching forms:", error);
            }
        };


        fetchCaseFile();
        checkCollectionExists();
        fetchForms();
    }, [params.id, organisationId]);

    const toggleModal = () => {
        setModalVisible(!isModalVisible);
    };

    const toggleSuccessModal = () => {
        setSuccessModalVisible(!isSuccessModalVisible);
    }

    const toggleDeleteModal = () => {
        setDeleteModalVisible(!isDeleteModalVisible);
    }

    const toggleDeleteSuccessModal = () => {
        setDeleteSuccessModalVisible(!isDeleteSuccessModalVisible);
    }

    const toggleReadSoapNoteModal = () => {
        setReadSoapNoteModalVisible(!isReadSoapNoteModalVisible);
    }

    const toggleReadFileModal = () => {
        setReadFileModalVisible(!isReadFileModalVisible);
    }

    const toggleDeleteFileModal = () => {
        setDeleteFileModalVisible(!isDeleteFileModalVisible);
    }

    const toggleAddFileModal = () => {
        setAddFileModalVisible(!isAddFileModalVisible);
    }

    const toggleDeleteFileSuccess = () => {
        setDeleteFileSuccessModalVisible(!isDeleteFileSuccessModalVisible);
        // setFiles(updatedFiles);
    }

    const toggleDeleteInjuryModal = () => {
        setDeleteInjuryModalVisible(!isDeleteInjuryModalVisible);
    }

    const toggleDeleteInjurySuccessModal = () => {
        setDeleteInjurysuccessfulModalVisible(!isDeleteInjurysuccessfulModalVisible);
    }

    const toggleReadFormModal = () => {
        setReadFormModalVisible(!isReadFormModalVisible);
    }

    const toggleDeleteFormModal = () => {
        setDeleteFormModalVisible(!isDeleteFormModalVisible);
    }

    const toggleDeleteFormSuccessModal = () => {
        setDeleteFormSuccessfulModalVisible(!isDeleteFormSuccessfulModalVisible);
    }

    const getStatusBackgroundColor = (status) => {
        console.log(status)
        switch (status) {
            case 'In Triage':
                return 'bg-red-500';
            case 'In Rehab':
                return 'bg-yellow-400';
            case 'Returned to Training':
                return 'bg-purple-600';
            case 'Returned to Play':
                return 'bg-green-600';
            case 'Playing with Injury':
                return 'bg-orange-500';
            case 'Archived':
                return 'bg-gray-600';
            default:
                return 'bg-black';
        }
    }

    const calculateDateDifference = (startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);

        const yearDiff = end.getFullYear() - start.getFullYear();
        const monthDiff = end.getMonth() - start.getMonth();
        let dayDiff = end.getDate() - start.getDate();
        let months = yearDiff * 12 + monthDiff;

        if (dayDiff < 0) {
            const lastMonthEndDate = new Date(end.getFullYear(), end.getMonth(), 0); // Get the last day of the previous month
            dayDiff += lastMonthEndDate.getDate(); // Add the number of days in the last month to the negative day difference
            months--; // Adjust the month count
        }

        if (months < 0) {
            return `${Math.abs(months)} months, ${Math.abs(dayDiff)}`;
        } else if (months === 0) {
            return `${Math.abs(dayDiff)} `;
        } else {
            return `${months} months, ${Math.abs(dayDiff)}`;
        }
    };

    const handleStatusChange = async (e) => {
        const oldStatus = currentCaseFile.status;
        const newStatus = e.target.value;
        try {
            await updateData("injuries", params.id, { status: newStatus }, organisationId);
            setCurrentCaseFile((prevCaseFile) => ({
                ...prevCaseFile,
                status: newStatus,
            }));

            // Update globalUpdateData with the new subTitle
            const updatedGlobalUpdateData = {
                ...globalUpdateData,
                subTitle: `from "${oldStatus}" to "${newStatus}" for ${currentPlayer?.firstName} ${currentPlayer?.lastName}'s ${currentCaseFile.injury}`
            };

            // Update globalUpdateData state
            setGlobalUpdateData(updatedGlobalUpdateData);

            // Create global update with the updated data
            createData("globalUpdates", updatedGlobalUpdateData, organisationId);
        } catch (error) {
            console.error("Error updating status:", error);
        }
    };


    const saveEditInjuryName = async () => {
        updateData("injuries", params.id, newInjuryName, organisationId);
        setCurrentCaseFile({ ...currentCaseFile, injury: newInjuryName.injury })
        setEditInjuryNameVisible(!editInjuryNameVisible)
    }

    const saveEditDateOfInjury = async () => {
        updateData("injuries", params.id, newDateOfInjury, organisationId);
        setCurrentCaseFile({ ...currentCaseFile, dateOfInjury: newDateOfInjury.dateOfInjury })
        setEditDateOfInjuryVisible(!editDateOfInjuryVisible)
    }

    const saveEditExpectedRtp = async () => {
        updateData("injuries", params.id, newExpectedRtp, organisationId);
        setCurrentCaseFile({ ...currentCaseFile, expectedRtp: newExpectedRtp.expectedRtp })
        setEditExpectedRtpVisible(!editExpectedRtpVisible)
    }

    useEffect(() => {
        // Fetch creator details for each soap note when soapNotes change
        soapNotes.forEach(note => {
            fetchSoapNoteCreator(note.creatorId);
        });
    }, [soapNotes]);

    const fetchSoapNoteCreator = async (creatorId) => {
        try {
            const user = await readUser(creatorId); // Assuming readUser function fetches user details using creatorId
            setSoapNoteCreatorDetails((prevDetails) => ({
                ...prevDetails,
                [creatorId]: user // Store user details in an object using creatorId as key
            }));
        } catch (error) {
            console.error("Error reading document:", error);
        }
    }

    const toggleSoapNoteDetailsVisible = (noteId) => {
        setSoapNoteDetailsVisible(prevState => ({
            ...prevState,
            [noteId]: !prevState[noteId] // Toggle visibility for the specific note
        }));
    };

    const handleFileUploadSuccess = (downloadURL, fileName) => {
        setFiles(prevFiles => [...prevFiles, { url: downloadURL, name: fileName }]);
    }

    const goToPreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const goToNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    const goToPage = (pageNumber) => {
        setCurrentPage(Math.min(Math.max(pageNumber, 1), totalPages));
    };

    // Render the pagination controls
    const paginationControls = (
        <div className="flex justify-end mt-4">
            <button onClick={goToPreviousPage} disabled={currentPage === 1} className="px-3 py-1 mr-2 bg-gray-200 hover:bg-gray-300 rounded-md focus:outline-none">
                Previous
            </button>
            <button onClick={goToNextPage} disabled={currentPage === totalPages} className="px-3 py-1 bg-gray-200 hover:bg-gray-300 rounded-md focus:outline-none">
                Next
            </button>
        </div>
    );

    return (
        <div className="mx-10 my-10">

            {isModalVisible &&
                <div>
                    <SoapNoteModal onClose={toggleModal} paramsId={params.id} showSuccessModal={toggleSuccessModal} playerId={currentPlayer?.id} />
                </div>}

            {isSuccessModalVisible &&
                <div>
                    <SuccessfullyCreatedModal onClose={toggleSuccessModal} createdElementName="Soap Note" />
                </div>}

            {isDeleteModalVisible &&
                <div>
                    <CheckBeforeDeleteModal onClose={toggleDeleteModal} toBeDeletedElementName="Soap Note" soapNoteId={SoapNoteToDeleteId} showDeleteSuccessModal={toggleDeleteSuccessModal} caseFileId={params.id} />
                </div>}

            {isDeleteSuccessModalVisible &&
                <div>
                    <SuccessfullyDeletedModal onClose={toggleDeleteSuccessModal} deletedElementName="Soap Note" />
                </div>}

            {isReadSoapNoteModalVisible && <div>
                <ReadSoapNoteModal onClose={toggleReadSoapNoteModal} soapNoteId={SoapNoteToReadId} isVisible={isReadSoapNoteModalVisible} />
            </div>}

            {isAddFileModalVisible && <div>
                <AddFileModal onClose={toggleAddFileModal} injuryId={currentCaseFile.id} onFileUploadSuccess={handleFileUploadSuccess} files={files} />
            </div>}
            {isReadFileModalVisible && <div>
                <ReadFileModal onClose={toggleReadFileModal} file={fileToReadInfo} />
            </div>}
            {isDeleteFileModalVisible && <div>
                <DeleteFileModal onClose={toggleDeleteFileModal} file={fileToDeleteInfo} injuryId={currentCaseFile.id} deletionSuccessfull={toggleDeleteFileSuccess} />
            </div>}
            {isDeleteFileSuccessModalVisible && <div>
                <SuccessfullyDeletedModal onClose={toggleDeleteFileSuccess} deletedElementName="File" />
            </div>}

            {isDeleteInjuryModalVisible && <DeleteInjuryModal onClose={toggleDeleteInjuryModal} injury={currentCaseFile} onDeleteInjury={toggleDeleteInjurySuccessModal} />}
            {isDeleteInjurysuccessfulModalVisible && <SuccessfullyDeletedModal onClose={toggleDeleteInjurySuccessModal} deletedElementName="Injury" />}

            {isReadFormModalVisible && <ReadFormModal onClose={toggleReadFormModal} formId={formToReadId}/>}
            {isDeleteFormModalVisible && <DeleteFormModal onClose={toggleDeleteFormModal} formId={formTodeleteId} formStatus={formTodeleteStatus} deletionSuccessful={toggleDeleteFormSuccessModal} />}
            {isDeleteFormSuccessfulModalVisible && <SuccessfullyDeletedModal onClose={toggleDeleteFormSuccessModal} deletedElementName="Form" />}

            <MoveLeft
                className="items-center mr-4 text-gray-300 hover:text-gray-700 cursor-pointer"
                size={50}
                onClick={() => navigate("/injuries")}
            />
            <div className="bg-transparent pt-1 mt-4 pb-4 rounded-sm flex flex-row">

                <div name="left-segment" className="flex flex-col w-1/4">

                    {currentPlayer?.imageRef ? (
                        <div
                            className="h-48 w-48 rounded-sm bg-sky-500 bg-cover bg-no-repeat bg-center"
                            style={{ backgroundImage: `url(${currentPlayer?.imageRef})` }}
                        >
                        </div>
                    ) : (
                        <div
                            className="h-48 w-48 rounded-sm bg-gray-300 text-gray-600 bg-cover bg-no-repeat bg-center text-center"
                            style={{ backgroundImage: `url(https://placehold.co/600x400/f4b346/fff?text=${(currentPlayer?.firstName || '').charAt(0)}${(currentPlayer?.lastName || '').charAt(0)})` }}

                        >
                        </div>
                    )}

                    <div className="flex flex-col mt-2 mb-8">
                        <div className="flex flex-row">
                            <h1 className="text-sm font-semibold text-gray-400 mt-2">Injury</h1>
                            <Pencil size={15} className="text-gray-500 ml-1 mt-2 cursor-pointer" onClick={() => { setEditInjuryNameVisible(true) }} />
                        </div>
                        {!editInjuryNameVisible && <div className="flex flex-row">
                            {currentCaseFile?.injury ? (<h1 className="text-xl font-bold">{currentCaseFile?.injury}</h1>) : (<h1 className="text-center">-</h1>)}
                        </div>}
                        {editInjuryNameVisible && <div>
                            <input
                                type="text"
                                placeholder="Injury"
                                className="text-sm w-4/5 rounded-md py-1.5 mb-2"
                                onChange={(e) => {
                                    setNewInjuryName({ injury: e.target.value })
                                }}
                            />
                            <div>
                                <button
                                    className='mr-auto bg-darkTealPreset hover:bg-tealPreset text-white font-bold px-2 py-1 rounded'
                                    onClick={saveEditInjuryName}
                                >
                                    Save
                                </button>
                                <button
                                    className='mr-auto ml-2 bg-gray-400 hover:bg-gray-700 text-white font-bold px-2 py-1 rounded'
                                    onClick={() => { setEditInjuryNameVisible(false) }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>}
                    </div>

                    <div className="mb-8">
                        <h1 className="text-sm font-semibold text-gray-400">Status</h1>
                        <select
                            className={`${getStatusBackgroundColor(currentCaseFile?.status)} text-white font-semibold py-2 px-2 flex-1 mr-auto rounded border-none shadow-md cursor-pointer`}
                            value={currentCaseFile?.status}
                            onChange={handleStatusChange}
                        >
                            <option value="In Triage">In Triage</option>
                            <option value="In Rehab">In Rehab</option>
                            <option value="Returned to Training">Returned to Training</option>
                            <option value="Returned to Play">Returned to Play</option>
                            <option value="Playing with Injury">Playing with Injury</option>
                            <option value="Archived">Archived</option>
                        </select>
                    </div>

                    <div className="flex flex-col mb-8">
                        <div>
                            <h1 className="text-sm font-semibold text-gray-400">Home Address</h1>
                            <h1>{currentPlayer?.address?.street ? (currentPlayer?.address?.street) : (<h1 className="text-center">-</h1>)}</h1>
                            <h1>{currentPlayer?.address?.postCode ? (currentPlayer?.address?.postCode) : (<h1 className="text-center">-</h1>)}</h1>
                        </div>
                    </div>

                    <div className="flex flex-col">
                        <div>
                            <h1 className="text-sm font-semibold text-gray-400">Contact Information</h1>
                            <h1>{currentPlayer?.email ? (currentPlayer?.email) : ("Email: -")}</h1>
                            <h1>{currentPlayer?.phone ? (currentPlayer?.phone) : ("Phone: -")}</h1>
                        </div>
                    </div>
                </div>

                <div name="right-segment" className="flex flex-col w-3/4">

                    <div className="bg-white px-4 pt-3 pb-4 rounded-sm border border-gray-200 flex-1 shadow-md">
                        <h1 className="text-sm font-semibold text-gray-400">Full Name</h1>
                        <h1 className="text-xl font-bold pb-12">{currentPlayer?.firstName} {currentPlayer?.lastName}</h1>
                        <div className="flex flex-row justify-between">
                            <div>
                                <h1 className="text-sm font-semibold text-gray-400">Date of Birth</h1>
                                <h1>{currentPlayer?.dob ? (currentPlayer?.dob) : (<h1 className="text-center">-</h1>)}</h1>
                                <h1 className="text-gray-400 text-sm">{calculateAge(currentPlayer?.dob)} years old</h1>
                            </div>

                            <div>
                                <div className="flex flex-row">
                                    <h1 className="text-sm font-semibold text-gray-400">Date of Injury</h1>
                                    <Pencil size={15} className="text-gray-500 ml-1 cursor-pointer" onClick={() => { setEditDateOfInjuryVisible(true) }} />
                                </div>
                                {!editDateOfInjuryVisible && <div>
                                    <h1 className="">{currentCaseFile?.dateOfInjury}</h1>
                                    <h1 className="text-gray-400 text-sm">{calculateDateDifference(currentCaseFile?.dateOfInjury, new Date())} days ago</h1>
                                </div>}

                                {editDateOfInjuryVisible && <div>
                                    <input
                                        type="date"
                                        placeholder="Date Of Injury"
                                        className="text-sm w-4/5 rounded-md py-1.5 mb-2"
                                        onChange={(e) => {
                                            setNewDateOfInjury({ dateOfInjury: e.target.value })
                                        }}
                                    />
                                    <div>
                                        <button
                                            className='mr-auto bg-darkTealPreset hover:bg-tealPreset text-white font-bold px-2 py-1 rounded'
                                            onClick={saveEditDateOfInjury}
                                        >
                                            Save
                                        </button>
                                        <button
                                            className='mr-auto ml-2 bg-gray-400 hover:bg-gray-700 text-white font-bold px-2 py-1 rounded'
                                            onClick={() => { setEditDateOfInjuryVisible(false) }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>}
                            </div>

                            <div>
                                <div className="flex flex-row">
                                    <h1 className="text-sm font-semibold text-gray-400">Expected RTP</h1>
                                    <Pencil size={15} className="text-gray-500 ml-1 cursor-pointer" onClick={() => { setEditExpectedRtpVisible(true) }} />
                                </div>

                                {!editExpectedRtpVisible && <div>
                                    <h1 className="">{currentCaseFile?.expectedRtp}</h1>
                                    <h1 className="text-gray-400 text-sm">{calculateDateDifference(new Date(), currentCaseFile?.expectedRtp)} days in the future</h1>
                                </div>}

                                {editExpectedRtpVisible && <div>
                                    <input
                                        type="date"
                                        placeholder="Expected RTP"
                                        className="text-sm w-4/5 rounded-md py-1.5 mb-2"
                                        onChange={(e) => {
                                            setNewExpectedRtp({ expectedRtp: e.target.value })
                                        }}
                                    />
                                    <div>
                                        <button
                                            className='mr-auto bg-darkTealPreset hover:bg-tealPreset text-white font-bold px-2 py-1 rounded'
                                            onClick={saveEditExpectedRtp}
                                        >
                                            Save
                                        </button>
                                        <button
                                            className='mr-auto ml-2 bg-gray-400 hover:bg-gray-700 text-white font-bold px-2 py-1 rounded'
                                            onClick={() => { setEditExpectedRtpVisible(false) }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>

                    <Tab.Group>
                        <Tab.List className="flex p-1 space-x-1 bg-gray-900/20 rounded-xl mt-4">
                            <Tab
                                className={({ selected }) =>
                                    selected
                                        ? 'w-full py-2.5 text-sm leading-5 font-medium text-white bg-gray-600 rounded-lg'
                                        : 'w-full py-2.5 text-sm leading-5 font-medium text-gray-400 rounded-lg hover:bg-white/[0.12] hover:text-white'
                                }
                            >
                                Soap Notes
                            </Tab>
                            <Tab
                                className={({ selected }) =>
                                    selected
                                        ? 'w-full py-2.5 text-sm leading-5 font-medium text-white bg-gray-600 rounded-lg'
                                        : 'w-full py-2.5 text-sm leading-5 font-medium text-gray-400 rounded-lg hover:bg-white/[0.12] hover:text-white'
                                }
                            >
                                Files
                            </Tab>
                            <Tab
                                className={({ selected }) =>
                                    selected
                                        ? 'w-full py-2.5 text-sm leading-5 font-medium text-white bg-gray-600 rounded-lg'
                                        : 'w-full py-2.5 text-sm leading-5 font-medium text-gray-400 rounded-lg hover:bg-white/[0.12] hover:text-white'
                                }
                            >
                                Forms
                            </Tab>
                        </Tab.List>
                        <Tab.Panels className="mt-2">
                            <Tab.Panel className="rounded-xl p-3 ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400">
                                <div className="flex-1 h-[100%]">
                                    <div className="bg-white px-4 pt-3 mt-4 rounded-sm border border-gray-200 flex-1 h-[100%] shadow-md">
                                        <div className="flex flex-row">
                                            <h1 className="text-xl font-bold pb-4">Soap Notes</h1>
                                            <button
                                                className="bg-darkTealPreset hover:bg-tealPreset text-white font-bold px-2 rounded ml-auto"
                                                onClick={() => setModalVisible(true)}>
                                                + Add
                                            </button>
                                        </div>
                                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 mt-4">
                                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-200">
                                                <tr>
                                                    <th scope="col" className="px-6 py-3">
                                                        View
                                                    </th>
                                                    <th scope="col" className="px-6 py-3">
                                                        Date & Time
                                                    </th>
                                                    <th scope="col" className="px-6 py-3">
                                                        Title
                                                    </th>
                                                    <th scope="col" className="px-6 py-3">
                                                        Creator
                                                    </th>
                                                    <th scope="col" className="px-6 py-3">
                                                        Delete
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentSoapNotes.length === 0 ? (
                                                    <tr className="bg-white border-b hover:bg-gray-50">
                                                        <td colSpan="6" className="text-center">No Soap Notes found.</td>
                                                    </tr>
                                                ) : (
                                                    currentSoapNotes.map((note, index) => (
                                                        <tr key={index} className='bg-white border-b hover:bg-gray-50"'>
                                                            <td className='px-6 py-4'>
                                                                <Eye
                                                                    className='cursor-pointer mr-2 text-gray-400 hover:text-black'
                                                                    onClick={() => {
                                                                        toggleReadSoapNoteModal();
                                                                        setSoapNoteToReadId(note.id);
                                                                    }} />
                                                            </td>
                                                            <td className='px-6 py-4'>
                                                                <p className=" ml-5">{note?.selectedTime}</p>
                                                                <p>{note?.selectedDate}</p>
                                                            </td>
                                                            <td className='px-6 py-4'>{note.title || '-'}</td>
                                                            <td className='px-6 py-4'>
                                                                <div className="flex flex-row items-center mt-2">
                                                                    <div
                                                                        className="h-10 w-10 rounded-full bg-sky-500 bg-cover bg-no-repeat bg-center"
                                                                        style={{ backgroundImage: `url(${note?.creatorImageRef})` }}
                                                                    ></div>
                                                                    <div className="flex flex-col ml-1 mt-1">
                                                                        <h1 className="text-gray-700">{note?.creatorFirstName + " " + note?.creatorLastName}</h1>
                                                                        <h1 className="text-gray-400 mt-[-5px]">{note?.creatorRole ? note.creatorRole : "Role Undefined"}</h1>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className='px-6 py-4'>
                                                                <Trash2
                                                                    className="cursor-pointer ml-2 text-gray-400 hover:text-black"
                                                                    onClick={() => {
                                                                        toggleDeleteModal();
                                                                        setSoapNoteToDeleteId(note.id);
                                                                    }} />
                                                            </td>
                                                        </tr>
                                                    ))
                                                )}
                                            </tbody>
                                        </table>
                                        <p className="text-center mt-2">{paginationInfo}</p>
                                        {paginationControls}
                                    </div>
                                </div>
                            </Tab.Panel>
                            <Tab.Panel className="rounded-xl p-3 ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 ">
                                <div className="bg-transparent rounded-sm flex-1 pt-4">
                                    <div className="bg-white px-4 pt-3 mt-4 pb-4 rounded-sm border border-gray-200 flex-1 shadow-md">
                                        <div className="flex flex-row">
                                            <h1 className="text-xl font-bold pb-4">Files</h1>
                                            <button
                                                className="bg-darkTealPreset hover:bg-tealPreset text-white font-bold px-2 rounded ml-auto"
                                                onClick={() => setAddFileModalVisible(true)}>
                                                + Add
                                            </button>
                                        </div>
                                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 mt-4">
                                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-200">
                                                <tr>
                                                    <th scope="col" className="px-6 py-3">
                                                        View
                                                    </th>
                                                    <th scope="col" className="px-6 py-3">
                                                        File Name
                                                    </th>
                                                    <th scope="col" className="px-6 py-3">
                                                        Delete
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {files.length === 0 ? (
                                                    <tr className="bg-white border-b hover:bg-gray-50">
                                                        <td colSpan="6" className="text-center">No Files found.</td>
                                                    </tr>
                                                ) : (
                                                    files.map((file, index) => (
                                                        <tr key={index} className='bg-white border-b hover:bg-gray-50"'>
                                                            <td className='px-6 py-4'>
                                                                <Eye
                                                                    className='cursor-pointer mr-2 text-gray-400 hover:text-black'
                                                                    onClick={() => {
                                                                        toggleReadFileModal();
                                                                        setFileToReadInfo(file);
                                                                    }} />
                                                            </td>
                                                            <td className='px-6 py-4'>
                                                                <h1 className="text-gray-700">{file?.name}</h1>
                                                            </td>
                                                            <td className='px-6 py-4'>
                                                                <Trash2
                                                                    className="cursor-pointer ml-2 text-gray-400 hover:text-black"
                                                                    onClick={() => {
                                                                        toggleDeleteFileModal();
                                                                        setFileToDeleteInfo(file);
                                                                    }} />
                                                            </td>
                                                        </tr>
                                                    ))
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Tab.Panel>
                            <Tab.Panel className="rounded-xl p-3 ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400">
                                <div className="bg-transparent rounded-sm flex-1 pt-4">
                                    <div className="bg-white px-4 pt-3 mt-4 pb-4 rounded-sm border border-gray-200 flex-1 shadow-md">
                                        <div className="flex flex-row">
                                            <h1 className="text-xl font-bold pb-4">Forms</h1>
                                        </div>
                                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 mt-4">
                                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-200">
                                                <tr>
                                                    <th scope="col" className="px-6 py-3">
                                                        View
                                                    </th>
                                                    <th scope="col" className="px-6 py-3">
                                                        Title
                                                    </th>
                                                    <th scope="col" className="px-6 py-3">
                                                        Status
                                                    </th>
                                                    <th scope="col" className="px-6 py-3">
                                                        Delete
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {forms.length === 0 ? (
                                                    <tr className="bg-white border-b hover:bg-gray-50">
                                                        <td colSpan="6" className="text-center">No Forms Completed.</td>
                                                    </tr>
                                                ) : (
                                                    forms.map((form, index) => (
                                                        <tr key={index} className='bg-white border-b hover:bg-gray-50"'>
                                                            <td className='px-6 py-4'>
                                                                <Eye
                                                                    className='cursor-pointer mr-2 text-gray-400 hover:text-black' 
                                                                    onClick={() => {
                                                                        toggleReadFormModal();
                                                                        setFormToReadId(form.id);
                                                                    }}
                                                                    />
                                                            </td>
                                                            <td className='px-6 py-4'>
                                                                <h1 className="text-gray-700">{form?.formTitle}</h1>
                                                            </td>
                                                            <td className='px-6 py-4'>
                                                                <h1 className="text-gray-700">{form?.status}</h1>
                                                            </td>
                                                            <td className='px-6 py-4'>
                                                                <Trash2
                                                                    className="cursor-pointer ml-2 text-gray-400 hover:text-black"
                                                                    onClick={() => {
                                                                        toggleDeleteFormModal();
                                                                        setFormToDeleteId(form.id)
                                                                        setFormToDeleteStatus(form.status)
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Tab.Panel>
                        </Tab.Panels>
                    </Tab.Group>
                </div>
            </div>

            <div className="bg-transparent px-4 pt-3 mt-4 pb-4 rounded-sm flex flex-row justify-between">
                <div></div> {/* This empty div will push the button to the right */}
                <button
                    className='bg-red-400 hover:bg-red-600 text-white font-bold px-4 py-2 rounded'
                    onClick={toggleDeleteInjuryModal}
                >
                    Delete Case File
                </button>
            </div>
        </div>
    )
}