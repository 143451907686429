import {
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    onSnapshot,
    setDoc,
    updateDoc,
    deleteField,
    query,
    where
} from "firebase/firestore";
import { ref, deleteObject, listAll } from 'firebase/storage';
import { db, storage } from "../firebase/fire";

export const generateRandomId = () => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomId = "";

    for (let i = 0; i < 20; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        randomId += characters.charAt(randomIndex);
    }
    return randomId
}

export const createData = async (collectionName, data, organisationId) => {
    const id = generateRandomId();
    try {
        const docRef = doc(db, "organisations", organisationId, collectionName, id);
        await setDoc(docRef, {
            id,
            ...data,
            organisationId
        });
    } catch (error) {
        console.error("Error adding document: ", error)
    }
    return id;
};

export const createMail = async (data) => {
    const id = generateRandomId();
    try {
        const docRef = doc(db, "mail", id);
        await setDoc(docRef, {
            ...data,
        });
    } catch (error) {
        console.error("Error adding document: ", error)
    }
    return id;
};

export const createUserInvitation = async (data) => {
    const id = data.id;
    try {
        const docRef = doc(db, "invitations", id);
        await setDoc(docRef, {
            ...data,
        });
    } catch (error) {
        console.error("Error adding document: ", error)
    }
    return id;
};

export const createFormToComplete = async (data) => {
    const id = data.id;
    try {
        const docRef = doc(db, "formsToComplete", id);
        await setDoc(docRef, {
            ...data,
        });
    } catch (error) {
        console.error("Error adding document: ", error)
    }
    return id;
};

export const createCompletedForm = async (data) => {
    const id = data.id;
    try {
        const docRef = doc(db, "completedForms", id);
        await setDoc(docRef, {
            ...data,
        });
    } catch (error) {
        console.error("Error adding document: ", error)
    }
    return id;
};

export const readData = async (collectionName, id, organisationId) => {
    try {
        const docRef = doc(db, "organisations", organisationId, collectionName, id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            console.log("Document data: ", docSnap.data());
            return docSnap.data();
        } else {
            console.log("No such document exists!")
        }
    } catch (error) {
        console.error("Error reading document: ", error)
    }
};

export const readUser = async (id) => {
    try {
        const docRef = doc(db, "users", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            console.log("Document data: ", docSnap.data());
            return docSnap.data();
        } else {
            console.log("No such document exists!")
        }
    } catch (error) {
        console.error("Error reading document: ", error)
    }
};

export const readInvite = async (id) => {
    try {
        const docRef = doc(db, "invitations", id)
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            console.log("Document data: ", docSnap.data());
            return docSnap.data();
        } else {
            console.log("Invitation not found or expired")
        }
    } catch (error) {
        console.error("Error fetching invitation: ", error)
    }
};

export const readFormToComplete = async (id) => {
    try {
        const docRef = doc(db, "formsToComplete", id)
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            console.log("Document data: ", docSnap.data());
            return docSnap.data();
        } else {
            console.log("form not found or expired")
        }
    } catch (error) {
        console.error("Error fetching form: ", error)
    }
};

export const readCompletedForm = async (id) => {
    try {
        const docRef = doc(db, "completedForms", id)
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            console.log("Document data: ", docSnap.data());
            return docSnap.data();
        } else {
            console.log("form not found or expired")
        }
    } catch (error) {
        console.error("Error fetching form: ", error)
    }
};

export const updateData = async (collectionName, id, data, organisationId) => {
    try {
        const docRef = doc(db, "organisations", organisationId, collectionName, id);
        await updateDoc(docRef, {
            id: id,
            ...data,
        });

        console.log("Document successfully updated!")
    } catch (error) {
        console.error("Error updating document: ", error)
    }
};

export const updateUser = async (data, userId) => {
    try {
        const docRef = doc(db, "users", userId);
        await updateDoc(docRef, {
            uid: userId,
            ...data
        });

        console.log("Document successfully updated!")
    } catch (error) {
        console.error("Error updating document: ", error)
    }
}

export const updateOrganisation = async (data, organisationId) => {
    try {
        const docRef = doc(db, "organisations", organisationId);
        await updateDoc(docRef, {
            uid: organisationId,
            ...data
        });

        console.log("Document successfully updated!")
    } catch (error) {
        console.error("Error updating document: ", error)
    }
}

export const deleteData = async (collectionName, id, organisationId) => {
    try {
        const docRef = doc(db, "organisations", organisationId, collectionName, id);
        await deleteDoc(docRef)
        console.log("Document successfully deleted!")
    } catch (error) {
        console.error("Error deleting document: ", error)
    }
};

export const deleteDocumentsByField = async (collectionName, fieldName, value, organisationId) => {
    try {
        const collectionRef = collection(db, "organisations", organisationId, collectionName);
        const querySnapshot = await getDocs(collectionRef);

        querySnapshot.forEach(async (doc) => {
            const documentData = doc.data();
            if (documentData[fieldName] === value) {
                await deleteDoc(doc.ref);
                console.log("Document successfully deleted!");
            }
        });
    } catch (error) {
        console.error("Error deleting documents: ", error);
    }
};

export const deleteFilesInFolder = async (organisationId, folderName, id) => {
    try {
        // Get a reference to the folder in Firebase Storage
        const folderRef = ref(storage, `${organisationId}/${folderName}/${id}`);

        // List all the files in the folder
        const listResult = await listAll(folderRef);

        // Iterate through the list of files and delete each one
        await Promise.all(listResult.items.map(async (itemRef) => {
            await deleteObject(itemRef);
            console.log(`File '${itemRef.name}' in folder '${folderName}' for document with ID '${id}' successfully deleted from Firebase Storage.`);
        }));

        console.log(`All files in folder '${folderName}' for document with ID '${id}' successfully deleted.`);
    } catch (error) {
        console.error("Error deleting files:", error);
    }
};


export const deleteFileFromFilesArray = async (collectionName, id, organisationId, fileNameToDelete) => {
    try {
        const docRef = doc(db, "organisations", organisationId, collectionName, id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const data = docSnap.data();
            const updatedFiles = data.files.filter(file => file.name !== fileNameToDelete);
            await updateData(collectionName, id, { files: updatedFiles }, organisationId);
            console.log(`File '${fileNameToDelete}' successfully deleted from 'files' array.`);

            // Delete the file from Firebase Storage
            const fileRef = ref(storage, `${organisationId}/injuries/${id}/${fileNameToDelete}`);
            await deleteObject(fileRef);
            console.log(`File '${fileNameToDelete}' successfully deleted from Firebase Storage.`);
            return updatedFiles;
        } else {
            console.error("Document not found");
        }
    } catch (error) {
        console.error("Error deleting file:", error);
    }
};


export const deleteSpecificField = async (collectionName, id, organisationId, fieldName) => {
    try {
        const docRef = doc(db, "organisations", organisationId, collectionName, id);
        await updateDoc(docRef, {
            [fieldName]: deleteField()
        });
        console.log(`Field "${fieldName}" successfully deleted from document ${id} in collection ${collectionName}!`);
    } catch (error) {
        console.error("Error deleting field: ", error);
    }
};

export const deleteInvite = async (id) => {
    try {
        const docRef = doc(db, "invitations", id);
        await deleteDoc(docRef)
        console.log("Document successfully deleted!")
    } catch (error) {
        console.error("Error deleting document: ", error)
    }
};

export const deleteFormToComplete = async (id) => {
    try {
        const docRef = doc(db, "formsToComplete", id);
        await deleteDoc(docRef)
        console.log("Document successfully deleted!")
    } catch (error) {
        console.error("Error deleting document: ", error)
    }
};

export const deleteCompletedForm = async (id) => {
    try {
        const docRef = doc(db, "completedForms", id);
        await deleteDoc(docRef)
        console.log("Document successfully deleted!")
    } catch (error) {
        console.error("Error deleting document: ", error)
    }
};

export const deleteUser = async (id) => {
    try {
        const docRef = doc(db, "users", id);
        await deleteDoc(docRef)
        console.log("Document successfully deleted!")
    } catch (error) {
        console.error("Error deleting document: ", error)
    }
};

export const readAllData = async (collectionName, organisationId) => {
    try {
        const newDataArr = [];
        const querySnapshot = await getDocs(collection(db, "organisations", organisationId, collectionName))
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());
            newDataArr.push({ ...doc.data() });
        });
        return newDataArr;
    } catch (error) {
        console.error("Error reading collection: ", error)
    }
};

export const readAllForms = async (organisationId) => {
    try {
        const collections = ["formsToComplete", "completedForms"];
        const allDataArr = [];

        for (const collectionName of collections) {
            const q = query(
                collection(db, collectionName),
                where("organisationId", "==", organisationId)
            );
            const querySnapshot = await getDocs(q);

            querySnapshot.forEach((doc) => {
                console.log(doc.id, " => ", doc.data());
                allDataArr.push({ ...doc.data(), id: doc.id });
            });
        }

        return allDataArr;
    } catch (error) {
        console.error("Error reading collections: ", error);
        return [];
    }
};

export const listenToCollection = (collectionName, organisationId, callback) => {
    const collectionRef = collection(db, "organisations", organisationId, collectionName);

    return onSnapshot(collectionRef, (querySnapshot) => {
        const newDataArr = [];
        querySnapshot.forEach((doc) => {
            newDataArr.push({ ...doc.data() });
        });
        callback(newDataArr);
    })
};

export const checkSubscriptionStatus = async (organisationId) => {
    try {
        // Check if the organisation has an owner field
        const organisationRef = doc(db, "organisations", organisationId);
        const organisationSnapshot = await getDoc(organisationRef);
        const organisationData = organisationSnapshot.data();

        if (!organisationData.owner) {
            // If organisation doesn't have an owner field, return true
            return true;
        } else {
            // If organisation has an owner field, check if the owner has an active subscription
            const owner = organisationData.owner;
            const subscriptionsRef = collection(db, "users", owner, "subscriptions");
            const q = query(subscriptionsRef, where("status", "in", ["trialing", "active"]));
            const querySnapshot = await getDocs(q);

            // If there are subscriptions with status 'trialing' or 'active', return false
            return querySnapshot.empty;
        }
    } catch (error) {
        console.error("Error checking subscription status:", error);
        return true;
    }
};

export const checkisUserOwner = async (organisationId, currentUserId) => {
    try {
        const organisationRef = doc(db, "organisations", organisationId);
        const organisationSnapshot = await getDoc(organisationRef);
        const organisationData = organisationSnapshot.data();

        if (organisationData.owner == currentUserId) {
            return true;
        } else {

            return false;
        }
    } catch (error) {
        console.error("Error checking subscription status:", error);
        return false;
    }
};

export const checkSubscriptionAccountInviteLimit = async (organisationId) => {
    try {
        // Get the organisation document
        const organisationRef = doc(db, "organisations", organisationId);
        const organisationSnapshot = await getDoc(organisationRef);
        const organisationData = organisationSnapshot.data();

        if (!organisationData.owner) {
            return getDefaultInviteLimit();
        } else {
            const owner = organisationData.owner;
            const subscriptionsRef = collection(db, "users", owner, "subscriptions");
            const q = query(subscriptionsRef, where("status", "in", ["trialing", "active"]));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const subscriptionData = querySnapshot.docs[0].data();
                const subscriptionPlanId = subscriptionData.items[0].plan.id; // Assuming plan ID is stored in the subscription document

                const inviteLimit = getInviteLimitForPlan(subscriptionPlanId);
                return inviteLimit;
            } else {
                return getDefaultInviteLimit();
            }
        }
    } catch (error) {
        console.error("Error checking account invite limit: ", error);
        return getDefaultInviteLimit();
    }
}

const getDefaultInviteLimit = () => {
    return 1;
}

const getInviteLimitForPlan = (planId) => {
    switch (planId) {
        case "price_1P7IraJE427N7TRjRlQcyiBg":
            return 1;
        case "price_1P7IxBJE427N7TRjvRfqxAPj":
            return 6;
        case "price_1P8OoUJE427N7TRj6XYXrvrJ":
            return 12;
        case "price_1P8Op0JE427N7TRjy5bqWE3u":
            return 25;
        case "price_1P8OpFJE427N7TRjA4K6ZHUR":
            return 100;
        case "price_1POdQuJE427N7TRjdXL4hLG6":
            return 1;
        case "price_1POdRMJE427N7TRjaTA5F3xQ":
            return 6;
        case "price_1POdRPJE427N7TRjfowGKpuj":
            return 12;
        case "price_1POdRSJE427N7TRjvENEUKPU":
            return 25;
        case "price_1POdRUJE427N7TRjnPy6MuAZ":
            return 100;
        default:
            return getDefaultInviteLimit();
    }
}