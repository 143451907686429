import React, { useState } from 'react';
import { X } from 'lucide-react';
import { deleteCompletedForm, deleteFormToComplete } from '../../functions/crud';
import { UserAuth } from '../../context/AuthContext';

function DeleteFormModal({ onClose, formId, formStatus, deletionSuccessful }) {
    const { user } = UserAuth();
    const organisationId = user?.organisationId;

    const handleDeleteButtonClicked = async () => {
        try {
            if (formStatus == 'Pending') {
                await deleteFormToComplete(formId, organisationId);
            } else if (formStatus == 'Completed') {
                await deleteCompletedForm(formId, organisationId);
            }

            onClose(); // Close the modal after successful deletion
            deletionSuccessful(); // Optional callback to notify parent component of successful deletion
        } catch (error) {
            console.error('Error deleting form:', error);
            // Handle error as needed (e.g., show error message)
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
            <div className="bg-white p-8 rounded-md shadow-md max-w-lg w-full overflow-hidden">
                <div className="flex flex-row items-center mb-4">
                    <h2 className="text-2xl font-bold flex-1">Delete Form</h2>
                    <X className="text-gray-400 hover:text-gray-600 cursor-pointer" onClick={onClose} />
                </div>
                <div className="max-h-96 overflow-y-auto">
                    <h1 className="mb-5">
                        Are you sure you want to delete this form?
                    </h1>
                    <div className="flex justify-end">
                        <button
                            className="mr-2 bg-red-400 hover:bg-red-600 text-white font-bold px-4 py-2 rounded"
                            onClick={handleDeleteButtonClicked}
                        >
                            Delete
                        </button>
                        <button
                            className="bg-gray-400 hover:bg-gray-700 text-white font-bold px-4 py-2 rounded"
                            onClick={onClose}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DeleteFormModal;
