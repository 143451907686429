import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { readInvite, deleteInvite } from "../functions/crud";
import { inviteSignUp } from "../functions/auth";
import { useNavigate } from "react-router-dom";
import Lottie from 'react-lottie';
import signUpAnimation from "../assets/signUpAnimation.json"

function InviteSignup() {
    
    const navigate = useNavigate();
    const { id, organisationId, email} = useParams();
    const [invitation, setInvitation] = useState(null); // Get organisationId, email, and token parameters from the URL
    const [firstName, setFirstName] = useState("");
    const [LastName, setLastName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    useEffect(() => {
        const fetchInvitation = async () => {
            try {
                const invitationData = await readInvite(id);
                
                if (invitationData) {
                    setInvitation(invitationData);
                } else {
                    console.log("Invitation not found or expired");
                }
            } catch (error) {
                console.error("Error fetching invitation:", error);
            }
        };
    
        fetchInvitation();
    }, [id]);

    const handleSignUp = (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            alert("Passwords do not match")
            return;
        } else if (!password) {
            alert("Password is required")
            return;
        } else {
            const userData = {
                firstName,
                LastName,
                email,
                organisationId,
                accessLevel: invitation.accessLevel
            };
            inviteSignUp(email, password, userData);
            deleteInvite(id);
            navigate("/dashboard");
            console.log(userData)
        }
    }

    if (!invitation) {
        return <div className="text-center text-2xl mt-10"><h1>No such invitation exisits.</h1></div>;
    }

    const { expiry } = invitation;
    const expiryDate = new Date(expiry.seconds * 1000);
    const currentDate = new Date();

    if (currentDate > expiryDate) {
        return <div className="text-center text-2xl mt-10"><h1>The invitation has expired.</h1></div>;
    }

    return (
        <div className="flex flex-row gap-4 mx-10 my-10">
            <div id="left-segment" className="w-1/2 my-32">
                <h1 className="text-3xl font-bold text-center mb-12">Invitation Sign up</h1>
                <form className="grid grid-cols-2 gap-4 max-w-5xl mx-auto" onSubmit={handleSignUp}>
                    <input type="text" placeholder="First Name" 
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                    <input type="text" placeholder="Last Name" 
                        value={LastName}
                        onChange={(e) => setLastName(e.target.value)}
                    />
                    <input type="password" placeholder="Password" 
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <input type="password" placeholder="Confirm Password" 
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <button className="bg-darkTealPreset hover:bg-tealPreset text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                        Sign Up
                    </button>
                </form>
            </div>
            <div id="right-segment" className="w-1/2">
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: signUpAnimation,
                        rendererSettings: {
                          preserveAspectRatio: 'xMidYMid slice'
                      }
                    }}
                    height={500}
                    width={500}
                    speed={0.5}
                />
            </div>
        </div>
    );
}

export default InviteSignup;