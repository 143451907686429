import React, { useState, useEffect } from "react";
import { UserAuth } from "../context/AuthContext";
import { MoveLeft, Pencil } from "lucide-react";
import { useNavigate } from "react-router-dom"
import { updateUser } from '../functions/crud';
import EditUserImageModal from "./modals/EditUserImageModal";
import { calculateAge } from "./utils";
import DeleteStaffModal from "../components/modals/DeleteStaffModal"
import SuccessfullyDeletedModal from "../components/modals/SuccessfullyDeletedModal"


function MyProfile() {

    const { user } = UserAuth();
    const navigate = useNavigate();
    const organisationId = user?.organisationId;
    const userId = user?.uid;
    const [newRole, setNewRole] = useState({ role: "" });
    const [newName, setNewName] = useState({ firstName: "", LastName: "" });
    const [newDob, setNewDob] = useState({ dob: "" });
    const [newAddress, setNewAddress] = useState({
        address: {
            street: "",
            postCode: ""
        }
    });
    const [newPhone, setNewPhone] = useState({ phone: "" });
    const [editRoleVisible, setEditRoleVisible] = useState(false)
    const [editNameVisible, setEditNameVisible] = useState(false)
    const [editDobVisible, setEditDobVisible] = useState(false)
    const [editAddressVisible, setEditAddressVisible] = useState(false);
    const [editPhoneVisible, setEditPhoneVisible] = useState(false);
    const [isEditImageModalOpen, setEditImageModalOpen] = useState(false)
    const [isDeleteUserModalOpen, setDeleteUserModalOpen] = useState(false)
    const [isDeletionSuccessfulModalVisible, setDeletionSuccessfulModalVisible] = useState(false);
    const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);

    const saveEditRole = async () => {
        updateUser(newRole, userId)
        setEditRoleVisible(!editRoleVisible);
    }

    const saveEditName = async () => {
        updateUser(newName, userId)
        setEditNameVisible(!editNameVisible);
    }

    const saveEditDob = async () => {
        updateUser(newDob, userId)
        setEditDobVisible(!editDobVisible);
    }

    const saveEditAddress = async () => {
        updateUser(newAddress, userId);
        setEditAddressVisible(!editAddressVisible);
    }

    const saveEditPhone = async () => {
        updateUser(newPhone, userId);
        setEditPhoneVisible(!editPhoneVisible)
    }

    const toggleEditImageModal = () => {
        setEditImageModalOpen(!isEditImageModalOpen);
    }

    const toggleDeleteUserModal = () => {
        setDeleteUserModalOpen(!isDeleteUserModalOpen);
    }

    const toggleDeletionSuccessfulModalVisible = () => {
        setDeletionSuccessfulModalVisible(!isDeletionSuccessfulModalVisible);
    }

    const deletionSuccessfullCloseButtonClicked = () => {
        setDeletionSuccessfulModalVisible(!isDeletionSuccessfulModalVisible);
        onClose();
    }

    return (
        <div className="mx-10 my-10">

            {isDeleteUserModalOpen && <DeleteStaffModal onClose={toggleDeleteUserModal} deletionSuccessfull={toggleDeletionSuccessfulModalVisible}/>}
            {isDeletionSuccessfulModalVisible && <SuccessfullyDeletedModal onClose={deletionSuccessfullCloseButtonClicked} deletedElementName="Staff Member"/>}

            {isEditImageModalOpen && <div>
                <EditUserImageModal onClose={toggleEditImageModal} />
            </div>}

            <MoveLeft
                className="items-center mr-4 text-gray-300 hover:text-gray-700 cursor-pointer"
                size={50}
                onClick={() => navigate("/dashboard")}
            />
            <div className="bg-transparent px-4 pt-3 mt-4 pb-4 rounded-sm flex flex-row">

                <div name="left-segment" className="flex flex-col w-1/4">

                    {user?.imageRef ? (
                        <div
                            className="h-52 w-52 rounded-sm bg-sky-500 bg-cover bg-no-repeat bg-center"
                            style={{ backgroundImage: `url(${user?.imageRef})` }}
                        >
                        </div>
                    ) : (
                        <div
                            className="h-52 w-52 rounded-sm bg-gray-300 text-gray-600 bg-cover bg-no-repeat bg-center text-center"
                            style={{ backgroundImage: `url(https://placehold.co/600x400/2a8795/fff?text=${(user?.firstName || '').charAt(0)}${(user?.LastName || '').charAt(0)})` }}
                        >
                        </div>
                    )}
                    <p className="text-sm cursor-pointer text-blue-400" onClick={toggleEditImageModal}>Edit Image</p>
                </div>

                <div name="right-segment" className="flex flex-col w-3/4">

                    <div className="bg-white px-4 pt-3 pb-4 rounded-sm border border-gray-200 flex-1 shadow-md">
                        <div className="flex flex-row">
                            <h1 className="text-sm font-semibold text-gray-400">Full Name</h1>
                            <Pencil size={15} className="text-gray-500 ml-1 cursor-pointer" onClick={() => { setEditNameVisible(true) }} />
                        </div>
                        {!editNameVisible && <h1 className="text-xl font-bold pb-4">{user?.firstName + " " + user?.LastName}</h1>}
                        {editNameVisible && <div>
                            <div className="flex flex-row">
                                <input
                                    type="text"
                                    placeholder="First Name"
                                    className="text-sm w-1/4 rounded-md py-1.5 mb-2 mr-4"
                                    value={newName.firstName}
                                    onChange={(e) => {
                                        setNewName({ ...newName, firstName: e.target.value })
                                    }}
                                />
                                <input
                                    type="text"
                                    placeholder="Last Name"
                                    className="text-sm w-1/4 rounded-md py-1.5 mb-2"
                                    value={newName.LastName}
                                    onChange={(e) => {
                                        setNewName({ ...newName, LastName: e.target.value })
                                    }}
                                />
                            </div>
                            <div>
                                <button
                                    className='mr-auto bg-darkTealPreset hover:bg-tealPreset text-white font-bold px-2 py-1 rounded'
                                    onClick={saveEditName}
                                >
                                    Save
                                </button>
                                <button
                                    className='mr-auto ml-2 bg-gray-400 hover:bg-gray-700 text-white font-bold px-2 py-1 rounded'
                                    onClick={() => { setEditNameVisible(false) }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>}

                        <div>
                            <div className="flex flex-row">
                                <h1 className="text-sm font-semibold text-gray-400 mt-2">Role</h1>
                                <Pencil size={15} className="text-gray-500 ml-1 mt-2 cursor-pointer" onClick={() => { setEditRoleVisible(true) }} />
                            </div>
                            {!editRoleVisible && <div className="flex flex-row">
                                {user?.role ? (<h1>{user?.role}</h1>) : (<h1 className="text-center">-</h1>)}
                            </div>}
                            {editRoleVisible && <div>
                                <input
                                    type="text"
                                    placeholder="Role"
                                    className="text-sm w-4/5 rounded-md py-1.5 mb-2"
                                    value={newRole.role}
                                    onChange={(e) => {
                                        setNewRole({ role: e.target.value })
                                    }}
                                />
                                <div>
                                    <button
                                        className='mr-auto bg-darkTealPreset hover:bg-tealPreset text-white font-bold px-2 py-1 rounded'
                                        onClick={saveEditRole}
                                    >
                                        Save
                                    </button>
                                    <button
                                        className='mr-auto ml-2 bg-gray-400 hover:bg-gray-700 text-white font-bold px-2 py-1 rounded'
                                        onClick={() => { setEditRoleVisible(false) }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>}
                        </div>

                        <div className="flex flex-row justify-between mt-4">
                            <div>
                                <div className="flex flex-row">
                                    <h1 className="text-sm font-semibold text-gray-400">Date of Birth</h1>
                                    <Pencil size={15} className="text-gray-500 ml-1 cursor-pointer" onClick={() => { setEditDobVisible(true) }} />
                                </div>

                                {!editDobVisible && <div>
                                    {user?.dob ? (<h1>{user?.dob}</h1>) : (<h1 className="text-center">-</h1>)}
                                </div>}

                                {editDobVisible && <div>
                                    <input
                                        type="date"
                                        placeholder="Date of Birth"
                                        className="text-sm rounded-md mb-2 mr-4"
                                        onChange={(e) => {
                                            setNewDob({ ...newDob, dob: e.target.value })
                                        }}
                                    />
                                    <div>
                                        <button
                                            className='mr-auto bg-darkTealPreset hover:bg-tealPreset text-white font-bold px-2 py-1 rounded'
                                            onClick={saveEditDob}
                                        >
                                            Save
                                        </button>
                                        <button
                                            className='mr-auto ml-2 bg-gray-400 hover:bg-gray-700 text-white font-bold px-2 py-1 rounded'
                                            onClick={() => { setEditDobVisible(false) }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>}

                                <h1>{calculateAge(user?.dob)} years old</h1>
                            </div>

                            <div>
                                <div className="flex flex-row">
                                    <h1 className="text-sm font-semibold text-gray-400">Home Address</h1>
                                    <Pencil size={15} className="text-gray-500 ml-1 cursor-pointer" onClick={() => setEditAddressVisible(true)} />
                                </div>

                                {!editAddressVisible && <div>
                                    {user?.address ? (<div><h1>{user?.address.street}</h1><h1>{user?.address.postCode}</h1></div>) : (<h1 className="text-center">-</h1>)}
                                </div>}

                                {editAddressVisible && (
                                    <div>
                                        <input
                                            type="text"
                                            placeholder="Street"
                                            className="text-sm rounded-md mb-2 mr-4"
                                            onChange={(e) => setNewAddress(prevAddress => ({ ...prevAddress, address: { ...prevAddress.address, street: e.target.value } }))}
                                        />
                                        <input
                                            type="text"
                                            placeholder="Postcode"
                                            className="text-sm rounded-md mb-2 mr-4"
                                            onChange={(e) => setNewAddress(prevAddress => ({ ...prevAddress, address: { ...prevAddress.address, postCode: e.target.value } }))}
                                        />
                                        <div>
                                            <button
                                                className='mr-auto bg-darkTealPreset hover:bg-tealPreset text-white font-bold px-2 py-1 rounded'
                                                onClick={saveEditAddress}
                                            >
                                                Save
                                            </button>
                                            <button
                                                className='mr-auto ml-2 bg-gray-400 hover:bg-gray-700 text-white font-bold px-2 py-1 rounded'
                                                onClick={() => setEditAddressVisible(false)}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div>
                                <div className="flex flex-row">
                                    <h1 className="text-sm font-semibold text-gray-400">Contact Information</h1>
                                    <Pencil size={15} className="text-gray-500 ml-1 cursor-pointer" onClick={() => setEditPhoneVisible(true)} />
                                </div>

                                {user?.email ? (<h1>{user?.email}</h1>) : (<h1>Email: -</h1>)}
                                {!editPhoneVisible && <div>
                                    {user?.phone ? (<h1>{user?.phone}</h1>) : (<h1>Phone: -</h1>)}
                                </div>}

                                {editPhoneVisible && (
                                    <div>
                                        <input
                                            type="text"
                                            placeholder="Phone"
                                            className="text-sm w-3/4 rounded-md py-1.5 mb-2 mr-4"
                                            onChange={(e) => setNewPhone({ phone: e.target.value })}
                                        />
                                        <div>
                                            <button
                                                className='mr-auto bg-darkTealPreset hover:bg-tealPreset text-white font-bold px-2 py-1 rounded'
                                                onClick={saveEditPhone}
                                            >
                                                Save
                                            </button>
                                            <button
                                                className='mr-auto ml-2 bg-gray-400 hover:bg-gray-700 text-white font-bold px-2 py-1 rounded'
                                                onClick={() => setEditPhoneVisible(false)}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="bg-transparent px-4 pt-3 mt-4 pb-4 rounded-sm flex flex-row justify-between">
                <div></div> {/* This empty div will push the button to the right */}
                <button
                    className='bg-red-400 hover:bg-red-600 text-white font-bold px-4 py-2 rounded'
                    onClick={toggleDeleteUserModal}
                >
                    Delete My Account
                </button>
            </div>

        </div>
    )
}

export default MyProfile