import React, {useState, useEffect} from 'react'
import { Users, ClipboardPlus, ActivitySquare, ClipboardCheck } from "lucide-react";
import { readAllData } from '../../functions/crud';
import { UserAuth } from "../../context/AuthContext";


export default function TopGrid() {

	const {user} = UserAuth();
    const organisationId = user?.organisationId;

    const [totalInjuries, setTotalInjuries] = useState(0);
    const [awaitingTriage, setAwaitingTriage] = useState(0);
    const [inRehab, setInRehab] = useState(0);
    const [totalRecovered, setTotalRecovered] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const injuries = await readAllData('injuries', organisationId);
                
				const totalInjuriesCount = injuries.filter(injury => injury.status !== 'Archived').length;
                const awaitingTriageCount = injuries.filter(injury => injury.status === 'In Triage').length;
                const inRehabCount = injuries.filter(injury => injury.status === 'In Rehab').length;
                const totalRecoveredCount = injuries.filter(injury => injury.status === 'Archived').length;

                setTotalInjuries(totalInjuriesCount);
                setAwaitingTriage(awaitingTriageCount);
                setInRehab(inRehabCount);
                setTotalRecovered(totalRecoveredCount);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <div className="flex gap-4">
            <BoxWrapper>
                <div className="rounded-full h-12 w-12 flex items-center justify-center bg-sky-500">
                    <Users className="text-2xl text-white" />
                </div>
                <div className="pl-4">
                    <span className="text-sm text-gray-500 font-light">Total Injuries</span>
                    <div className="flex items-center">
                        <strong className="text-xl text-gray-700 font-semibold">{totalInjuries}</strong>
                    </div>
                </div>
            </BoxWrapper>
            <BoxWrapper>
                <div className="rounded-full h-12 w-12 flex items-center justify-center bg-red-500">
                    <ClipboardPlus className="text-2xl text-white" />
                </div>
                <div className="pl-4">
                    <span className="text-sm text-gray-500 font-light">Awaiting Triage</span>
                    <div className="flex items-center">
                        <strong className="text-xl text-gray-700 font-semibold">{awaitingTriage}</strong>
                    </div>
                </div>
            </BoxWrapper>
            <BoxWrapper>
                <div className="rounded-full h-12 w-12 flex items-center justify-center bg-yellow-400">
                    <ActivitySquare className="text-2xl text-white" />
                </div>
                <div className="pl-4">
                    <span className="text-sm text-gray-500 font-light">In Rehab</span>
                    <div className="flex items-center">
                        <strong className="text-xl text-gray-700 font-semibold">{inRehab}</strong>
                    </div>
                </div>
            </BoxWrapper>
            <BoxWrapper>
                <div className="rounded-full h-12 w-12 flex items-center justify-center bg-green-600">
                    <ClipboardCheck className="text-2xl text-white" />
                </div>
                <div className="pl-4">
                    <span className="text-sm text-gray-500 font-light">Total Recoveries</span>
                    <div className="flex items-center">
                        <strong className="text-xl text-gray-700 font-semibold">{totalRecovered}</strong>
                    </div>
                </div>
            </BoxWrapper>
        </div>
    );
}

function BoxWrapper({ children }) {
    return <div className="bg-white rounded-sm p-4 flex-1 border border-gray-200 flex items-center shadow-md">{children}</div>;
}