import React, { useState } from "react";
import { signUp } from "../../functions/auth";
import { useNavigate } from "react-router-dom";

const SignUpComponent = () => {
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [organisationName, setOrganisationName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const userData = {
        firstName,
        lastName,
        email,
        organisationName,
        accessLevel: "Admin"
    };

    const organisationData = {
        organisationName,
        subscriptionStatus: "inactive"
    };

    const handleSignUp = (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            alert("Passwords do not match");
            return;
        } else if (!email) {
            alert("Email is required");
            return;
        } else if (!password) {
            alert("Password is required");
            return;
        } else {
            signUp(email, password, userData, organisationData);
            navigate("/dashboard");
        }
    };

    return (
        <div className="flex items-center justify-center max-h-screen">
            <div className="p-6 sm:p-8 bg-white rounded-md w-full max-w-lg">
                <h1 className="text-2xl font-bold text-gray-800 mb-6 text-center">Sign up</h1>

                {/* Form */}
                <form className="flex flex-col gap-4" onSubmit={handleSignUp}>
                    <input 
                        type="text" 
                        placeholder="First Name" 
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        className="p-2 border rounded-md"
                    />
                    <input 
                        type="text" 
                        placeholder="Last Name" 
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        className="p-2 border rounded-md"
                    />
                    <input 
                        type="email" 
                        placeholder="Email" 
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="p-2 border rounded-md"
                    />
                    <input 
                        type="text" 
                        placeholder="Organisation Name" 
                        value={organisationName}
                        onChange={(e) => setOrganisationName(e.target.value)}
                        className="p-2 border rounded-md"
                    />
                    <input 
                        type="password" 
                        placeholder="Password" 
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="p-2 border rounded-md"
                    />
                    <input 
                        type="password" 
                        placeholder="Confirm Password" 
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className="p-2 border rounded-md"
                    />
                    <button 
                        type="submit"
                        className="bg-teal-500 hover:bg-teal-600 text-white font-bold py-2 px-4 rounded mt-4"
                    >
                        Sign Up
                    </button>
                </form>
                {/* End Form */}
            </div>
        </div>
    );
};

export default SignUpComponent;
