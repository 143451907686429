import React, { useState, useEffect } from 'react';
import { UserAuth } from "../../context/AuthContext";
import { createData, readData } from "../../functions/crud";
import { X } from 'lucide-react';
import { getTime, getDate } from '../utils';

function SoapNoteModal({ onClose, paramsId, showSuccessModal, playerId }) {

    const { user } = UserAuth();
    const organisationId = user?.organisationId;

    const initialSoapNoteDetails = {
        title: "",
        subjective: "",
        objective: "",
        assessment: "",
        plan: "",
        treatment: "",
        rehab: "",
        selectedTime: getTime(),
        selectedDate: getDate(),
        injuryId: paramsId,
        playerId: playerId,
        creatorId: user?.uid,
        creatorImageRef: `${user?.imageRef ? user?.imageRef : "https://placehold.co/600x400/2a8795/fff?text="}${(user?.firstName || '').charAt(0)}${(user?.LastName || '').charAt(0)}`,
        creatorFirstName: `${user?.firstName}`,
        creatorLastName: `${user?.LastName}`,
        creatorRole: `${user?.role}`,
    };

    const [currentCaseFile, setCurrentCaseFile] = useState([]);
    const [newSoapNote, setNewSoapNote] = useState(initialSoapNoteDetails);
    const [globalUpdateData, setGlobalUpdateData] = useState(null)

    useEffect(() => {
        const fetchCaseFile = async () => {
            try {
                const caseFile = await readData("injuries", paramsId, organisationId);
                setCurrentCaseFile(caseFile);
                setGlobalUpdateData({
                    title: "New Soap Note Created",
                    subTitle: `for ${caseFile.firstName + " " + caseFile.lastName}`,
                    imageRef: `${user?.imageRef ? user?.imageRef : "https://placehold.co/600x400/2a8795/fff?text="}${(user?.firstName || '').charAt(0)}${(user?.LastName || '').charAt(0)}`,
                    creatorFirstName: `${user?.firstName}`,
                    creatorLastName: `${user?.LastName}`,
                    creatorRole: `${user?.role}`,
                    timeCreated: getTime(),
                    dateCreated: getDate()
                })
            } catch (error) {
                console.error("Error reading document:", error);
            }
        }
        fetchCaseFile();
    }, [paramsId, organisationId, user]);

    const handleCreateSoapNote = (e) => {
        e.preventDefault();
        createData("soapNotes", newSoapNote, organisationId);
        if (globalUpdateData) {
            createData("globalUpdates", globalUpdateData, organisationId);
        }
        onClose();
        showSuccessModal();
    };

    const handleOnChangeInput = (e) => {
        setNewSoapNote({ ...newSoapNote, [e.target.name]: e.target.value });
    };

    return (
        <div className={`fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75`}>
            <div className="bg-white p-8 rounded-md shadow-md max-w-3xl w-full overflow-hidden">
                <div className='flex flex-row items-center mb-4'>
                    <h2 className="text-2xl font-bold flex-1">Add Soap Note</h2>
                    <X className='text-gray-400 hover:text-gray-600 cursor-pointer' onClick={onClose} />
                </div>
                <div className="max-h-96 overflow-y-auto">
                    <form className="flex flex-col gap-2" onSubmit={handleCreateSoapNote}>
                        <div className="flex justify-evenly">
                            <input
                                type="date"
                                className="border rounded p-2"
                                name='selectedDate'
                                onChange={handleOnChangeInput}
                            />
                            <input
                                type="time"
                                className="border rounded p-2"
                                name='selectedTime'
                                onChange={handleOnChangeInput}
                            />
                        </div>
                        <input
                            type="text"
                            placeholder="Title"
                            name="title"
                            onChange={handleOnChangeInput}
                        />
                        <textarea
                            rows="4"
                            placeholder="Subjective"
                            name="subjective"
                            onChange={handleOnChangeInput}
                        />
                        <textarea
                            rows="4"
                            placeholder="Objective"
                            name="objective"
                            onChange={handleOnChangeInput}
                        />
                        <textarea
                            rows="4"
                            placeholder="Assessment"
                            name="assessment"
                            onChange={handleOnChangeInput}
                        />
                        <textarea
                            rows="4"
                            placeholder="Plan"
                            name="plan"
                            onChange={handleOnChangeInput}
                        />
                        <textarea
                            rows="4"
                            placeholder="Treatment"
                            name="treatment"
                            onChange={handleOnChangeInput}
                        />
                        <textarea
                            rows="4"
                            placeholder="Rehab"
                            name="rehab"
                            onChange={handleOnChangeInput}
                        />
                        <button className="bg-darkTealPreset hover:bg-tealPreset text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                            Create
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default SoapNoteModal;