import { X } from 'lucide-react'
import React, { useState, useEffect } from 'react'
import { readData, readUser } from '../../functions/crud';
import { UserAuth } from "../../context/AuthContext";

function ReadSoapNoteModal({ onClose, soapNoteId, isVisible }) {

    const [soapNote, setSoapNote] = useState(null);
    const { user } = UserAuth();
    const organisationId = user?.organisationId;

    useEffect(() => {
        const fetchSoapNoteData = async () => {
            try {
                const soapNoteData = await readData("soapNotes", soapNoteId, organisationId);
                setSoapNote(soapNoteData);
            } catch (error) {
                console.error("Error fetching soap note data:", error);
            }
        };

        fetchSoapNoteData();
    }, [soapNoteId]);

    return (
        <div className={`fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75`}>
            <div className="bg-white p-8 rounded-md shadow-md max-w-3xl w-full overflow-hidden">
                <div className='flex flex-row items-center mb-4'>
                    <h2 className="text-2xl font-bold flex-1">
                        Soap Note:
                    </h2>
                    <X className='text-gray-400 hover:text-gray-600 cursor-pointer' onClick={onClose} />
                </div>
                <div className='mb-4'>
                    <p className='text-xl'>{soapNote?.title}</p>
                    <p className='text-lg font-normal'>{soapNote?.selectedTime}</p>
                    <p className='text-lg font-normal'>{soapNote?.selectedDate}</p>
                    <div className="flex flex-row items-center mt-2">
                        <div
                            className="h-10 w-10 rounded-full bg-sky-500 bg-cover bg-no-repeat bg-center"
                            style={{ backgroundImage: `url(${soapNote?.creatorImageRef})` }}
                        ></div>
                        <div className="flex flex-col ml-1 mt-1">
                            <h1 className="text-gray-700">{soapNote?.creatorFirstName + " " + soapNote?.creatorLastName}</h1>
                            <h1 className="text-gray-400 mt-[-5px]">{soapNote?.creatorRole ? soapNote.creatorRole : "Role Undefined"}</h1>
                        </div>
                    </div>
                </div>
                {soapNote && <div className="max-h-96 overflow-y-auto">
                    <h1 className="text-xl font-semibold flex-1">Subjective</h1>
                    <p>{soapNote?.subjective}</p>
                    <h1 className="text-xl font-semibold flex-1">Objective</h1>
                    <p>{soapNote?.objective}</p>
                    <h1 className="text-xl font-semibold flex-1">Assessment</h1>
                    <p>{soapNote?.assessment}</p>
                    <h1 className="text-xl font-semibold flex-1">Plan</h1>
                    <p>{soapNote?.plan}</p>
                    <h1 className="text-xl font-semibold flex-1">Treatment</h1>
                    <p>{soapNote?.treatment}</p>
                    <h1 className="text-xl font-semibold flex-1">Rehab</h1>
                    <p>{soapNote?.rehab}</p>
                </div>}
            </div>
        </div>
    )
}

export default ReadSoapNoteModal